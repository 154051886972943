import { ToastrService } from "ngx-toastr";
import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import { Equipment } from "./equipment";
import { EQUIPMENTS } from "./equipments-mock";
import { SPARES } from "./spares-mock";
//import Swiper from 'swiper';
import Swiper from "../../../node_modules/swiper/dist/js/swiper.js";
import { EquipmentService } from "./../services/equipement-service.service";
//import { EquipmentService } from './../services/equipement-service.service';
import { InvoiceService } from "../services/invoice.service";
import { ElementRef, Pipe } from "@angular/core";
import * as $ from "jquery";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from "ngx-smart-modal";
@Component({
  selector: "app-my-equipments-meters",
  templateUrl: "./my-equipments.component.pug",
  styleUrls: ["./my-equipments.component.scss"],
})
export class MyEquipmentsMetersComponent implements OnInit, AfterViewInit {
  @Input() usedIn: string;
  // equipments = EQUIPMENTS;
  equipments = [];
  spares = SPARES;
  myEquipmentsSwiper: Swiper = undefined;
  myEquipmentSparesSwipers: Swiper[] = [];
  formelement: HTMLElement;
  equipmentList = [];
  equipmentMain = [];
  equipmentsArray = [];

  constructor(
    private eqService: EquipmentService,
    private data: InvoiceService,
    private elementRef: ElementRef,
    public userStateService: UserStateService,
    public spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.getMeterReaddata();
    }, 2000);

    this.data.getMessage().subscribe((mgs) => {
      if (mgs == "excel") {
        const data = this.equipments.map((item) => item.EtMassMeter);
        data.forEach((item) => {
          delete item.__metadata;
          delete item.Equipment;
          delete item.MpointGuid;
          delete item.Location1;
          delete item.City1;
          delete item.State1;
          delete item.CurrentReadingDisp;
          delete item.ReadOrigin;
          delete item.Type;
          delete item.Message;
        });

        if (data.length) {
          this.eqService.exportAsExcelFile(data, "MeterData");
        }
      }
    });
  }

  getMeterReaddata() {
    this.equipments = [];
    this.eqService.getMeterReadData().subscribe((data) => {
      this.equipments = data.d.results;
      console.table(" this.equipments>>>", this.equipments);
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.getEquipmentInfo();
    }, 2000);
    // console.log(this.equipmentList);
    setTimeout(function () {
      this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
        direction: "vertical",
        slidesPerView: "auto",
        slidesPerGroup: 4,
        navigation: {
          nextEl: ".my-equipments--navigation--next",
          prevEl: ".my-equipments--navigation--prev",
        },
      });
    }, 7000);

    // Array.from(document.querySelectorAll(".my-equipment--spares")).forEach(
    //   spares => {
    //     const swiperContainer: HTMLElement = spares.querySelector(
    //       ".spares-container .swiper-container"
    //     );
    //     const prevElement: HTMLElement = spares.querySelector(
    //       ".spares-action .nav-prev"
    //     );
    //     const nextElement: HTMLElement = spares.querySelector(
    //       ".spares-action .nav-next"
    //     );

    //     this.myEquipmentSparesSwipers.push(
    //       new Swiper(swiperContainer, {
    //         slidesPerView: "auto",
    //         navigation: {
    //           nextEl: nextElement,
    //           prevEl: prevElement
    //         }
    //       })
    //     );
    //   }
    // );

    this.data.searchData.subscribe((data) => {
      if (data) {
        // console.log(data+"this is search value");
        // console.log(this.equipmentList);
        if (data && data.trim() != "") {
          this.equipmentList = this.equipmentsArray.filter((item) => {
            return (
              item.Sernr.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
              item.EquipmentId.toLowerCase().indexOf(data.toLowerCase()) > -1
            );
          });
        } else {
          this.equipmentList = this.equipmentsArray;
        }
      } else {
        this.equipmentList = this.equipmentsArray;
      }
    });

    this.data.getMessage().subscribe((data) => {
      // console.log(data);
      if (data != "excel") {
        this.getEquipmentInfo();
      }

      setTimeout(function () {
        this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
          direction: "vertical",
          slidesPerView: "auto",
          slidesPerGroup: 4,
          navigation: {
            nextEl: ".my-equipments--navigation--next",
            prevEl: ".my-equipments--navigation--prev",
          },
        });
      }, 7000);

      // Array.from(document.querySelectorAll(".my-equipment--spares")).forEach(
      //   spares => {
      //     const swiperContainer: HTMLElement = spares.querySelector(
      //       ".spares-container .swiper-container"
      //     );
      //     const prevElement: HTMLElement = spares.querySelector(
      //       ".spares-action .nav-prev"
      //     );
      //     const nextElement: HTMLElement = spares.querySelector(
      //       ".spares-action .nav-next"
      //     );

      //     this.myEquipmentSparesSwipers.push(
      //       new Swiper(swiperContainer, {
      //         slidesPerView: "auto",
      //         navigation: {
      //           nextEl: nextElement,
      //           prevEl: prevElement
      //         }
      //       })
      //     );
      //   }
      // );
    });

    $(document).ready(function () {
      $(".scroll-y").scroll(function () {
        if ($(".scroll-y").scrollTop() > 1600) {
          $(".scroll-y").removeClass("scrollwhite scrollhovergrey");
          $(".scroll-y").addClass("scrollred scrollhoverblack");
        } else {
          $(".scroll-y").removeClass("scrollred scrollhoverblack");
          $(".scroll-y").addClass("scrollwhite scrollhovergrey");
        }
      });
    });

    //})
  }

  toggleSpares(equipment: Equipment, parentContainer: HTMLElement) {
    const sparesContainer = parentContainer.querySelector(
      ".my-equipment--spares"
    );
    const allSlides = parentContainer
      .closest(".my-equipments--container")
      .querySelectorAll(".my-equipment-slide");
    const thisSlideIndex = Array.from(allSlides).indexOf(parentContainer);
    const btnViewSparesLabel = parentContainer.querySelector(
      ".btn-view-spares .icon-btn--label"
    );

    sparesContainer.classList.toggle("shown");
    window.setTimeout(() => {
      this.myEquipmentsSwiper.updateSlides();
      if (sparesContainer.classList.contains("shown")) {
        btnViewSparesLabel.innerHTML = "Hide Spares";
        this.myEquipmentsSwiper.slideTo(thisSlideIndex, 600);
      } else {
        btnViewSparesLabel.innerHTML = "View Spares";
      }
    }, 400);
  }

  getEquipmentInfo() {
    this.equipmentList = [];
    this.spinner.show();
    var defEquip = localStorage.getItem("equip");
    if (defEquip) {
      var jEquip = JSON.parse(defEquip);

      for (var i = 0; i < jEquip.d.results.length; i++) {
        this.equipmentList.push(jEquip.d.results[i].EtList);
        this.equipmentMain.push(jEquip.d.results[i]);
      }

      this.equipmentsArray = this.equipmentList.slice();
      this.spinner.hide();
    } else {
      this.eqService.getEquipmentInfo().subscribe(
        (equiData) => {
          this.spinner.hide();
          // console.log(equiData.d);
          for (var i = 0; i < equiData.d.results.length; i++) {
            this.equipmentList.push(equiData.d.results[i].EtList);
            this.equipmentMain.push(equiData.d.results[i]);
          }

          this.equipmentsArray = this.equipmentList.slice();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
    }
  }

  meterReads(equipRef: string) {
    this.closeForm();
    $(".main-page-form").addClass("active");
    $(".meter-reads-form").removeClass("hide");
    $(".meter-reads-form").siblings().addClass("hide");

    $(".meter-reads-form .message-no-items").hide();

    $(".meter-reads-form .message-no-items").hide();

    this.eqService.equipmentInfo = {};
    this.eqService.equipmentInfo = this.equipmentList.filter(
      (e) => e.Equipment == equipRef
    );
    let userRef = this.eqService.equipmentInfo[0].Equipment;
    let userEuipId = this.eqService.equipmentInfo[0].EquipmentId;
    let userEuipModel = this.eqService.equipmentInfo[0].Model;
    let userEuipSrno = this.eqService.equipmentInfo[0].Sernr;

    //let userRef=$(this).parent().parent().find(".equipinfo").text().trim();
    //let servURL="http://crdb00.sec.sharpamericas.com:8000/";
    let servURL = "";
    let partURL =
      "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetMetersSet?$filter= IvEquipment eq ";
    // let soldtoUser = "641433";
    let soldtoUser = localStorage.getItem("soldto");
    // let user = "SBSTST000";
    let user = localStorage.getItem("username").toUpperCase();
    // let getmeterReadURL=servURL+partURL+"'"+userRef+"'&$format=json";
    let getmeterReadURL = `/getMeterSet?$filter= IvEquipment eq '${userRef}' &$format=json`;
    let getData = "";
    // console.log(JSON.stringify(getmeterReadURL));
    $(".meter-reads-form .new-meter-data").empty();
    $.ajax({
      context: this,
      url: getmeterReadURL,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          this.userStateService.getAuthorizationHeader()
        );
      },
      success: function (meterReadData) {
        let newmeterData: any = meterReadData.d.results;
        this.getData = newmeterData;
        // console.log(JSON.stringify(meterReadData));
        //alert(JSON.stringify(newmeterData));
        if (Array.isArray(newmeterData) && newmeterData.length) {
          //alert("hello");
          $(".meter-reads-form .equip-id-meter").text(userEuipId);
          $(".meter-reads-form  .model-meter").text(userEuipModel);
          $(".meter-reads-form  .sr-no-meter").text(userEuipSrno);
          // $(".meter-reads-form  .desc-number").text(userEuipSrno);
          var newcreate = "";
          for (var i = 0; i < newmeterData.length; i++) {
            newcreate +=
              "<p class='previous-billed-number'  style='color:#fff'>Previous Billed Meter: <span class='pr-billed" +
              [i] +
              "' style='color:#fff'></span><span class='pr-date'></span></p>" +
              "<p class='last-updated-number'  style='color:#fff'>Last Updated Meter: <span class='last-up-meter" +
              [i] +
              "'  style='color:#fff'></span><span class='last-up-date'></span></p>" +
              "<p class='last-updated-number'  style='color:#fff'>Color: <span class='color-meter" +
              [i] +
              "'  style='color:#fff'></span><span class='last-up-date'></span></p>" +
              "<div class='form-group input-form-group'>" +
              "<div class='err-message' id='meter-err-message" +
              [i] +
              "' style='color:red'></div>" +
              "<div class='desc-number" +
              [i] +
              "' style='display:none'></div>" +
              "<div class='new-read-date" +
              [i] +
              "' style='display:none'></div>" +
              "<div class='meter-guid" +
              [i] +
              "' style='display:none'></div>" +
              "<input type='text' id='newMeterRead" +
              [i] +
              "' name='newMeterRead" +
              [i] +
              "' class='form-control input new-meter-read' placeholder='New Meter Read'/>" +
              // "<label for='newMeterRead" +
              // [i] +
              // "'>New Meter Read</label>" +
              "</div>" +
              "<div class='form-group input-form-group'>" +
              "<input type='text' id='usage" +
              [i] +
              "' name='usage" +
              [i] +
              "' class='form-control input usage-read' placeholder='Usage' disabled/>" +
              // "<label for='usage" +
              // [i] +
              // "' class='usage'>Usage</label>" +
              "</div>";
          }
          $(".meter-reads-form .new-meter-data").append(newcreate);

          for (var i = 0; i < $(".new-meter-read").length; i++) {
            //alert($(".new-meter-read").length);

            $(".meter-reads-form .pr-billed" + [i]).text(
              newmeterData[i].EtMeters.PrvReadingDisp
            );
            $(".meter-reads-form .last-up-meter" + [i]).text(
              newmeterData[i].EtMeters.CurrentReadingDisp
            );
            $(".meter-reads-form .color-meter" + [i]).text(
              newmeterData[i].EtMeters.Label
            );
            $(".meter-reads-form .desc-number" + [i]).text(
              newmeterData[i].EtMeters.Description
            );
            $(".meter-reads-form .new-read-date" + [i]).text(
              newmeterData[i].EtMeters.NewReadingDt
            );
            $(".meter-reads-form .meter-guid" + [i]).text(
              newmeterData[i].EtMeters.Guid
            );
            var validateChars = new RegExp("[0-9,]");
            $("#newMeterRead" + [i]).keyup(function (e) {
              //alert(JSON.stringify(meterReadData));
              // console.log(newmeterData);
              //let lastmeterRead=  this.getData[i].EtMeters.CurrentReading.trim();
              var ab = $(this).attr("id");
              var cd = ab.replace("newMeterRead", "").trim();
              //alert(cd);
              let lastmeterRead =
                meterReadData.d.results[cd].EtMeters.CurrentReading.trim();
              let newmeterRead = $(this).val();
              $(".meter-reads-form .err-message").text("");
              if (!validateChars.test(newmeterRead)) {
                e.preventDefault();
                $(this).val(
                  $(this)
                    .val()
                    .replace(/[A-Za-z!@#$%^&*()'<>./?{}[\\/\]"]/g, "")
                );
                //return;
              }
              //alert($(this).val());
              newmeterRead = $(this).val().replace(/,/g, "");

              if ($(this).val().trim()) {
                if (parseFloat($(this).val()) < parseFloat(lastmeterRead)) {
                  $("#meter-err-message" + [cd]).text(
                    "New meter reading can not be less than last reading"
                  );
                  //alert("no");
                  parseInt($("#usage" + [cd]).val(0));
                  return;
                }

                let usageVal =
                  parseFloat(newmeterRead) - parseFloat(lastmeterRead);
                //alert(lastmeterRead + " " + $(this).val());
                parseInt($("#usage" + [cd]).val(usageVal));

                if (usageVal > parseFloat(lastmeterRead) * 0.2) {
                  $("#meter-err-message" + [cd]).text(
                    "Usage value can not be greater than 20 percent last reading"
                  );
                  //alert("no");
                  parseInt($("#usage" + [cd]).val(0));
                  return;
                }
              }
            });
          }
        } else {
          //alert("Hi");
          $(".meter-reads-form .equip-id-meter").text("");
          $(".meter-reads-form  .model-meter").text("");
          $(".meter-reads-form  .sr-no-meter").text("");
          $(".meter-reads-form .desc-meter").text("");
          $(".meter-reads-form .pr-billed").text("");
          $(".meter-reads-form .last-up-meter").text("");
          $(".meter-reads-form .message-no-items").show();
        }

        var validateChars = new RegExp("[0-9,]");
      },
    });
  }

  closeForm() {
    this.formelement = document.querySelector(".main-page-form");
    this.formelement.classList.remove("active");
    if ($(".new-meter-read").length != 0) {
      for (var i = 0; i < $(".new-meter-read").length; i++) {
        $("#newMeterRead" + [i]).val("");
        $(".new-read-date" + [i]).val("");
      }
    }
    $(".meter-reads-form .equip-id-meter").text("");
    $(".meter-reads-form  .model-meter").text("");
    $(".meter-reads-form  .sr-no-meter").text("");
  }

  postMeterData() {
    this.spinner.show();
    var $this = this;
    var narray = [];
    var rows = $(".new-meter-read");
    //alert($("#orderConsumables2 tr").length);
    for (var i = 0; i < $(".new-meter-read").length; i++) {
      //var ab=$("#orderConsumables2 #partno"+i).text();
      //var cd=$("#orderConsumables2 #inq"+i).val();

      narray.push({
        Equipment: $(".meter-reads-form .equip-id-meter").text().trim(),
        Guid: $(".meter-reads-form .meter-guid" + [i])
          .text()
          .trim(),
        Description: $(".meter-reads-form .desc-number" + [i])
          .text()
          .trim(),
        NewReading: $("#newMeterRead" + [i]).val(),
        NewReadingDt: $(".new-read-date" + [i])
          .text()
          .trim(),
      });
      //narray.pop();
      //narray.pop();
    }
    var MetersData: any = {
      IvEquipment: $(".meter-reads-form .equip-id-meter").text().trim(),
      EtMeterSet: "",
    };

    MetersData.EtMeterSet = narray;
    // MetersData = JSON.stringify(MetersData);
    // console.log(MetersData);

    var token = "";
    var cookie = "";
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        //  xhr.setRequestHeader('X-CSRF-Token', 'Fetch');
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        //  token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      $.ajax({
        url: `/postmeterdata`,
        async: false,
        dataType: "json",
        data: JSON.stringify({ actual: MetersData, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          // console.log("Meter reads success:",JSON.stringify(odata));
          // alert(JSON.stringify(odata));
          var data1: any = odata.d.EtMeterSet.results;
          var msg = "";
          for (var i = 0; i < data1.length; i++) {
            msg += data1[i].Message + "\n";
          }
          $this.spinner.hide();
          // alert(msg);
          $this.toastr.success(msg, "", {
            closeButton: true,
            disableTimeOut: true,
          });
          if ($(".new-meter-read").length != 0) {
            for (var i = 0; i < $(".new-meter-read").length; i++) {
              $("#newMeterRead" + [i]).val("");
              $(".new-read-date" + [i]).val("");
            }
          }
          $(".meter-reads-form .equip-id-meter").text("");
          $(".meter-reads-form  .model-meter").text("");
          $(".meter-reads-form  .sr-no-meter").text("");
        },
        error: function (data) {
          // console.log("error:", JSON.stringify(data));
          $this.spinner.hide();
          // alert("error:"+JSON.stringify(data));
          $this.toastr.error(JSON.stringify(data), "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }
}
