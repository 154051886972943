import { DialogComponent } from "./../dialog/dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { ElementRef, Pipe, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { EquipmentService } from "../services/equipement-service.service";
import * as $ from "jquery";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ToastrService } from "ngx-toastr";
import _ from "lodash";
import {
  HttpClient,
  HttpClientXsrfModule,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { jsonpCallbackContext } from "@angular/common/http/src/module";
import { AppComponent } from "../app.component";
import { InvoiceService } from "../services/invoice.service";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
import { filter } from "rxjs/operators";

//import {HeaderService} from "../header/header.component";
//import {TokenInterceptor} from './token.interceptor';

/*import { networkInterfaces } from 'os';
import { addAllToArray } from '@angular/core/src/render3/util';*/

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
/*class styleModel{
    itemClass : string;
    colClass : string;
    blockClass : string;
    data : string;
}*/
export class MainComponent implements AfterViewInit, OnInit {
  public equipmentList = [];
  getRequestDetails = [];
  abab: any;
  consumableData = [];
  nodata = "";
  noorderdata = "";
  norequestdata = "";
  conord: Observable<any>;
  briefdesc: any;
  addinfo: any;
  //   get abab():any {
  //     debugger;
  //     return MainComponent.abab;
  // }

  openRequest = [];
  openOrders = [];
  userid: any;
  //parseFloat:any;
  //parseInt: any;
  value: any;

  equipmentInfo: any;
  errorMessage: string;
  equipmentId: any;
  textdata: string;
  requestInfoArray = [];
  equipmentsArray = [];
  requestsArray = [];
  ordersArray = [];
  showSearch = false;

  servicesArray = [];
  public searchdata: FormGroup;
  constructor(
    private _router: Router,
    private _equipmentService: EquipmentService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private userInfoService: AppComponent,
    private selectService: InvoiceService,
    public userStateService: UserStateService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    localStorage.setItem(
      "auth",
      this.userStateService.getAuthorizationHeader()
    );
    this.searchdata = this.formBuilder.group({
      searchelement: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  ngAfterViewInit() {
    const MainComponent = this;
    console.log("Main component");
    var $this = this;
    this.getUserInfo();

    if (
      localStorage.getItem("route") === null ||
      localStorage.getItem("route") === "false"
    ) {
      // console.log("local record");

      let checkEquip = function () {
        setTimeout(function () {
          if (localStorage.getItem("equip") == null) checkEquip();
          else {
            $this.getEquipdefault(JSON.parse(localStorage.getItem("equip")));
          }
        }, 1000);
      };
      checkEquip();

      let checkOrder = function () {
        setTimeout(function () {
          if (localStorage.getItem("openord") == null) checkOrder();
          else {
            $this.getOpenOrderdefault(
              JSON.parse(localStorage.getItem("openord"))
            );
          }
        }, 1000);
      };
      checkOrder();

      let checkReq = function () {
        setTimeout(function () {
          if (localStorage.getItem("openreq") == null) checkReq();
          else {
            $this.getOpenReqdeafault(
              JSON.parse(localStorage.getItem("openreq"))
            );
          }
        }, 1000);
      };
      checkReq();
    } else {
      // console.log("updated record");
      this.getEquipmentInfo();
      this.openOrdersData();
      this.openRequestData();
    }

    this.selectService.getMessage().subscribe((data) => {
      // console.log(data, "yahan aya");
      this.getEquipmentInfo();
      this.openOrdersData();
      this.openRequestData();
    });

    $(document).ready(function () {
      var $this;
      $('.carousel[data-type="multi"] .item').each(function () {
        var next = $(this).next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }
        next.children(":first-child").clone().appendTo($(this));

        for (var i = 0; i < 6; i++) {
          next = next.next();
          if (!next.length) {
            next = $(this).siblings(":first");
          }
          next.children(":first-child").clone().appendTo($(this));
        }
      });

      var checkitem = function () {
        //alert("Check");
        var $this;
        // console.log("Load");
        $this = $("#carousel1");
        if ($("#carousel1 .carousel-inner .item:first").hasClass("active")) {
          $this.children(".left").hide();
          $this.children(".right").show();
        } else if (
          $("#carousel1 .carousel-inner .item:last").hasClass("active")
        ) {
          $this.children(".right").hide();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
      };
      checkitem();

      var checkitemLeft = function () {
        //alert("Left");
        var $this;
        // console.log("Clicked");
        $this = $("#carousel1");
        if (
          $("#carousel1 .carousel-inner .item:nth-child(2)").hasClass("active")
        ) {
          $this.children(".left").hide();
          $this.children(".right").show();
        } else if (
          $("#carousel1 .carousel-inner .item:nth-last-child(2)").hasClass(
            "active"
          )
        ) {
          $this.children(".right").show();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
        /*if ($("#carousel1 .carousel-inner .item:nth-child(3)").hasClass("active")) {
          $this.children(".carousel-control.left").show();
          $this.children(".right").show();
        } */
      };
      $("#carousel1").on("click", "a", checkitemLeft);

      var checkitemRight = function () {
        var $this;
        // console.log("Clicked");
        $this = $("#carousel1");
        if (
          $("#carousel1 .carousel-inner .item:nth-child(3)").hasClass("active")
        ) {
          $this.children(".left").show();
          $this.children(".right").show();
        } else if (
          $("#carousel1 .carousel-inner .item:nth-last-child(2)").hasClass(
            "active"
          )
        ) {
          $this.children(".right").hide();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
      };
      $("#carousel1").on("click", "a[data-slide='next']", checkitemRight);

      //Carousel2 Controls Show
      var checkitem2 = function () {
        var $this;
        // console.log("Load");
        $this = $("#carousel2");
        if ($("#carousel2 .carousel-inner .item:first").hasClass("active")) {
          $this.children(".left").hide();
          $this.children(".right").show();
        } else if (
          $("#carousel2 .carousel-inner .item:last").hasClass("active")
        ) {
          $this.children(".right").hide();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
      };
      checkitem2();

      var checkitemLeft2 = function () {
        var $this;
        // console.log("Clicked");
        $this = $("#carousel2");
        if (
          $("#carousel2 .carousel-inner .item:nth-child(2)").hasClass("active")
        ) {
          $this.children(".left").hide();
          $this.children(".right").show();
        } else if (
          $("#carousel2 .carousel-inner .item:nth-last-child(2)").hasClass(
            "active"
          )
        ) {
          $this.children(".right").show();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
        /*if ($("#carousel1 .carousel-inner .item:nth-child(3)").hasClass("active")) {
          $this.children(".carousel-control.left").show();
          $this.children(".right").show();
        } */
      };
      $("#carousel2").on("click", "a", checkitemLeft2);

      var checkitemRight2 = function () {
        var $this;
        // console.log("Clicked");
        $this = $("#carousel2");
        if (
          $("#carousel2 .carousel-inner .item:nth-child(3)").hasClass("active")
        ) {
          $this.children(".left").show();
          $this.children(".right").show();
        } else if (
          $("#carousel2 .carousel-inner .item:nth-last-child(2)").hasClass(
            "active"
          )
        ) {
          $this.children(".right").hide();
          $this.children(".left").show();
        } else {
          $this.children(".carousel-control").show();
        }
        /*if ($("#carousel1 .carousel-inner .item:nth-child(3)").hasClass("active")) {
          $this.children(".carousel-control.left").show();
          $this.children(".right").show();
        } */
      };
      $("#carousel2").on("click", "a[data-slide='next']", checkitemRight2);

      //});

      //On focus of input fields functionality (floating labels)
      $(".input-form-group .input").focus(function () {
        if ($(this).val() == "") {
          $(this).addClass("floating-label");
        }
      });

      //On blur of input fields functionality (floating labels)
      $(".input-form-group .input").blur(function () {
        if ($(this).val() == "") {
          $(this).removeClass("floating-label");
        }
      });

      if ($(".input-form-group .input").val() !== "") {
        $(".input-form-group .input").addClass("floating-label");
      } else {
        $(".input-form-group .input").removeClass("floating-label");
      }

      var getQuantity = function () {
        // console.log("this is changed");
      };
      $("body").on("click", ".main-form-close", function (e) {
        e.preventDefault();
        $(".main-page-form").removeClass("active");
      });
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // console.log("On init main");
  }

  // calculate(data) {
  //   console.log(data);
  // }
  getEquipData(event, titleText) {
    var $this = this;
    // debugger;
    var parentEle = $(event.target).parents(".equip-list");
    $(".equip-list").removeClass("clicked");
    parentEle.addClass("clicked");

    //var innerText = $(this).text().trim();
    var innerText = titleText;
    $(".main-page-form").addClass("active");

    if (innerText == "REQUEST STATUS") {
      $this.spinner.show();
      // console.log("req status");
      $(".req-status-sl .equip-id").text("");
      $(".req-status-sl .order-info").text("");
      $(".req-status-sl .gen-topic").text("");
      $(".req-status-sl .det-concern").text("");
      $(".req-status-sl .rep-name").text("");
      $(".req-status-sl .rep-tel").text("");
      $(".req-status-sl .mob-status").text("");
      $(".req-status-sl .time").html("");
      $(".req-status-sl .curr-active").text("");
      // $(".req-status-sl .mob-status").text(newdata.MobileStatus);
      $(".req-status-sl .tech").text("");
      $("#table_content tr").remove();
      var ivorder = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".r-nmbr")
        .text()
        .trim();
      //alert(ivorder);
      //var FullURL= "http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetRequestDetailSet?$filter= IvOrder eq '"+ivorder+"'&$format=json";
      //  var FullURL= "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetRequestDetailSet?$filter= IvOrder eq '"+ivorder+"'&$format=json";
      var FullURL = `/getRequestDetails?$filter= IvOrder eq '${ivorder}'&$format=json`;
      $.ajax({
        context: this,
        url: FullURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("auth"));
        },
        success: function (reqData) {
          $this.spinner.hide();
          reqData = reqData;

          let newdata: any = reqData.d.results[0];
          //let newdata;
          // console.log(JSON.stringify(reqData));
          $(".req-status-sl .equip-id").text(newdata.EtEqInfo.Equipment);
          $(".req-status-sl .order-info").text(newdata.EvCaption);
          $(".req-status-sl .gen-topic").text(newdata.EvCat1);
          $(".req-status-sl .det-concern").text(newdata.EvCat2);
          $(".req-status-sl .rep-name").text(newdata.EvRepName);
          $(".req-status-sl .rep-tel").text(newdata.EvRepNumber);
          $("#table_content tr").remove();
          $.each(reqData.d.results, function (i, item) {
            $('<tr style="background-color: #FFF;">')
              .append(
                $('<td style="color: #7B7B7B;font-size: 13px;">').text(
                  item.EtStatus.MobileStatus
                ),
                $('<td style="color: #7B7B7B;font-size: 13px;">').html(
                  item.EtStatus.StatusTs
                ),
                $('<td style="color: #7B7B7B;font-size: 13px;">').text(
                  item.EtStatus.FlagActive
                ),
                $('<td style="color: #7B7B7B;font-size: 13px;">').text(
                  item.EtStatus.CreatedBy
                )
              )
              .appendTo("#table_content");
          });
        },
        error: function (odata) {
          $this.spinner.hide();
          // console.log(odata);
        },
      });
      //alert(this.newdata);
      $(".request-status-form").removeClass("hide");
      $(".request-status-form").siblings().addClass("hide");
    } else if (innerText == "SERVICE REQUEST") {
      $this.spinner.show();
      $("#CreateServiceRequestConcern").val("");
      $("#CreateServiceRequestTopic").val("");
      $(".service-request-form .equip-id").text("");
      $(".service-request-form .model").text("");
      $(".service-request-form .srnum").text("");
      this.briefdesc = "";
      this.addinfo = "";
      $(".service-request-form").removeClass("hide");
      $(".service-request-form").siblings().addClass("hide");
      // console.log("service status");
      let userRef = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipinfo")
        .text()
        .trim();
      //let servURL="http://crdb00.sec.sharpamericas.com:8000/";
      let servURL = "";
      let partURL =
        "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetRequestInfoSet?$filter= IvSoldTo eq ";
      // let soldtoUser = "641433";
      let soldtoUser = localStorage.getItem("soldto");
      // let user = "SBSTST000";
      let user = localStorage.getItem("username").toUpperCase();
      // let servReqURL =
      //   servURL +
      //   partURL +
      //   "'" +
      //   soldtoUser +
      //   "' and IvUser eq '" +
      //   user +
      //   "' and IvEquipment eq '" +
      //   userRef +
      //   "'&$format=json";
      let servReqURL = `/getRequestInfo?$filter= IvSoldTo eq '${soldtoUser}' and IvUser eq '${user}' and IvEquipment eq '${userRef}' &$format=json`;
      // console.log(JSON.stringify(servReqURL));
      $.ajax({
        context: this,
        url: servReqURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("auth"));
        },
        success: function (servReqData) {
          $this.spinner.hide();
          let serviceData: any = servReqData.d.results[0];
          $(".service-request-form .equip-id").text(
            serviceData.EsEqInfo.EquipmentId
          );
          $(".service-request-form .model").text(
            serviceData.EsEqInfo.Model +
              " " +
              serviceData.EsEqInfo.ProdrefShtext
          );
          $(".service-request-form .srnum").text(serviceData.EsEqInfo.Sernr);
          $(".service-request-form .equip-ref").text(
            serviceData.EsEqInfo.Equipment
          );

          $("#CreateServiceRequestTopic .gen-topic").remove();
          $("#CreateServiceRequestConcern .det-con").remove();

          localStorage.setItem(
            "requestData",
            JSON.stringify(servReqData.d.results)
          );
          let topicArr = [];
          var detailArr = [];
          let lastTopicArr = [];

          var i;
          for (i = 0; i < servReqData.d.results.length; i++) {
            if (
              topicArr.indexOf(servReqData.d.results[i].EtCategories.Cat1) == -1
            ) {
              topicArr.push(servReqData.d.results[i].EtCategories.Cat1);
            }
          }

          for (i = 0; i < topicArr.length; i++) {
            $("#CreateServiceRequestTopic").append(
              "<option class='gen-topic'>" + topicArr[i] + "</option>"
            );
          }

          $("#CreateServiceRequestTopic").change(function () {
            var ab = $("#CreateServiceRequestTopic :selected").text().trim();
            var cd = [];
            var aa = [];
            detailArr = [];
            $("#CreateServiceRequestConcern .det-con").remove();
            // console.log("service change" + ab);
            for (i = 0; i < servReqData.d.results.length; i++) {
              if (servReqData.d.results[i].EtCategories.Cat1 == ab) {
                detailArr.push(servReqData.d.results[i].EtCategories.Cat2);
              }
            }
            // console.log(detailArr.length);
            for (i = 0; i < detailArr.length; i++) {
              $("#CreateServiceRequestConcern").append(
                "<option  class='det-con'>" + detailArr[i] + "</option>"
              );
            }
          });
        },
        error: function (odata) {
          // console.log(odata);
          $this.spinner.hide();
        },
      });
    } else if (innerText == "ORDER INFO") {
      $this.spinner.show();
      $(".order-consumables-info-sl .equip-id").text("");
      $(".order-consumables-info-sl .model").text("");
      $(".order-consumables-info-sl .sl-no").text("");
      $(".order-consumables-info-sl .contr-terms").text("");
      $(".order-consumables-info-sl .order-info").text("");
      $("#orderConsumables .order-data").remove();
      //alert($this.abab);
      $(".order-consumables-info-form").removeClass("hide");
      $(".order-consumables-info-form").siblings().addClass("hide");
      // console.log("Order Info");
      var ivorderinfo = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".o-nmbr")
        .text()
        .trim();
      //alert(ivorderinfo);
      //var ordInfoURL="http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsDetailSet?$filter= IvOrder eq '"+ivorderinfo+"'&$format=json";
      // var ordInfoURL =
      //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsDetailSet?$filter= IvOrder eq '" +
      //   ivorderinfo +
      // "'&$format=json";
      var ordInfoURL = `/getConsDetails?$filter= IvOrder eq '${ivorderinfo}'&$format=json`;

      // console.log(ordInfoURL);
      $.ajax({
        context: this,
        url: ordInfoURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("auth"));
        },
        success: function (orderData) {
          $this.spinner.hide();
          orderData = orderData;
          let newdata: any = orderData.d.results[0];
          //let newdata:any=reqData.d.results[0];
          //let newdata;
          $(".order-consumables-info-sl .equip-id").text(
            newdata.EtEqInfo.Equipment
          );
          $(".order-consumables-info-sl .model").text(newdata.EtEqInfo.Model);
          $(".order-consumables-info-sl .sl-no").text(newdata.EtEqInfo.Serial);
          $(".order-consumables-info-sl .contr-terms").text(
            newdata.EtEqInfo.Terms
          );
          $(".order-consumables-info-sl .order-info").text(newdata.EvCaption);
          $(".order-consumables-info-sl #orderConsumablesShippingInstr").val(
            newdata.EvText
          );

          $("#orderConsumables .order-data").remove();
          var newRows = "";
          //alert(orderData.d.results.length);
          for (var i = 0; i < orderData.d.results.length; i++) {
            //alert("Hi");
            newRows +=
              '<tr style="background:#fff; border-bottom: solid 1px #000;" class="order-data"><td class="part-no" style="border: solid 1px #ccc; padding: 5px; width: 56%">' +
              orderData.d.results[i].EtItems.ProductId +
              "</td>" +
              '<td class="desc" style="border: solid 1px #ccc; padding: 5px; width: 20%">' +
              orderData.d.results[i].EtItems.ProductDescr +
              "</td>" +
              '<td class="qty inqty" style="border: solid 1px #ccc; padding: 5px; width:6%; text-align:right;">' +
              orderData.d.results[i].EtItems.Quantity +
              "</td>" +
              '<td class="unit" style="border: solid 1px #ccc; padding: 5px; width: 8%">' +
              orderData.d.results[i].EtItems.Unit +
              "</td>" +
              '<td class="price" style="border: solid 1px #ccc; padding: 5px; width: 10%; text-align:right;">' +
              orderData.d.results[i].EtItems.Price +
              "</td></tr>";
            //console.log(orderData.d.results[i].EtItems.Unit);
          }
          // console.log(newRows);
          $("#orderConsumables .head").after(newRows);
          /*$("#orderConsumables td").addClass("desc");*/
          let indTotal: any;
          let trlen = $(".order-data").length;
          // console.log("tr length" + trlen);
          let Total = 0;
          for (var i = 0; i < trlen; i++) {
            Total +=
              parseFloat($(".inqty")[i].innerHTML) *
              parseFloat($(".price")[i].innerHTML);
            //Total+=indTotal[i];
            // console.log(
            //   parseFloat($(".inqty")[i].innerHTML) +
            //     " " +
            //     parseFloat($(".price")[i].innerHTML)
            // );
            //console.log(indTotal[i]);
          }
          $("#orderConsumables .newtotal").html(Total);
          // console.log(Total);

          $("#orderConsumables .inqty").keyup(function () {
            let trlen = $(".order-data").length;
            let Total1 = 0;
            let newrowTotal;
            //let rowtotal;
            //for (var i=0; i<trlen; i++)
            //{
            //alert("yyyy");
            newrowTotal =
              parseFloat($(this).val()) *
              Number($(this).parent().find("price").innerHTML);
            //Total+=indTotal[i];
            // console.log(
            //   parseFloat($(this).val()) +
            //     " " +
            //     Number($(this).parent().find("price").innerHTML)
            // );
            //console.log(indTotal[i]);
            //}
            for (var i = 0; i < trlen; i++) {
              Total1 +=
                parseFloat(
                  (<HTMLInputElement>(
                    document.getElementsByClassName("inqty")[i]
                  )).value
                ) * Number($(".price")[i].innerHTML);
            }

            $("#orderConsumables .newtotal").text(Total1);
            // console.log(Total1);
          });
        },
        error: function (odata) {
          // console.log(odata);
          $this.spinner.hide();
        },
      });
    } else if (innerText == "ORDER CONSUMABLES") {
      $this.spinner.show();
      $(".order-consumables-sl .equip-id").text("");
      $(".order-consumables-sl .model").text("");
      $(".order-consumables-sl .sl-no").text("");
      $(".order-consumables-sl .contr-terms").text("");
      $(".order-consumables-order-form .shipping-instr-create-order").val("");
      $("#orderConsumables2 .newtotal").text("");
      $("#orderConsumables2 .order-data-o").remove();
      // var innerText = $(this).text().trim();
      $(".main-page-form").addClass("active");
      $(".order-consumables-order-form").removeClass("hide");
      $(".order-consumables-order-form").siblings().addClass("hide");
      $("#orderConsumables2 .cons-data-cell").remove();
      $(".order-consumables-sl .message-no-items").hide();
      let equipRef = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipinfo")
        .text()
        .trim();
      $(".order-consumables-order-form .equiprefno").text(equipRef);

      var ivorder = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipinfo")
        .text()
        .trim();
      //alert(ivorder);
      //alert(ivorderinfo);
      //var ab="http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '56FC534B6CBF0CD0E1008000AC1D1C38'&$format=json"

      // var ordInfoURL =
      //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '" +
      //   ivorder +
      //   "'&$format=json";
      // console.log(ordInfoURL);
      // var userRefNo = '641433';
      var userRefNo = localStorage.getItem("soldto");
      // var thisuser = 'SBSTST000';
      var thisuser = localStorage.getItem("username").toUpperCase();
      var ordInfoURL = `/getConsInfo?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}' and IvEquipment eq '${ivorder}' &$format=json`;
      $.ajax({
        context: this,
        url: ordInfoURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("auth"));
        },
        success: function (orderData) {
          $this.spinner.hide();
          // console.log(JSON.stringify(orderData));
          orderData = orderData;
          let newdata: any = orderData.d.results[0];
          //let newdata:any=reqData.d.results[0];
          //let newdata;
          //alert(newdata.EsEqInfo.Equipment);

          $(".order-consumables-sl .equip-id").text(
            newdata.EsEqInfo.EquipmentId
          );
          $(".order-consumables-sl .model").text(newdata.EsEqInfo.Model);
          $(".order-consumables-sl .sl-no").text(newdata.EsEqInfo.Sernr);
          $(".order-consumables-sl .contr-terms").text(newdata.EsEqInfo.Terms);

          $("#orderConsumables2 .order-data-o").remove();
          var newRows = "";
          if (newdata.EsReturn.Type != "E") {
            //alert(orderData.d.results.length);
            for (var i = 0; i < orderData.d.results.length; i++) {
              //alert("Hi");
              newRows +=
                '<tr style="background:#fff; border-bottom: solid 1px #000;" class="order-data-o"><td class="part-no" style="border: solid 1px #ccc; padding: 5px; width: 56%" id="partno' +
                i +
                '">' +
                orderData.d.results[i].EtPPR.ProductId +
                "</td>" +
                '<td class="desc" style="border: solid 1px #ccc; padding: 5px; width: 20%">' +
                orderData.d.results[i].EtPPR.ProductDescr +
                "</td>" +
                "<td class=\"qty2\" style=\"border: solid 1px #ccc; padding: 5px; width: 6%\"><input type=\"text\" size=\"4\" class=\"inqty2 newqty2\" onkeyup=\"if(parseInt(this.parentElement.parentElement.lastElementChild.innerHTML.trim()) == 0){if(this.value > 1){this.value = 1; document.getElementById('quantity-error').style.display = 'block'}else{document.getElementById('quantity-error').style.display = 'none'}};var odc = document.getElementById('orderConsumables2').children[0].children;var total = 0;for(var i=1; i < odc.length-1; i++) {var priceval = parseInt(odc[i].children[2].getElementsByClassName('inqty2')[0].value)*parseFloat(odc[i].children[4].innerText);if(!priceval){priceval = 0}total+=priceval}odc[odc.length-1].getElementsByClassName('newtotal')[0].innerText = total;\"  value=" +
                orderData.d.results[i].EtPPR.Quantity +
                ' id="inq' +
                i +
                '"></input></td>' +
                '<td class="unit2" style="border: solid 1px #ccc; padding: 5px; width: 8%;">' +
                orderData.d.results[i].EtPPR.Unit +
                "</td>" +
                '<td class="price2" style="border: solid 1px #ccc; padding: 5px; width: 10%; text-align: right;">' +
                orderData.d.results[i].EtPPR.Price +
                "</td></tr>";

              //console.log(orderData.d.results[i].EtItems.Unit);
            }
          } else {
            $(".order-consumables-sl .message-no-items").show();
            $(".order-consumables-sl .message-no-items").text(
              newdata.EsReturn.Message
            );
          }
          // console.log(newRows);
          $("#orderConsumables2 .head").after(newRows);
          let indTotal: any;
          let trlen = $(".order-data-o").length;
          // console.log("tr length" + trlen);
          let Total = 0;
          for (var i = 0; i < trlen; i++) {
            Total +=
              parseFloat(
                (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
                  .value
              ) * parseFloat($(".price2")[i].innerHTML);
            //Total+=indTotal[i];
            // console.log(
            //   parseFloat(
            //     (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
            //       .value
            //   ) +
            //     " " +
            //     parseFloat($(".price2")[i].innerHTML)
            // );
            //console.log(indTotal[i]);
          }
          $("#orderConsumables2 .newtotal").html(Total);
          // console.log(Total);
        },
        error: function (odata) {
          $this.spinner.hide();
          // console.log(odata);
        },
      });

      $("#orderConsumables2 .inqty2").keyup(function () {
        // console.log("keydowndwd");
        let trlen = $(".order-data-o").length;
        let Total1 = 0;
        let newrowTotal;
        //let rowtotal;
        //for (var i=0; i<trlen; i++)
        //{
        //alert("yyyy");
        newrowTotal =
          parseFloat($(this).val()) *
          Number($(this).parent().find("price2").innerHTML);
        //Total+=indTotal[i];
        // console.log(
        //   parseFloat($(this).val()) +
        //     " " +
        //     Number($(this).parent().find("price2").innerHTML)
        // );
        // console.log(indTotal[i]);
        //}
        for (var i = 0; i < trlen; i++) {
          Total1 +=
            parseFloat(
              (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
                .value
            ) * Number($(".price2")[i].innerHTML);
        }

        $("#orderConsumables2 .newtotal").text(Total1);
        // console.log(Total1);
      });
    } else if (innerText == "METER READS") {
      $this.spinner.show();
      if ($(".new-meter-read").length != 0) {
        for (var i = 0; i < $(".new-meter-read").length; i++) {
          $("#newMeterRead" + [i]).val("");
          $(".new-read-date" + [i]).val("");
        }
      }
      $(".meter-reads-form .equip-id-meter").text("");
      $(".meter-reads-form  .model-meter").text("");
      $(".meter-reads-form  .sr-no-meter").text("");
      // console.log("METER READS");
      $(".meter-reads-form").removeClass("hide");
      $(".meter-reads-form").siblings().addClass("hide");

      $(".meter-reads-form .message-no-items").hide();
      let userEuipId = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-id")
        .text()
        .trim();
      let userEuipModel = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-modelno")
        .text()
        .trim();
      let userEuipSrno = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-srno")
        .text()
        .trim();

      let userRef = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipinfo")
        .text()
        .trim();
      //let servURL="http://crdb00.sec.sharpamericas.com:8000/";
      let servURL = "";
      let partURL =
        "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetMetersSet?$filter= IvEquipment eq ";
      // let soldtoUser = "641433";
      let soldtoUser = localStorage.getItem("soldto");
      // let user = "SBSTST000";
      let user = localStorage.getItem("username").toUpperCase();
      // let getmeterReadURL =
      //   servURL + partURL + "'" + userRef + "'&$format=json";
      let getmeterReadURL = `/getMeterSet?$filter= IvEquipment eq '${userRef}' &$format=json`;
      let getData = "";
      // console.log(JSON.stringify(getmeterReadURL));
      $(".meter-reads-form .new-meter-data").empty();
      $.ajax({
        context: this,
        url: getmeterReadURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", localStorage.getItem("auth"));
        },
        success: function (meterReadData) {
          $this.spinner.hide();
          let newmeterData: any = meterReadData.d.results;
          this.getData = newmeterData;
          // console.log(JSON.stringify(meterReadData));
          //alert(JSON.stringify(newmeterData));
          if (Array.isArray(newmeterData) && newmeterData.length) {
            localStorage.setItem("EquipmentId", newmeterData[0]["IvEquipment"]);
            //alert("hello");
            $(".meter-reads-form .equip-id-meter").text(userEuipId);
            $(".meter-reads-form  .model-meter").text(userEuipModel);
            $(".meter-reads-form  .sr-no-meter").text(userEuipSrno);
            //$(".meter-reads-form  .desc-number").text(userEuipSrno);
            var newcreate = "";
            for (var i = 0; i < newmeterData.length; i++) {
              newcreate +=
                "<p class='previous-billed-number'  style='color:#fff'>Previous Billed Meter: <span class='pr-billed" +
                [i] +
                "' style='color:#fff'></span><span class='pr-date'></span></p>" +
                "<p class='last-updated-number'  style='color:#fff'>Last Updated Meter: <span class='last-up-meter" +
                [i] +
                "'  style='color:#fff'></span><span class='last-up-date'></span></p>" +
                "<p class='last-updated-number'  style='color:#fff'>Color: <span class='color-meter" +
                [i] +
                "'  style='color:#fff'></span><span class='last-up-date'></span></p>" +
                "<div class='form-group input-form-group'>" +
                "<div class='err-message' id='meter-err-message" +
                [i] +
                "' style='color:red'></div>" +
                "<div class='desc-number" +
                [i] +
                "' style='display:none'></div>" +
                "<div class='new-read-date" +
                [i] +
                "' style='display:none'></div>" +
                "<div class='meter-guid" +
                [i] +
                "' style='display:none'></div>" +
                "<input type='text' id='newMeterRead" +
                [i] +
                "' name='newMeterRead" +
                [i] +
                "' class='form-control input new-meter-read' placeholder='New Meter Read'/>" +
                // "<label for='newMeterRead" +
                // [i] +
                // "'>New Meter Read</label>" +
                "</div>" +
                "<div class='form-group input-form-group'>" +
                "<input type='text' id='usage" +
                [i] +
                "' name='usage" +
                [i] +
                "' class='form-control input usage-read' placeholder='Usage' disabled/>" +
                // "<label for='usage" +
                // [i] +
                // "' class='usage'>Usage</label>" +
                "</div>";
            }
            $(".meter-reads-form .new-meter-data").append(newcreate);
            /*$(".meter-reads-form .desc-meter").text(newmeterData.EtMeters.Description);
      $(".meter-reads-form .pr-billed").text(newmeterData.EtMeters.PrvReadingDisp);
      $(".meter-reads-form .last-up-meter").text(newmeterData.EtMeters.CurrentReadingDisp);*/

            /* $("#newMeterRead").keyup(function(e){
        // console.log(newmeterData);
        let lastmeterRead=meterReadData.d.results[0].EtMeters.CurrentReading.trim();
        let newmeterRead=$("#newMeterRead").val();
        $(".meter-reads-form .err-message").text('');
        if (!validateChars.test(newmeterRead)) {
          e.preventDefault();
          $("#newMeterRead").val($(this).val().replace(/[A-Za-z!@#$%^&*()'<>./?{}[\\/\]"]/g, ''));
          //return;
        }

        newmeterRead=newmeterRead.replace(/,/g, '');
        if (parseFloat(newmeterRead) < parseFloat(lastmeterRead)){
          $(".meter-reads-form .err-message").text("New meter reading can not be less than last reading");
          //alert("no");
          parseInt($("#usage").val(''));
          return;
        }

        if (parseFloat(newmeterRead) < parseFloat(lastmeterRead)){
          $(".meter-reads-form .err-message").text("New meter reading can not be less than last reading");
          //alert("no");
          parseInt($("#usage").val(''));
          return;
        }

        let usageVal=parseFloat(newmeterRead)-parseFloat(lastmeterRead);
        //alert(lastmeterRead + " " + $(this).val());
        parseInt($("#usage").val(usageVal));

      });*/

            //////////////////////New///////////////////////
            for (var i = 0; i < $(".new-meter-read").length; i++) {
              //alert($(".new-meter-read").length);

              $(".meter-reads-form .pr-billed" + [i]).text(
                newmeterData[i].EtMeters.PrvReadingDisp
              );
              $(".meter-reads-form .last-up-meter" + [i]).text(
                newmeterData[i].EtMeters.CurrentReadingDisp
              );
              $(".meter-reads-form .color-meter" + [i]).text(
                newmeterData[i].EtMeters.Label
              );
              $(".meter-reads-form .desc-number" + [i]).text(
                newmeterData[i].EtMeters.Description
              );
              $(".meter-reads-form .new-read-date" + [i]).text(
                newmeterData[i].EtMeters.NewReadingDt
              );
              $(".meter-reads-form .meter-guid" + [i]).text(
                newmeterData[i].EtMeters.Guid
              );
              var validateChars = new RegExp("[0-9,]");
              $("#newMeterRead" + [i]).keyup(function (e) {
                //alert(JSON.stringify(meterReadData));
                //console.log(newmeterData);
                //let lastmeterRead=  this.getData[i].EtMeters.CurrentReading.trim();
                var ab = $(this).attr("id");
                var cd = ab.replace("newMeterRead", "").trim();
                //alert(cd);
                let lastmeterRead =
                  meterReadData.d.results[cd].EtMeters.CurrentReading.trim();
                let newmeterRead = $(this).val();
                $(".meter-reads-form .err-message").text("");
                if (!validateChars.test(newmeterRead)) {
                  e.preventDefault();
                  $(this).val(
                    $(this)
                      .val()
                      .replace(/[A-Za-z!@#$%^&*()'<>./?{}[\\/\]"]/g, "")
                  );
                  //return;
                }
                //alert($(this).val());
                newmeterRead = $(this).val().replace(/,/g, "");

                if ($(this).val().trim()) {
                  if (parseFloat($(this).val()) < parseFloat(lastmeterRead)) {
                    $("#meter-err-message" + [cd]).text(
                      "New meter reading can not be less than last reading"
                    );
                    //alert("no");
                    parseInt($("#usage" + [cd]).val(0));
                    return;
                  }

                  let usageVal =
                    parseFloat(newmeterRead) - parseFloat(lastmeterRead);
                  //alert(lastmeterRead + " " + $(this).val());
                  parseInt($("#usage" + [cd]).val(usageVal));

                  if (usageVal > parseFloat(lastmeterRead) * 0.2) {
                    $("#meter-err-message" + [cd]).text(
                      "Usage value can not be greater than 20 percent last reading"
                    );
                    //alert("no");
                    parseInt($("#usage" + [cd]).val(0));
                    return;
                  }
                }
              });
            }
          } else {
            //alert("Hi");
            $(".meter-reads-form .equip-id-meter").text("");
            $(".meter-reads-form  .model-meter").text("");
            $(".meter-reads-form  .sr-no-meter").text("");
            $(".meter-reads-form .desc-meter").text("");
            $(".meter-reads-form .pr-billed").text("");
            $(".meter-reads-form .last-up-meter").text("");
            $(".meter-reads-form .message-no-items").show();
          }

          var validateChars = new RegExp("[0-9,]");
        },
        error: function (odata) {
          $this.spinner.hide();
          // console.log(odata);
        },
      });
    } else if (innerText == "EQUIPMENT INFO") {
      $(".equipment-info-form .equip-id-equip").text("");
      $(".equipment-info-form  .model-equip-info-equip").text("");
      $(".equipment-info-form  .sr-no-equip-info").text("");
      $(".equipment-info-form  .contr-terms-equip-info").text("");
      $(".equipment-info-form  .equip-loc").text("");
      $(".equipment-info-form").removeClass("hide");
      $(".equipment-info-form").siblings().addClass("hide");
      let userEuipId = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-id")
        .text()
        .trim();
      let userEuipModel = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-modelno")
        .text()
        .trim();
      let userEuipSrno = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-srno")
        .text()
        .trim();
      let userEuipTerms = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-terms")
        .text()
        .trim();
      let userEuipLocation = $(event.target)
        .parent()
        .parent()
        .parent()
        .find(".equipin-loc")
        .text()
        .trim();

      $(".equipment-info-form .equip-id-equip").text(userEuipId);
      $(".equipment-info-form  .model-equip-info-equip").text(userEuipModel);
      $(".equipment-info-form  .sr-no-equip-info").text(userEuipSrno);
      $(".equipment-info-form  .contr-terms-equip-info").text(userEuipTerms);
      $(".equipment-info-form  .equip-loc").text(userEuipLocation);
    }
  }
  openConOrdrs() {
    //alert("gggg");
    var $this = this;
    var innerText = $(this).text().trim();
    $(".main-page-form").addClass("active");
    $(".order-consumables-order-form").removeClass("hide");
    $(".order-consumables-order-form").siblings().addClass("hide");
    $("#orderConsumables2 .cons-data-cell").remove();

    var ivorder = $(this).parent().parent().find(".equipinfo").text();
    //alert("Hello"+$(this).parent().html());
    //alert(ivorderinfo);
    // var ordInfoURL =
    //   "http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsDetailSet?$filter= IvOrder eq '" +
    //   ivorder +
    //   "'&$format=json";
    var ordInfoURL = `/getConsDetails?$filter= IvOrder eq '${ivorder}'&$format=json`;
    $.ajax({
      context: this,
      url: ordInfoURL,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
      },
      success: function (orderData) {
        orderData = orderData;
        let newdata: any = orderData.d.results[0];
        //let newdata:any=reqData.d.results[0];
        //let newdata;
        $(".order-consumables-sl .equip-id").text(newdata.EtEqInfo.Equipment);
        $(".order-consumables-sl .model").text(newdata.EtEqInfo.Model);
        $(".order-consumables-sl .sl-no").text(newdata.EtEqInfo.Serial);
        $(".order-consumables-sl .contr-terms").text(newdata.EtEqInfo.Terms);

        $("#orderConsumables2 .order-data-o").remove();
        var newRows = "";
        //alert(orderData.d.results.length);
        for (var i = 0; i < orderData.d.results.length; i++) {
          //alert("Hi");
          newRows +=
            '<tr style="background:#fff; border-bottom: solid 1px #000;" class="order-data-o"><td class="part-no">' +
            orderData.d.results[i].EtItems.ProductId +
            "</td>" +
            '<td class="desc">' +
            orderData.d.results[i].EtItems.ProductDescr +
            "</td>" +
            '<td class="qty2"><input type="text" size="8" class="inqty2" onchange="unitCheck()" value=' +
            orderData.d.results[i].EtItems.Quantity +
            "></input></td>" +
            '<td class="price2">' +
            orderData.d.results[i].EtItems.Price +
            "</td>" +
            '<td class="unit2">' +
            orderData.d.results[i].EtItems.Unit +
            "</td></tr>";
          //console.log(orderData.d.results[i].EtItems.Unit);
        }
        // console.log(newRows);
        $("#orderConsumables2 .head").after(newRows);
        let indTotal: any;
        let trlen = $(".order-data-o").length;
        // console.log("tr length" + trlen);
        let Total = 0;
        for (var i = 0; i < trlen; i++) {
          Total +=
            parseFloat(
              (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
                .value
            ) * parseFloat($(".price2")[i].innerHTML);
          //Total+=indTotal[i];
          // console.log(
          //   parseFloat(
          //     (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
          //       .value
          //   ) +
          //     " " +
          //     parseFloat($(".price2")[i].innerHTML)
          // );
          //console.log(indTotal[i]);
        }
        $("#orderConsumables2 .newtotal").html(Total);
        // console.log(Total);
      },
      error: function (odata) {
        // console.log(odata);
      },
    });
    $("#orderConsumables2 .inqty2").keyup(function () {
      let trlen = $(".order-data-o").length;
      let Total1 = 0;
      let newrowTotal;
      //let rowtotal;
      //for (var i=0; i<trlen; i++)
      //{
      //alert("yyyy");
      newrowTotal =
        parseFloat($(this).val()) *
        Number($(this).parent().find("price2").innerHTML);
      //Total+=indTotal[i];
      // console.log(
      //   parseFloat($(this).val()) +
      //     " " +
      //     Number($(this).parent().find("price2").innerHTML)
      // );
      //console.log(indTotal[i]);
      //}
      for (var i = 0; i < trlen; i++) {
        Total1 +=
          parseFloat(
            (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
              .value
          ) * Number($(".price2")[i].innerHTML);
      }

      $("#orderConsumables2 .newtotal").text(Total1);
      // console.log(Total1);
    });

    function unitCheck() {
      // console.log("event hapeening");
    }
  }

  checkitem() {
    $(document).ready(function () {
      var $this;
      // console.log("clicked");
      $this = $("#carousel");
      if ($("#carousel .carousel-inner .item:first").hasClass("active")) {
        $this.children(".left").hide();
        $this.children(".right").show();
      } else if ($("#carousel .carousel-inner .item:last").hasClass("active")) {
        $this.children(".right").hide();
        $this.children(".left").show();
      } else {
        $this.children(".carousel-control").show();
      }
    });
  }
  callCheckItem() {
    $(document).ready(function () {
      $("#carousel").on("click", this.checkitem);
    });
  }

  //getEquipmentInfo(equipmentId : string) : void{
  //debugger;
  getEquipmentInfo() {
    this.equipmentList = [];
    this.spinner.show();
    var defEquip = localStorage.getItem("equip");
    if (defEquip) {
      var jEquip = JSON.parse(defEquip);
      //console.log(equiData.d)
      for (var i = 0; i < jEquip.d.results.length; i++) {
        this.equipmentList.push(jEquip.d.results[i].EtList);
      }
      if (this.equipmentList.length == 0) {
        this.nodata = "No data";
      }

      this.equipmentsArray = this.equipmentList.slice();
      this.spinner.hide();
    } else {
      this._equipmentService.getEquipmentInfo().subscribe(
        (equiData) => {
          this.spinner.hide();
          // console.log(equiData);
          localStorage.setItem("equip", JSON.stringify(equiData));
          //console.log(equiData.d)
          for (var i = 0; i < equiData.d.results.length; i++) {
            this.equipmentList.push(equiData.d.results[i].EtList);
          }
          if (this.equipmentList.length == 0) {
            this.nodata = "No data";
          }

          this.equipmentsArray = this.equipmentList.slice();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
    }
  }

  /**Hanumanth code */
  getEquipdefault(equiData) {
    this.equipmentList = [];
    for (var i = 0; i < equiData.d.results.length; i++) {
      this.equipmentList.push(equiData.d.results[i].EtList);
    }
    if (this.equipmentList.length == 0) {
      this.nodata = "No data";
    }

    this.equipmentsArray = this.equipmentList.slice();
  }
  /**Hanumanth code */

  getEquipmentInfoDetail(equipmentId: string): void {
    //debugger;
    this._equipmentService.equipmentInfo = {};
    this._equipmentService.equipmentInfo = this.equipmentList.filter(
      (e) => e.Equipment == equipmentId
    );
    this._router.navigate(["/equipment-info"]);
  }

  openOrdersData() {
    this.openOrders = [];

    this._equipmentService.getopenOrders().subscribe((data) => {
      localStorage.setItem("openord", JSON.stringify(data));
      for (var i = 0; i < data.d.results.length; i++) {
        this.openOrders.push(data.d.results[i].EtHistory);
      }
      this.ordersArray = this.openOrders.slice();
      if (this.openOrders.length == 0) {
        this.noorderdata = "No data";
      }
    });
  }

  /**Hanumanth code  */
  getOpenOrderdefault(data) {
    this.openOrders = [];
    for (var i = 0; i < data.d.results.length; i++) {
      this.openOrders.push(data.d.results[i].EtHistory);
    }
    this.ordersArray = this.openOrders.slice();
    if (this.openOrders.length == 0) {
      this.noorderdata = "No data";
    }
  }
  /**Hanumanth code */

  openRequestData() {
    this.openRequest = [];

    this._equipmentService.getopenRequests().subscribe((data) => {
      localStorage.setItem("openreq", JSON.stringify(data));
      for (var i = 0; i < data.d.results.length; i++) {
        this.openRequest.push(data.d.results[i].EtHistory);
      }
      this.requestsArray = this.openRequest.slice();
      if (this.openRequest.length == 0) {
        this.norequestdata = "No data";
      }
      // console.log(this.openRequest);
    });
  }

  /**Hanumanth code */
  getOpenReqdeafault(data) {
    this.openRequest = [];
    for (var i = 0; i < data.d.results.length; i++) {
      this.openRequest.push(data.d.results[i].EtHistory);
    }
    this.requestsArray = this.openRequest.slice();
    if (this.openRequest.length == 0) {
      this.norequestdata = "No data";
    }
  }
  /**Hanumanth code */

  getUserInfo() {
    //alert(JSON.stringify(this.userInfoService.userdetail));
    //this.userid=this.userInfoService.userdetail.trim();
    this.userid = "Deys";
  }

  /*headerRef(){
    this.hdrService.headerRefVal().subscribe((data) => {
      alert(JSON.stringify(data));
    })
  }*/

  postConsOrder() {
    this.spinner.show();
    var $this = this;
    var narray = [];
    var rows = $("#orderConsumables2 tr");
    //alert($("#orderConsumables2 tr").length);
    for (var i = 0; i < $("#orderConsumables2 tr").length; i++) {
      //var ab=$("#orderConsumables2 #partno"+i).text();
      //var cd=$("#orderConsumables2 #inq"+i).val();

      narray.push({
        SoldTo: localStorage.getItem("soldto"),
        ProductId: $("#partno" + i).text(),
        Quantity: $("#inq" + i).val(),
      });
      //narray.pop();
      //narray.pop();
    }
    narray.pop();
    narray.pop();

    //alert(JSON.stringify(narray));

    var orderdata: any = {
      IvSoldTo: localStorage.getItem("soldto"),
      IvShippingInstruct: "",
      IvEquipment: "",
      IvText: "",
      EtProductsSet: "",
    };
    var shipInstr = $(
      ".order-consumables-order-form .shipping-instr-create-order"
    ).val();
    //alert($(".order-consumables-order-form .shipping-instr-create-order").val() + "Hello" +$(".order-consumables-order-form .equiprefno").text())
    var equipRefno = $(".order-consumables-order-form .equiprefno").text();
    orderdata.IvShippingInstruct = shipInstr;
    orderdata.IvEquipment = equipRefno;
    orderdata.EtProductsSet = narray;

    var orderdata1 = orderdata;
    // console.log(orderdata1);
    //alert(JSON.stringify(orderdata));

    var token = "";
    var cookie = "";
    /*$.ajax({
            type: "GET",
            url: "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/",
            //data: orderdata1,

            headers: {'X-CSRF-Token':'Fetch'},
            success: function(data,
              textStatus,
              XMLHttpRequest) {
           var token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
                 alert(this.url + "hhhh" + token);
      },
            async: false,
            //crossDomain: true
            //dataType: 'json'
          });*/

    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        //xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
        // xhr.setRequestHeader('Access-Control-Allow-Methods','GET, POST, PUT, OPTIONS');
        //xhr.setRequestHeader('Access-Control-Expose-Headers', 'x-csrf-token');
        //xhr.setRequestHeader('Access-Control-Allow-Headers', 'x-csrf-token, Content-Type, Authorization');
      },
      success: function (data, textStatus, XMLHttpRequest) {
        // token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      error: function (odata) {
        // console.log(odata);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      // debugger;
      document.cookie = cookie;
      $.ajax({
        url: `/createorders`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: orderdata1, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("Cookie", cookie);
        },
        success: function (odata) {
          // oDialog.setTitle("File
          // Uploaded");
          // oDialog.open();
          // document.location.reload(true);
          // console.log("Crate order success:", JSON.stringify(odata));
          $this.spinner.hide();
          //alert(odata.d.EvType);
          if (odata.d.EvType == "S") {
            // alert("Create Order success. Order number: " + odata.d.IvOrderNo);
            $this.textdata =
              "Create Order success. Order number: " + odata.d.IvOrderNo;
            // $this.ngxSmartModalService.open('myModal');
            $this.toastr.success($this.textdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
            $(".order-consumables-sl .equip-id").text("");
            $(".order-consumables-sl .model").text("");
            $(".order-consumables-sl .sl-no").text("");
            $(".order-consumables-sl .contr-terms").text("");
            $(".order-consumables-order-form .shipping-instr-create-order").val(
              ""
            );
            $("#orderConsumables2 .newtotal").text("");
            $("#orderConsumables2 .order-data-o").remove();
            $(".main-page-form").removeClass("active");
            $this.getEquipmentInfo();
            $this.openOrdersData();
            $this.openRequestData();
          }
          if (odata.d.EvType == "E") {
            // alert(odata.d.EvMessage);
            $this.textdata = odata.d.EvMessage;
            // $this.ngxSmartModalService.open('myModal');
            $this.toastr.success($this.textdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
        },
        error: function (odata) {
          $this.spinner.hide();
          $this.textdata = "error:" + JSON.stringify(odata);
          $this.toastr.error($this.textdata, "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }

  postCreatServiceeRequest() {
    this.spinner.show();
    var $this = this;
    //var narray=[];
    // console.log(JSON.parse(localStorage.getItem("requestData")));
    var cat1 = $("#CreateServiceRequestTopic :selected").text().trim();
    // console.log(cat1);
    var cat2 = $("#CreateServiceRequestConcern :selected").text().trim();
    // console.log(cat2);
    var ivcat1;
    var ivcat2;
    var arr = JSON.parse(localStorage.getItem("requestData"));
    for (var i = 0; i < arr.length; i++) {
      // console.log(arr[i].EtCategories.Cat1);
      if (arr[i].EtCategories.Cat1 == cat1) {
        ivcat1 = arr[i].EtCategories.CatCode1;
        break;
      }
    }

    for (var i = 0; i < arr.length; i++) {
      // console.log(arr[i].EtCategories.Cat2);
      if (arr[i].EtCategories.Cat2 == cat2) {
        ivcat2 = arr[i].EtCategories.CatCode2;
        break;
      }
    }

    // console.log(ivcat1 + "this is cat1" + ivcat2);
    // console.log(this.briefdesc);
    // console.log(this.addinfo);
    var createServiceReqData = {
      // IvSoldTo: localStorage.getItem('soldto'),
      // IvEquipment: $(".service-request-form .equip-ref")
      //   .text()
      //   .trim(),
      // // Cat1: $("#CreateServiceRequestTopic :selected")
      // //   .text()
      // //   .trim(),
      // // Cat2: $("#CreateServiceRequestConcern :selected")
      // //   .text()
      // //   .trim(),
      // IvComments: this.addinfo,
      // IvDescription: this.briefdesc,
      // IvCat1 : ivcat1,
      // IvCat2 : ivcat2
      IvSoldTo: localStorage.getItem("soldto"),
      IvEquipment: $(".service-request-form .equip-ref").text().trim(),
      // IvComments: $("#CreateServiceRequestTopic :selected")
      //   .text()
      //   .trim(),
      // IvDescription: $("#CreateServiceRequestConcern :selected")
      //   .text()
      //   .trim(),
      IvComments: this.addinfo,
      IvDescription: this.briefdesc,
      IvCat1: ivcat1,
      IvCat2: ivcat2,
    };
    var newData = createServiceReqData;
    // alert(newData);

    /*this.http.post("sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/CreateRequestSet", newData, {headers:
      {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} })
      .subscribe((res:any) => {
       let abgg = res.json();
        console.log(abgg);
      },
      (error) => {
        console.log(error);
      });*/

    var token = "";
    var cookie = "";
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        // token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      error: function (odata) {
        // console.log(odata);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      // console.log(token + "this is the token to send");
      $.ajax({
        url: `/createrequestset`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: newData, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (data) {
          // console.log("Crate service success:", JSON.stringify(data));
          //alert(data.d.EvType);
          $this.spinner.hide();
          if (data.d.EvType == "S") {
            $this.textdata =
              "Create Service request success. Service order number: " +
              data.d.IvOrderNo;
            // $this.ngxSmartModalService.open('myModal');
            $this.toastr.success($this.textdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
            // $('#CreateServiceRequestConcern').val('');
            // $('#CreateServiceRequestTopic').val('');
            // $this.briefdesc = '';
            // $this.addinfo = '';
            // $(".service-request-form .equip-id").val('');
            // $(".service-request-form .model").val('');
            // $(".service-request-form .srnum").val('');
            $this.getEquipmentInfo();
            $this.openOrdersData();
            $this.openRequestData();
            $("#CreateServiceRequestConcern").val("");
            $("#CreateServiceRequestTopic").val("");
            $(".service-request-form .equip-id").text("");
            $(".service-request-form .model").text("");
            $(".service-request-form .srnum").text("");
            $(".main-page-form").removeClass("active");
            $this.briefdesc = "";
            $this.addinfo = "";
            // alert(
            //   "Create Service request success. Service order number: " +
            //     data.d.IvOrderNo
            // );
            //$(".service-request-form .success-data").html("Service created successfully. Service order number: "+ data.d.IvOrderNo).show();
            //$(".service-request-form .create-service-request").hide();
          }
        },
        error: function (data) {
          $this.spinner.hide();
          $this.textdata = "";
          // console.log("error:", JSON.stringify(data));
          if (
            data.responseJSON &&
            data.responseJSON.error &&
            data.responseJSON.error.message
          ) {
            $this.textdata = data.responseJSON.error.message.value;
          } else {
            $this.textdata = "error:" + JSON.stringify(data);
          }
          // $this.ngxSmartModalService.open('myModal');
          $this.toastr.error($this.textdata, "", {
            closeButton: true,
            disableTimeOut: true,
          });
          // alert("error:"+JSON.stringify(data));
        },
      });
    }, 5000);
  }

  postMeterData() {
    this.spinner.show();
    var narray = [];
    var $this = this;
    var rows = $(".new-meter-read");
    //alert($("#orderConsumables2 tr").length);
    for (var i = 0; i < $(".new-meter-read").length; i++) {
      //var ab=$("#orderConsumables2 #partno"+i).text();
      //var cd=$("#orderConsumables2 #inq"+i).val();

      narray.push({
        Equipment: $(".meter-reads-form .equip-id-meter").text().trim(),
        Guid: $(".meter-reads-form .meter-guid" + [i])
          .text()
          .trim(),
        Description: $(".meter-reads-form .desc-number" + [i])
          .text()
          .trim(),
        NewReading: $("#newMeterRead" + [i]).val(),
        NewReadingDt: $(".new-read-date" + [i])
          .text()
          .trim(),
      });
      //narray.pop();
      //narray.pop();
    }
    var MetersData: any = {
      // IvEquipment: $(".meter-reads-form .equip-id-meter")
      //   .text()
      //   .trim(),
      IvEquipment: localStorage.getItem("EquipmentId"),
      EtMeterSet: "",
    };

    MetersData.EtMeterSet = narray;
    // MetersData = JSON.stringify(MetersData);
    // console.log(MetersData);

    var token = "";
    var cookie = "";
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        // token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        // console.log(data);
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      error: function (odata) {
        // console.log(odata);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      $.ajax({
        url: `/postmeterdata`,
        async: false,
        dataType: "json",
        data: JSON.stringify({ actual: MetersData, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          // console.log("Meter reads success:",JSON.stringify(odata));
          // alert(JSON.stringify(odata));
          var data1: any = odata.d.EtMeterSet.results;
          var msg = "";
          for (var i = 0; i < data1.length; i++) {
            msg += data1[i].Message + "\n";
          }
          $this.spinner.hide();
          $this.textdata = msg;
          $this.toastr.success(msg, "", {
            closeButton: true,
            disableTimeOut: true,
          });
          if ($(".new-meter-read").length != 0) {
            for (var i = 0; i < $(".new-meter-read").length; i++) {
              $("#newMeterRead" + [i]).val("");
              $(".new-read-date" + [i]).val("");
            }
          }
          $(".meter-reads-form .equip-id-meter").text("");
          $(".meter-reads-form  .model-meter").text("");
          $(".meter-reads-form  .sr-no-meter").text("");
          $(".main-page-form").removeClass("active");
          $this.getEquipmentInfo();
          $this.openOrdersData();
          $this.openRequestData();
          // $this.ngxSmartModalService.open('myModal');
          localStorage.removeItem("EquipmentId");
        },
        error: function (data) {
          $this.spinner.hide();
          $this.textdata = "error:" + JSON.stringify(data);
          // console.log("error:", JSON.stringify(data));
          // $this.ngxSmartModalService.open('myModal');
          $this.toastr.error($this.textdata, "", {
            closeButton: true,
            disableTimeOut: true,
          });
          // alert("error:"+JSON.stringify(data));
        },
      });
    }, 5000);
  }

  clearData() {
    // console.log("Clear Data");
    $("#CreateServiceRequestConcern").val("");
    $("#CreateServiceRequestTopic").val("");
    $(".service-request-form .equip-id").text("");
    $(".service-request-form .model").text("");
    $(".service-request-form .srnum").text("");
    this.briefdesc = "";
    this.addinfo = "";
    for (var i = 0; i < $(".new-meter-read").length; i++) {
      $("#newMeterRead" + [i]).val("");
      $(".new-read-date" + [i]).val("");
    }
    $(".meter-reads-form .equip-id-meter").text("");
    $(".meter-reads-form  .model-meter").text("");
    $(".meter-reads-form  .sr-no-meter").text("");
    $(".order-consumables-sl .equip-id").text("");
    $(".order-consumables-sl .model").text("");
    $(".order-consumables-sl .sl-no").text("");
    $(".order-consumables-sl .contr-terms").text("");
    $(".order-consumables-order-form .shipping-instr-create-order").val("");
    $("#orderConsumables2 .newtotal").text("");
    $("#orderConsumables2 .order-data-o").remove();
    $(".order-consumables-info-sl .equip-id").text("");
    $(".order-consumables-info-sl .model").text("");
    $(".order-consumables-info-sl .sl-no").text("");
    $(".order-consumables-info-sl .contr-terms").text("");
    $(".order-consumables-info-sl .order-info").text("");
    $("#orderConsumables .order-data").remove();
    $(".equipment-info-form .equip-id-equip").text("");
    $(".equipment-info-form  .model-equip-info-equip").text("");
    $(".equipment-info-form  .sr-no-equip-info").text("");
    $(".equipment-info-form  .contr-terms-equip-info").text("");
    $(".equipment-info-form  .equip-loc").text("");
    $(".req-status-sl .equip-id").text("");
    $(".req-status-sl .order-info").text("");
    $(".req-status-sl .gen-topic").text("");
    $(".req-status-sl .det-concern").text("");
    $(".req-status-sl .rep-name").text("");
    $(".req-status-sl .rep-tel").text("");
    $(".req-status-sl .mob-status").text("");
    $(".req-status-sl .time").html("");
    $(".req-status-sl .curr-active").text("");
    // $(".req-status-sl .mob-status").text(newdata.MobileStatus);
    $(".req-status-sl .tech").text("");
    $("#table_content tr").remove();
  }

  searchData() {
    // console.log(this.searchdata.value.searchelement);
    var data = this.searchdata.value.searchelement;
    if (data) {
      // console.log(data + "this is search value");
      if (data && data.trim() != "") {
        this.equipmentList = this.equipmentsArray.filter((item) => {
          return (
            item.Sernr.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
            item.EquipmentId.toLowerCase().indexOf(data.toLowerCase()) > -1
          );
        });
        this.openRequest = this.requestsArray.filter((item) => {
          return (
            item.Serial.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
            item.Equipment.toLowerCase().indexOf(data.toLowerCase()) > -1
          );
        });
        this.openOrders = this.ordersArray.filter((item) => {
          return (
            item.Serial.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
            item.Equipment.toLowerCase().indexOf(data.toLowerCase()) > -1
          );
        });
      } else {
        this.equipmentList = this.equipmentsArray;
        this.openRequest = this.requestsArray;
        this.openOrders = this.ordersArray;
      }
    } else {
      this.equipmentList = this.equipmentsArray;
      this.openRequest = this.requestsArray;
      this.openOrders = this.ordersArray;
    }
  }

  openSearch() {
    this.showSearch = true;
  }
}
