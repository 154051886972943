import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserStateService } from './userstate.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private userStateService: UserStateService, private _router: Router) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userStateService.isAuthenticated()) {
            return true;
        }
        if(this._router.routerState.snapshot.url.indexOf('home') === -1){
            this._router.navigate(['/home'], {
                queryParams: {
                    "enableLogin": true
                }
            });
        }else{
            this.userStateService.showHideLoginMode().next(true);
        }
        
        return false;

    }


}
