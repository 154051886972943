import { ReportsViewComponent } from "./reports-view/reports-view.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import { ChangeContactComponent } from "./change-contact/change-contact.component";
import { ReportsComponent } from "./reports/reports.component";
import { ReportsV2Component } from "./reports-v2/reports-v2.component";
import { ReportsDetailComponent } from "./reports-detail/reports-detail.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home/home.component";
import { MainComponent } from "./main/main.component";
import { EquipmentInfoComponent } from "./equipment/equipment-info/equipment-info.component";
import { EquipmentsAllComponent } from "./equipments-all/equipments-all.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { OrderComponent } from "./order/order.component";
import { Home2Component } from "./home2/home2.component";
import { MeterReadsComponent } from "./meter-reads/meter-reads.component";
import { AuthenticationGuard } from "microsoft-adal-angular6";
import { AuthGuard } from "./services/auth.gaurd";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "dashboard",
    component: MainComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "equipments",
    component: EquipmentsAllComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "equipment-info", component: EquipmentInfoComponent },
  {
    path: "invoice",
    component: InvoiceComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "order",
    component: OrderComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "home", component: Home2Component },
  {
    path: "meter-reads",
    component: MeterReadsComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  // {
  //   path: "reports-new",
  //   component: ReportsComponent,
  //   pathMatch: "full",
  //   canActivate: [AuthGuard],
  // },
  {
    path: "reports-v2",
    component: ReportsV2Component,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "reports-detail",
    component: ReportsDetailComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "change-contact",
    component: ChangeContactComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "audit-log",
    component: AuditLogComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "report-view",
    component: ReportsViewComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
