import { Spare } from './spare';

export const SPARES: Spare[] = [
    {
        ID: "MX607HB",
        name: "Toner Cart Black",
        imgFileName: "product--mx607hb--toner-cart-black.svg"
    },
    {
        ID: "MX607HC",
        name: "Toner Cart Cyan",
        imgFileName: "product--mx607hc--toner-cart-cyan.svg"
    },
    {
        ID: "MX607HM",
        name: "Toner Cart Magenta",
        imgFileName: "product--mx607hm--toner-cart-magenta.svg"
    },
    {
        ID: "MX607HY",
        name: "Toner Cart Yellow",
        imgFileName: "product--mx607hY--toner-cart-yellow.svg"
    },
    {
        ID: "MX607XY",
        name: "Staple Cart XY",
        imgFileName: "product--mx607xy--staple-cart-xy.svg"
    },
    {
        ID: "MX607XZ",
        name: "Staple Cart XZ",
        imgFileName: "product--mx607xz--staple-cart-xz.svg"
    },
    {
        ID: "MX607XY1",
        name: "Staple Cart XY1",
        imgFileName: "product--mx607xy--staple-cart-xy.svg"
    },
    {
        ID: "MX607XZ1",
        name: "Staple Cart XZ1",
        imgFileName: "product--mx607xz--staple-cart-xz.svg"
    }
];