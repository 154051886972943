import { EquipmentService } from "./../../services/equipement-service.service";
import { Component, OnInit, Input } from "@angular/core";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { UserStateService } from "src/app/services/userstate.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
declare var config;
@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  body: HTMLElement;
  loginform: HTMLElement;
  forgotpswd: HTMLElement;
  formsiblings: HTMLElement;
  signupformelement: HTMLElement;

  headerlinks: HTMLElement;
  parentEle: HTMLElement;
  username: string;
  userpassword: string;
  showSupport = 0;
  public loginformdata: FormGroup;
  public requestformdata: FormGroup;
  public resetformdata: FormGroup;
  //constructor(private adlaservice: MsAdalAngular6Service, public equip: EquipmentService, private http: HttpClient) { }

  constructor(
    private userStateService: UserStateService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private EquipService: EquipmentService
  ) {
    this.loginformdata = this.formBuilder.group({
      username: new FormControl("", Validators.compose([Validators.required])),
      userpassword: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
    this.resetformdata = this.formBuilder.group({
      usernamepass: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      oldpass: new FormControl("", Validators.compose([Validators.required])),
      newpass: new FormControl("", Validators.compose([Validators.required])),
      confpass: new FormControl("", Validators.compose([Validators.required])),
    });
    this.requestformdata = this.formBuilder.group({
      userId: new FormControl("", Validators.compose([Validators.required])),
      userName: new FormControl("", Validators.compose([Validators.required])),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      firstName: new FormControl("", Validators.compose([Validators.required])),
      emailId: new FormControl("", Validators.compose([Validators.required])),
      notes: new FormControl("", Validators.compose([Validators.required])),
      contactNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
    this.userStateService.showHideLoginMode().subscribe((val) => {
      this.enableLogin = val;
      this.loginForm();
    });
  }

  @Input("enable")
  enableLogin: boolean;

  ngOnInit() {
    var that = this;
    if (this.enableLogin) {
      this.loginForm();
    }
    $(document).keyup(function (e) {
      if (e.key === "Escape") {
        if (document.getElementById("loginForm").style.display === "block") {
          that.goToLoginForm();
          that.loginForm();
          document.getElementById("userIcon").focus();
        }
        // escape key maps to keycode `27`
        // <DO YOUR WORK HERE>
      }
    });
  }

  login() {
    // console.log("Logged in");
    var $this = this;
    this.spinner.show();
    // debugger;
    if (this.loginformdata.value.username.trim() == "") {
      this.spinner.hide();
      this.toastr.error("Username cannot be empty");
    } else if (this.loginformdata.value.userpassword.trim() == "") {
      this.spinner.hide();
      this.toastr.error("Password cannot be empty");
    } else {
      this.userStateService
        .login(
          this.loginformdata.value.username,
          this.loginformdata.value.userpassword
        )
        .subscribe(
          (data) => {
            this.toastr.success("Login Successfull!");
            this.loginForm();
            // console.log(data);
            this.spinner.hide();
          },
          (error) => {
            debugger;
            console.log(error, "error is");
            this.spinner.hide();
            if (error == "UpdatePassword") {
              this.toastr.error("Update Credentials", "", {
                closeButton: true,
                disableTimeOut: true,
              });
            } else if (error == "LockedUser") {
              this.toastr.error(
                "Your account is locked due to incorrect login attempts. Please contact your Sharp representative",
                "",
                {
                  closeButton: true,
                  disableTimeOut: true,
                }
              );
            } else {
              this.toastr.error("Invalid Credentials");
            }

            // alert(error['error']);
          }
        );
    }

    // this.adlaservice.login();
    // if (this.username) {
    //     config.extraQueryParameter = "scope=openid+profile&login_hint=" + encodeURIComponent(this.username);
    // } else {
    //     config.extraQueryParameter = "scope=openid+profile";
    // }

    // console.log(config);

    // var authContext = new AuthenticationContext(config); // from the ADAL.js library
    // // See if there's a cached user and it matches the expected user
    // let user = authContext.getCachedUser();
    // console.log(user);
    // // if (user) {
    // //     if (user.profile.oid !== userObjectId) {
    // //         // User doesn't match, clear the cache
    // //         authContext.clearCache();
    // //     }
    // // }

    // // In this example we are getting an id token (which ADAL.js returns if we ask for resource = clientId)
    // // $this.adlaservice.login();
    // authContext.acquireToken(config.clientId, function (message, tokenType) {
    // console.log(tokenType+"token");
    //     if (message) {
    // console.log(message);
    // console.log(tokenType);
    //       if(message == "User login is required"){
    //           $this.adlaservice.login();
    //       }else{
    // console.log(tokenType);
    //       }
    //         // Make sure ADAL gave us an id token
    //         // if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
    //         //     token = authContext.getCachedToken(config.clientId);
    //         // }
    //         // showProfileInformation(idToken);
    //     }
    // });
    // let body = {
    //   "username":this.username,
    //   "isOtherIdpSupported":true,
    //   "checkPhones":false,
    //   "isRemoteNGCSupported":true,
    //   "isCookieBannerShown":false,
    //   "isFidoSupported":true,
    //   "isAccessPassSupported":true
    // }
    // this.equip.postMicrosoftdata(body).subscribe(data => {
    // console.log(data);
    // }, err => {
    // console.log(err);
    // });
    // this.adlaservice.login();
    // console.log(this.adlaservice.handleCallback());
  }

  resetPass() {
    console.log(
      this.resetformdata.value.usernamepass,
      this.resetformdata.value.oldpass,
      this.resetformdata.value.newpass,
      this.resetformdata.value.confpass
    );
    if (
      this.resetformdata.value.usernamepass &&
      this.resetformdata.value.oldpass &&
      this.resetformdata.value.newpass &&
      this.resetformdata.value.confpass
    ) {
      if (
        this.resetformdata.value.newpass === this.resetformdata.value.confpass
      ) {
        this.userStateService
          .resetPassword(
            this.resetformdata.value.usernamepass,
            this.resetformdata.value.oldpass
          )
          .subscribe(
            (data) => {
              console.log(data, "Success reset");
              this.toastr.success("Updated");
            },
            (error) => {
              console.log(error, "error is");
            }
          );
        // this.toastr.success("Updated");
      } else {
        this.toastr.error("Password don't match");
      }
    } else {
      this.toastr.error("Form incomplete");
    }
    // alert("Reset Form");
  }

  public loginForm() {
    if (!this.body) this.body = document.querySelector("body");
    if (!this.loginform) this.loginform = document.querySelector("#loginForm");
    if (this.loginform) {
      this.loginform.classList.toggle("open");
      this.body.classList.toggle(
        "login-form-open",
        this.loginform.classList.contains("open")
      );

      this.parentEle = document.querySelector(".app-dialog-container");
      this.headerlinks = document.querySelector(".header--links");

      this.parentEle.classList.toggle("z-i-1");
      this.headerlinks.classList.toggle("hide");

      document.getElementById("loginForm").style.display = "none";
      document.getElementById("userIcon").focus();
    }
  }

  forgotPassword() {
    this.forgotpswd = document.querySelector(".input-forgot-password");
    this.formsiblings = document.querySelector(".login-fields");
    this.formsiblings.classList.add("hide");
    this.forgotpswd.classList.toggle("hide");
  }

  signUp() {
    this.signupformelement = document.querySelector(".input-signup");
    this.formsiblings = document.querySelector(".login-fields");
    this.formsiblings.classList.add("hide");
    this.signupformelement.classList.toggle("hide");
  }

  openSupportForm() {
    this.showSupport = 1;
  }

  goToLoginForm() {
    this.showSupport = 0;
  }

  openResetForm() {
    this.showSupport = 2;
  }

  submitRequest() {
    this.spinner.show();
    let un = "SVCAPPHLP";
    let password = "Shelp@1";
    let unp = `${un}:${password}`;
    let encryptedHeaderValue = `Basic ${btoa(unp)}`;
    let body = {
      IvEmail: this.requestformdata.value.emailId,
      IvUname: this.requestformdata.value.userName,
      IvFname: this.requestformdata.value.firstName,
      IvLname: this.requestformdata.value.lastName,
      IvNotes: this.requestformdata.value.notes,
      IvTell: this.requestformdata.value.contactNumber,
      IvAccount: this.requestformdata.value.userId,
    };
    var token = "";
    var cookie = "";
    var $this = this;
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/emailtoken`,
      type: "GET",
      async: true,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", encryptedHeaderValue);
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
    });
    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      document.cookie = cookie;
      $.ajax({
        url: `/sendemailset`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: body, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", encryptedHeaderValue);
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          $this.spinner.hide();
          // console.log(odata);
          if (odata.d.EvType == "S") {
            // console.log(odata.d.ev_message);
            $this.requestformdata.reset();
            $this.toastr.success("Email sent successfully", "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
          if (odata.d.EvType == "E") {
            // console.log(odata.d.ev_message);
            $this.toastr.error(odata.d.ev_message, "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
        },
        error: function (odata) {
          // console.log(odata);
          $this.spinner.hide();
          $this.toastr.error("Data not updated", "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }
}
