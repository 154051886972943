import { DialogComponent } from "./dialog/dialog.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home/home.component";
import { MainComponent } from "./main/main.component";
import { EquipmentInfoComponent } from "./equipment/equipment-info/equipment-info.component";
import { EquipmentService } from "../app/services/equipement-service.service";
import { HttpClientModule } from "@angular/common/http";
import { EquipmentsAllComponent } from "./equipments-all/equipments-all.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { OrderConsumablesService } from "./services/order-consumables.service";
import { InvoiceItemListComponent } from "./invoice/invoice-item-list/invoice-item-list.component";
import { InvoiceViewComponent } from "./invoice/invoice-view/invoice-view.component";
import { OrderComponent } from "./order/order.component";
import { MyEquipmentsComponent } from "./order/my-equipments/my-equipments.component";
import { MyEquipmentsServiceComponent } from "./equipments-all/my-equipments/my-equipments-service.component";
import { OrderFormComponent } from "./order/order-form/order-form.component";
import { Home2Component } from "./home2/home2.component";
import { HomeCardsComponent } from "./home2/home-cards/home-cards.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SearchEquipComponent } from "./header/search-equip/search-equip.component";
import { DealerSearchComponent } from "./header/dealer-search/dealer-search.component";
import { MeterReadsComponent } from "./meter-reads/meter-reads.component";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";
import { MyEquipmentsMetersComponent } from "./my-equipments/my-equipments.component";
import { LoginFormComponent } from "./home2/login-form/login-form.component";
import { EquipmentDetailsFormComponent } from "./home2/equipment-details-form/equipment-details-form.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from "@angular/common/http";
//import {TokenInterceptor} from './main/token.interceptor';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgScrollbarModule } from "ngx-scrollbar";
import { DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";
import { MsAdalAngular6Module } from "microsoft-adal-angular6";
import { AuthenticationGuard } from "microsoft-adal-angular6";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { ReportsComponent } from "./reports/reports.component";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ChangeContactComponent } from "./change-contact/change-contact.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import { ReportsViewComponent } from "./reports-view/reports-view.component";
import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";
import * as more from "highcharts/highcharts-more.src";
import * as exporting from "highcharts/modules/exporting.src";
import * as highcharts3D from "highcharts/highcharts-3d.src";
import * as cylinder from "highcharts/modules/cylinder.src";
import * as highmaps from "highcharts/modules/map.src";
import * as drilldown from "highcharts/modules/drilldown.src.js";
import { ReportsV2Component } from "./reports-v2/reports-v2.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ReportsDetailComponent } from "./reports-detail/reports-detail.component";
import { MatTooltipModule } from "@angular/material/tooltip";

// import { MatTableModule } from "@angular/material/table";
import {
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
} from "@angular/material";
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    EquipmentInfoComponent,
    EquipmentsAllComponent,
    InvoiceComponent,
    HeaderComponent,
    FooterComponent,
    Home2Component,
    HomeCardsComponent,
    InvoiceItemListComponent,
    InvoiceViewComponent,
    OrderComponent,
    MyEquipmentsComponent,
    MyEquipmentsServiceComponent,
    OrderFormComponent,
    SidebarComponent,
    SearchEquipComponent,
    DealerSearchComponent,
    MeterReadsComponent,
    NotificationDialogComponent,
    MyEquipmentsMetersComponent,
    LoginFormComponent,
    EquipmentDetailsFormComponent,
    ReportsComponent,
    DialogComponent,
    ChangeContactComponent,
    AuditLogComponent,
    ReportsViewComponent,
    ReportsV2Component,
    ReportsDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    //CalendarModule,
    DateRangePickerModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    NgxSpinnerModule,
    ChartModule,
    NgxSliderModule,
    SlickCarouselModule,
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-center",
    }),
    // HttpClientXsrfModule.withOptions({
    // cookieName: 'XSRF-TOKEN',
    // headerName: 'X-CSRF-TOKEN'
    //}),

    MsAdalAngular6Module.forRoot({
      tenant: "5c73dea3-97da-479a-be31-ab29d63d0a0c",
      clientId: "79a14f5c-969a-408a-aa3b-f03061449b66",
      redirectUri: window.location.origin,
      endpoints: {
        "https://localhost/Api/": "xxx-bae6-4760-b434-xxx",
      },
      navigateToLoginRequestUrl: false,
      cacheLocation: "localStorage", //<------ ADD
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
  ],
  providers: [
    EquipmentService,
    OrderConsumablesService,
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    //{
    //provide: HTTP_INTERCEPTORS,
    // useClass: TokenInterceptor,
    //multi: true
    //}
    AuthenticationGuard,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        more,
        exporting,
        highcharts3D,
        cylinder,
        highmaps,
        drilldown,
      ],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    library.add(fas, far);
  }
}
