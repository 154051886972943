import { Component, OnInit } from "@angular/core";
//import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.pug",
  styleUrls: ["./sidebar.component.scss"],
  //providers:[{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class SidebarComponent implements OnInit {
  body: HTMLElement;
  sidebar: HTMLElement;

  constructor() {}

  ngOnInit() {
    if (!this.body) this.body = document.querySelector("body");
    this.body.classList.remove("sidebar-open");
  }

  public toggleSidebar() {
    if (!this.body) this.body = document.querySelector("body");
    if (!this.sidebar) this.sidebar = document.querySelector("#sidebar");

    if (this.sidebar) {
      this.sidebar.classList.toggle("open");
      this.body.classList.toggle(
        "sidebar-open",
        this.sidebar.classList.contains("open")
      );
    }
  }

  goToHome() {
    localStorage.setItem("route", "true");
    this.toggleSidebar();
  }
  saveCurrRoute() {
    localStorage.setItem("route", "true");
  }
}
