import { EquipmentService } from "./../services/equipement-service.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-audit-log",
  templateUrl: "./audit-log.component.html",
  styleUrls: ["./audit-log.component.scss"],
})
export class AuditLogComponent implements OnInit {
  auditsArray = [];
  nodatatext = "";
  constructor(private equipmentService: EquipmentService) {}

  ngOnInit() {
    this.getChangeContactList();
  }

  getChangeContactList() {
    this.equipmentService.getChangeContactinformation().subscribe(
      (data) => {
        // console.log(data);
        this.auditsArray = data.d.results;
        if (this.auditsArray.length == 0) {
          this.nodatatext = "No records available";
        } else {
          this.nodatatext = "";
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }
}
