import { Component, OnInit, ViewChild } from "@angular/core";
import * as XLSX from "xlsx";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import { ToastrService } from "ngx-toastr";
// import TileMaster from "../../assets/json/Tile_Master.json";
import { UserStateService } from "../services/userstate.service";
import { HttpClient } from "@angular/common/http";
import {
  Options,
  LabelType,
  CustomStepDefinition,
} from "@angular-slider/ngx-slider";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
// import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
// import { Sort } from "@angular/material";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
@Component({
  selector: "app-reports-detail",
  templateUrl: "./reports-detail.component.html",
  styleUrls: ["./reports-detail.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*", display: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ReportsDetailComponent implements OnInit {
  monthSelection = [];
  months: string = this.createDateRange();
  range: any;
  customerNum: string;
  // value: number = this.letterToIndex("JAN");
  minValue: number = this.letterToIndex(this.getStartMonth());
  maxValue: number = this.letterToIndex(this.getEndMonth());
  options: Options = {
    stepsArray: this.months.split(",").map(
      (letter: string): CustomStepDefinition => {
        return { value: this.letterToIndex(letter), legend: "months" };
      }
    ),
    translate: (value: number, label: LabelType): string => {
      return this.indexToLetter(value);
    },
  };
  createDateRange(): string {
    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var currDate = new Date();
    var dateYear = [];
    var previousYear = currDate.getFullYear() - 1;
    var currMonth = currDate.getMonth();
    for (var i = currMonth + 1; i <= 11; i++) {
      dateYear.push(months[i] + " " + previousYear);
    }
    months.forEach((val, index) => {
      if (index <= currMonth) {
        dateYear.push(val + " " + currDate.getFullYear());
      }
    });
    return dateYear.join(",");
  }
  getStartMonth(): string {
    var months = this.months.split(",");
    return months[9];
  }

  getEndMonth(): string {
    var months = this.months.split(",");
    var currYear = new Date().getFullYear();
    var start = "";
    switch (months.length) {
      case 13:
        start = "NOV " + (currYear - 1);
        break;
      case 14:
        start = "DEC " + (currYear - 1);
        break;
      case 15:
        start = "JAN " + currYear;
        break;
      case 16:
        start = "FEB " + currYear;
        break;
      default:
        start = "MAR " + currYear;
    }
    return months[11];
  }

  indexToLetter(index: number): string {
    return this.months.split(",")[index];
  }

  letterToIndex(letter: string): number {
    return this.months.split(",").indexOf(letter);
  }
  timeline = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  reportData: any;
  reportName: string;
  reportTitle: string;
  departmentName: string;
  dataSource;
  // sortedData: PeriodicElement[];
  columnsToDisplay = [];
  labelsToDisplay = [];
  detailToDisplay = [];
  expandedElement: null;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private userStateService: UserStateService
  ) {}

  getInitials(month) {
    var text = month.substring(0, 3);
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  sliderChange(event) {
    var months = [];
    var start = event.value;
    var end = event.highValue;
    while (start <= end) {
      months.push(this.months.split(",")[start]);
      start++;
    }
    this.monthSelection = months;
  }
  onDownload() {
    // this.reportData.split(",").forEach((val) => {
    //   var url = "assets/data/" + val + ".xlsx";

    //   var oReq = new XMLHttpRequest();
    //   oReq.open("GET", url, true);
    //   oReq.responseType = "arraybuffer";
    //   oReq.onload = function (e) {
    //     if (this.status !== 404) {
    //       // not found, add some error handling
    //       window.open(url);
    //     }
    //   };
    //   oReq.send();
    // });
    this.customerNum = localStorage.getItem("soldto");
    // this.customerNum = "849487";
    var aReports = this.reportData;
    var sFilename = this.getInitials(aReports[0]);
    if (aReports.length > 1) {
      sFilename += "-" + this.getInitials(aReports[aReports.length - 1]);
    }
    const fileName =
      sFilename + "_" + this.reportName + "_" + this.customerNum + ".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      sFilename + "_" + this.reportName + "_" + this.customerNum
    );

    XLSX.writeFile(wb, fileName);
  }

  onShowSlider() {
    $(".month-slider").toggleClass("display");
  }
  onCloseSlider() {
    $(".month-slider").removeClass("display");
  }

  onSubmit() {
    $(".month-slider").removeClass("display");
    if (this.monthSelection.length > 1) {
      if (this.columnsToDisplay.indexOf("Month") == -1) {
        this.columnsToDisplay.push("Month");
      }
    } else {
      const index = this.columnsToDisplay.indexOf("Month");
      if (index > -1) {
        this.columnsToDisplay.splice(index, 1);
      }
    }
    this.getData(this.monthSelection);
  }

  getData(range) {
    var that = this;
    this.customerNum = localStorage.getItem("soldto");
    // this.customerNum = "849487";
    var aReports = [];
    range.forEach(function (val) {
      aReports.push(
        val.replace(" ", "_") + "_" + that.reportName + "_" + that.customerNum
      );
    });
    this.reportData = aReports;
    var content = [];
    var aPromise = [];
    // var aReports = this.reportData.split(",");
    this.timeline = this.getInitials(aReports[0]);
    var soldTo = that.customerNum;
    if (aReports.length > 1) {
      this.timeline += "-" + this.getInitials(aReports[aReports.length - 1]);
    }
    aReports.forEach((val) => {
      // var url = "assets/data/" + val + ".xlsx";
      // aPromise.push(this.getExcelData(url, val));
      aPromise.push(this.getExcelData(val, soldTo));
    });

    // this.timeline = "Jan - Feb";
    Promise.all(aPromise)
      .then((response) => {
        response.forEach((val) => {
          if (Array.isArray(val)) {
            content = content.concat(val);
          }
        });
        that.dataSource = new MatTableDataSource(content);
        that.departmentName = content[0].Department;
        that.dataSource.paginator = that.paginator;
        that.dataSource.sort = that.sort;
        if (content.length == 0) {
          this.toastr.error("No Data for this timeline", "", {
            timeOut: 1000,
          });
        }
      })
      .catch((error) => {});
  }

  ngOnInit() {
    this.customerNum = localStorage.getItem("soldto");
    // this.customerNum = "849487";
    this.range = this.activateRoute.snapshot.queryParamMap.get("range");
    this.reportName = this.activateRoute.snapshot.queryParamMap.get("report");
    this.httpClient.get("assets/json/Reports_header.json").subscribe((data) => {
      this.reportTitle = data[this.reportName].title;
      this.columnsToDisplay = data[this.reportName].header;
      this.labelsToDisplay = data[this.reportName].label;
      this.detailToDisplay = data[this.reportName].detail;
      if (this.range.split(",").length > 1) {
        this.columnsToDisplay.push("Month");
      } else {
        const index = this.columnsToDisplay.indexOf("Month");
        if (index > -1) {
          this.columnsToDisplay.splice(index, 1);
        }
      }
    });

    this.dataSource = new MatTableDataSource([]);
    var range = this.range.split(",");
    this.minValue = this.letterToIndex(range[0]);
    this.maxValue = this.letterToIndex(range[range.length - 1]);
    this.getData(range);
  }
  onBack() {
    this.router.navigate(["/reports-v2"]);
  }
  getExcelData(url, soldTo) {
    return new Promise((resolve, reject) => {
      var content = [];
      var mon = "";
      var $this = this;
      var thisuser = localStorage.getItem("username").toUpperCase();
      $.ajax({
        url:
          `/getExcelData?$filter= IvUser eq '${thisuser}'&$format=json&fileName=` +
          url +
          `&soldTo=` +
          soldTo,
        type: "GET",
        async: true,
        cache: true,
        beforeSend: function (xhr) {
          // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader(
            "Content-Type",
            "application/json; charset=utf-8"
          );
        },
        success: function (data, textStatus, XMLHttpRequest) {
          // console.log(data);
          resolve(data);
          mon = url.split("_")[0];
          data.forEach(function (value, index) {
            value["Month"] = mon;
            content[index] = value;
          });
        },
        error: function (error) {
          resolve("Error fetching " + url);
        },
        //crossDomain: true
      });
    });
  }
}
//   getExcelData(url, month) {
//     return new Promise((resolve, reject) => {
//       var content = [];
//       var mon = "";
//       var oReq = new XMLHttpRequest();
//       oReq.open("GET", url, true);
//       oReq.responseType = "arraybuffer";

//       oReq.onload = function (e) {
//         if (this.status === 404) {
//           // not found, add some error handling
//           resolve("Error fetching " + url);
//         } else {
//           var arraybuffer = oReq.response;

//           /* convert data to binary string */
//           var data = new Uint8Array(arraybuffer);
//           var arr = new Array();
//           for (var i = 0; i != data.length; ++i)
//             arr[i] = String.fromCharCode(data[i]);
//           var bstr = arr.join("");

//           /* Call XLSX */
//           try {
//             var workbook = XLSX.read(bstr, { type: "binary" });

//             /* DO SOMETHING WITH workbook HERE */
//             var first_sheet_name = workbook.SheetNames[0];
//             /* Get worksheet */
//             var worksheet = workbook.Sheets["Sheet1"];
//             var dataExcel = XLSX.utils.sheet_to_json(worksheet, {
//               raw: true,
//               defval: "",
//             });
//             // console.log(JSON.stringify(Object.keys(dataExcel[0])));
//             resolve(dataExcel);
//             mon = month.split("_")[0];
//             dataExcel.forEach(function (value, index) {
//               value["Month"] = mon;
//               content[index] = value;
//             });
//             // content = dataExcel;
//           } catch (e) {
//             resolve("Error fetching " + url);
//           }
//         }
//       };
//       oReq.onerror = function (e) {
//         reject("Error fetching " + e);
//       };
//       oReq.send();
//     });
//   }
// }
