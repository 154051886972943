import {
  Component,
  OnInit,
  AfterContentChecked,
  AfterViewChecked,
} from "@angular/core";
import { ElementRef, Pipe } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { EquipmentService } from "../services/equipement-service.service";
import * as $ from "jquery";
import {
  HttpClient,
  HttpClientXsrfModule,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";
import { switchMap } from "rxjs/operators";
import { isBoolean } from "util";
import { UserStateService } from "../services/userstate.service";
// declare var window:any;

@Component({
  selector: "app-home2",
  templateUrl: "./home2.component.pug",
  styleUrls: ["./home2.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
    // '(window:load)': 'onLoad($event)'
  },
})
export class Home2Component implements OnInit {
  userRef = [];
  enableLogin: boolean = false;
  showTilesData: boolean;
  islogin: boolean = false;
  img: any;
  coords: any;
  areas: any;
  constructor(
    private euipService: EquipmentService,
    private http: HttpClient,
    private ar: ActivatedRoute,
    private userStateService: UserStateService
  ) {
    this.euipService.userlogin.subscribe((data) => {
      this.islogin = data;
    });
    // this.userStateService.showTiles.subscribe((data) => {
    //   this.showTilesData = data;
    // });
  }

  ngOnInit() {
    var $this = this;
    this.ar.paramMap.subscribe((params: ParamMap) => {
      this.enableLogin = params.get("enableLogin") == "true" ? true : false;
    });
    $(".buttons").on("click", function (e) {
      e.preventDefault();
      var text = e.currentTarget.title;
      $("#display")[0].src = "../../assets/images/display " + text + ".png";
    });
    // console.log("This is init load");
    $("#img_ID").on("load", function () {
      $this.imageMap(
        document.getElementById("map_ID"),
        document.getElementById("img_ID")
      );
    });
    // this.showCords();
    this.userStateService.getUserRef.subscribe((data) => {
      // console.log(data);
      if (data) {
        // debugger;
        // console.log("hello true", data);
        this.getUserRef();
      } else {
        localStorage.removeItem("soldto");
        localStorage.removeItem("username");
        localStorage.removeItem("equip");
        localStorage.removeItem("openord");
        localStorage.removeItem("openreq");
        localStorage.removeItem("route");
      }
    });
    // this.getUserRef();
    //console.log(JSON.stringify(this.userRef));
  }

  onResize(event) {
    if (this.img && this.coords && this.areas) {
      this.resize(this.img, this.coords, this.areas);
    }
  }

  // ngAfterViewChecked(): void {
  //   debugger;
  //   console.log("this is checked ");
  //   this.onResize("event");
  // }

  // onLoad(event) {
  //   this.imageMap(document.getElementById('map_ID'), document.getElementById('img_ID'));
  // }

  // ngAfterContentChecked() {
  //   this.imageMap(document.getElementById('map_ID'), document.getElementById('img_ID'));
  // }

  imageMap(map, img) {
    var n;
    this.img = img;
    this.areas = map.getElementsByTagName("area");
    var len = this.areas.length;
    this.coords = [];
    for (n = 0; n < len; n++) {
      this.coords[n] = this.areas[n].coords.split(",");
    }
    this.resize(this.img, this.coords, this.areas);
    // window.onresize = this.resize(img,coords,areas);
  }

  resize(img, coords, areas): any {
    // debugger;
    var a1, a2, b1, b2;
    var x = img.offsetWidth;
    var y = img.offsetHeight;
    a1 = this.reCoord(
      x,
      y,
      coords[0][0],
      coords[0][1],
      coords[0][2],
      coords[0][3]
    );
    b1 = this.reCoord(
      x,
      y,
      coords[1][0],
      coords[1][1],
      coords[1][2],
      coords[1][3]
    );
    a2 = this.reCoord(
      x,
      y,
      coords[2][0],
      coords[2][1],
      coords[2][2],
      coords[2][3]
    );
    b2 = this.reCoord(
      x,
      y,
      coords[3][0],
      coords[3][1],
      coords[3][2],
      coords[3][3]
    );
    areas[0].coords = a1;
    areas[1].coords = b1;
    areas[2].coords = a2;
    areas[3].coords = b2;
  }

  reCoord(x, y, val1, val2, val3, val4) {
    var a = 326;
    var b = 179;
    return (
      Math.round((val1 / a) * x) +
      "," +
      Math.round((val2 / b) * y) +
      "," +
      Math.round((val3 / a) * x) +
      "," +
      Math.round((val4 / b) * y)
    );
  }

  getUserRef() {
    // debugger;
    this.euipService.getUserRefData().subscribe(
      (userData) => {
        this.showTilesData = true;
        //console.log(equiData.d)
        // debugger;
        // console.log(JSON.stringify(userData));
        // for (var i = 0; i < userData.d.results.length; i++) {
        //   this.userRef.push(userData.d.results[i].EtRefferances.SoldTo);
        // }
        // console.log(JSON.stringify(this.userRef));
      },
      (error) => {
        // console.log(error);
        this.showTilesData = false;
      }
    );
  }
}
