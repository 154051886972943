import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-details-form',
  templateUrl: './equipment-details-form.component.html',
  styleUrls: ['./equipment-details-form.component.scss']
})
export class EquipmentDetailsFormComponent implements OnInit {

  body: HTMLElement;
  equipdetailsform: HTMLElement;
  parentEle: HTMLElement;
  headerlinks: HTMLElement;

  constructor() { }

  ngOnInit() {
  }

  public equipDetailsForm() {//alert("hello");
    if (!this.body) this.body = document.querySelector('body');
    if (!this.equipdetailsform) this.equipdetailsform = document.querySelector('#equipDetailsForm');
    if (this.equipdetailsform) {
      this.equipdetailsform.classList.toggle('open');
      this.body.classList.toggle('equip-details-form-open', this.equipdetailsform.classList.contains('open'));

      this.parentEle = document.querySelector('.app-dialog-container');
      this.headerlinks = document.querySelector('.header--links');
  
      this.parentEle.classList.toggle('z-i-1');
      this.headerlinks.classList.toggle('hide');

    }
  }

}
