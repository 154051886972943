import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { Invoice } from "../invoice/invoice";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  private dataSource = new BehaviorSubject(undefined);
  public searchData = new BehaviorSubject(undefined);
  private subject = new Subject<any>();
  selectedInvoice = this.dataSource.asObservable();

  constructor() {}

  setSelectedInvoice(invoice: any) {
    this.dataSource.next(invoice);
  }

  sendMessage(dataVal: any) {
    this.subject.next(dataVal);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
