import { Router } from "@angular/router";
import { UserStateService } from "src/app/services/userstate.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  HostListener,
} from "@angular/core";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as $ from "jquery";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
declare var window: any;
declare var JogDial: any;
declare var require: any;
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent
  implements OnInit, AfterViewInit, AfterViewChecked {
  months = [
    { name: "JAN", code: "01" },
    { name: "FEB", code: "02" },
    { name: "MAR", code: "03" },
    { name: "APR", code: "04" },
    { name: "MAY", code: "05" },
    { name: "JUN", code: "06" },
    { name: "JUL", code: "07" },
    { name: "AUG", code: "08" },
    { name: "SEP", code: "09" },
    { name: "OCT", code: "10" },
    { name: "NOV", code: "11" },
    { name: "DEC", code: "12" },
  ];
  years: any = [];
  lastMonths: any = [];
  reportslist: any = [];
  duplicatearray: any = [];
  month: string;
  year: string;
  onDialerFocus: boolean = true;
  body: HTMLElement;
  sidebar: HTMLElement;
  constructor(
    public userService: UserStateService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    var $this = this;
    var date = new Date();
    // console.log(date);
    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var state = 0;
    var dialOne;
    var a = date.getFullYear();
    var b = date.getMonth();
    var c = date.getFullYear() + 10;
    var d = date.getMonth();

    function moveFocus(direction) {
      if (direction === "next") {
        if (state < 5) {
          state++;
          if (state === 1) {
            $("#month1").toggleClass("active", false);
            $("#year1").toggleClass("active", true);
          } else if (state === 2) {
            $("#year1").toggleClass("active", false);
            $("#month2").toggleClass("active", true);
          } else if (state === 3) {
            $("#month2").toggleClass("active", false);
            $("#year2").toggleClass("active", true);
          } else if (state === 4) {
            $("#year2").toggleClass("active", false);
          }
        }
      } else if (direction === "prev") {
        if (state > 0) {
          state--;
          if (state === 0) {
            $("#year1").toggleClass("active", false);
            $("#month1").toggleClass("active", true);
          } else if (state === 1) {
            $("#year1").toggleClass("active", true);
            $("#month2").toggleClass("active", false);
          } else if (state === 2) {
            $("#month2").toggleClass("active", true);
            $("#year2").toggleClass("active", false);
          } else if (state === -1) {
            $("#month1").toggleClass("active", false);
          }
          // else if (state === 3) {
          //   $("#year2").toggleClass("active", true);
          //   $("#month2").toggleClass("active", false);
          // }
          // else if (state === 4) {
          //   $("#month2").toggleClass("active", true);
          // }
        }
      }
    }
    function clearFocus() {
      $("#year1").toggleClass("active", false);
      $("#month1").toggleClass("active", false);
      $("#year2").toggleClass("active", false);
      $("#month2").toggleClass("active", false);
    }
    function setFocus(newState) {
      if (newState >= 0 && newState < 4) {
        state = newState;

        clearFocus();

        if (state === 0) {
          $("#month1").toggleClass("active", true);
        } else if (state === 1) {
          $("#year1").toggleClass("active", true);
        } else if (state === 2) {
          $("#month2").toggleClass("active", true);
        } else if (state === 3) {
          $("#year2").toggleClass("active", true);
        }
      }
    }

    function setValue(change) {
      if (state === 1) {
        var y = a;
        date.setFullYear(y + change);
        $("#year1").html(date.getFullYear());
        if ($("#year1").html() > $("#year2").html()) {
          $("#year2").html(date.getFullYear());
        }
      }
      if (state === 0) {
        var m = b + change;
        while (m < 0) m += 12;
        date.setMonth(m % 12);
        $("#month1").html(months[date.getMonth()]);
        if (
          $("#year1").html() === $("#year2").html() &&
          months.indexOf($("#month1").html()) >
            months.indexOf($("#month2").html())
        ) {
          $("#month2").html(months[date.getMonth()]);
        }
      }
      if (state === 3) {
        var y = c;
        date.setFullYear(y + change);
        $("#year2").html(date.getFullYear());
        if ($("#year1").html() >= date.getFullYear()) {
          $("#year1").html(date.getFullYear());
        }
      }
      if (state === 2) {
        var m = d + change;
        while (m < 0) m += 12;
        date.setMonth(m % 12);
        $("#month2").html(months[date.getMonth()]);
        if (
          $("#year1").html() === $("#year2").html() &&
          months.indexOf($("#month1").html()) >=
            months.indexOf($("#month2").html())
        ) {
          $("#month1").html(months[date.getMonth()]);
        }
      }
    }

    function getDaysInMonth(y, m) {
      return /3|5|8|10/.test(m)
        ? 30
        : m === 1
        ? (!(y % 4) && y % 100) || !(y % 400)
          ? 29
          : 28
        : 31;
    }

    $(document).ready(function () {
      $("#year1").html(date.getFullYear());
      $("#month1").html(months[date.getMonth()]);
      $("#year2").html(date.getFullYear());
      $("#month2").html(months[date.getMonth()]);

      $("#month1").focus();

      $("#year1").click(function () {
        setFocus(1);
      });
      $("#month1").click(function () {
        setFocus(0);
      });
      $("#year2").click(function () {
        setFocus(3);
      });
      $("#month2").click(function () {
        setFocus(2);
      });
      dialOne = JogDial(document.getElementById("jog_dial_example"), {
        wheelSize: "100px",
        knobSize: "30px",
        minDegree: null,
        maxDegree: null,
        degreeStartAt: 0,
      });
      dialOne.on("mousemove", function (evt) {
        document.getElementById("submit").style.zIndex = "0";
        var currDegree = Math.ceil(evt.target.rotation / 10);
        // console.log(currDegree);
        var angle = currDegree;
        setValue(angle);
        //$('#jog_dial_example_meter div').css('width', Math.round((evt.target.rotation/360)*100) + '%' )
      });
      dialOne.on("mouseup", function (evt) {
        dialOne.angle(0);
        dialOne.info.now = { rotation: 0, quadrant: 1 };
        dialOne.info.old = { rotation: 0, quadrant: 1 };
        a = parseInt($("#year1").html());
        b = months.indexOf($("#month1").html());
        c = parseInt($("#year2").html());
        d = months.indexOf($("#month2").html());
        moveFocus("next");
        document.getElementById("submit").style.zIndex = "10000";
      });
      setFocus(0);
    });

    $(document).keyup(function (evt) {
      evt.preventDefault();
      if (document.activeElement.id == "month1") {
        $("#month1").toggleClass("active", true);
        $("#year2").toggleClass("active", false);
        $("#year1").toggleClass("active", false);
        $("#month2").toggleClass("active", false);
        state = 0;
      } else if (document.activeElement.id == "year1") {
        $("#month1").toggleClass("active", false);
        $("#year2").toggleClass("active", false);
        $("#year1").toggleClass("active", true);
        $("#month2").toggleClass("active", false);
        state = 1;
      } else if (document.activeElement.id == "year2") {
        $("#month1").toggleClass("active", false);
        $("#year2").toggleClass("active", true);
        $("#year1").toggleClass("active", false);
        $("#month2").toggleClass("active", false);
        state = 3;
      } else if (document.activeElement.id == "month2") {
        $("#month1").toggleClass("active", false);
        $("#year2").toggleClass("active", false);
        $("#year1").toggleClass("active", false);
        $("#month2").toggleClass("active", true);
        state = 2;
      }
      // console.log(evt.key+"keyup");
      if (evt.key == "ArrowDown") {
        a = parseInt($("#year1").html());
        b = months.indexOf($("#month1").html());
        c = parseInt($("#year2").html());
        d = months.indexOf($("#month2").html());
        setValue(-1);
      } else if (evt.key == "ArrowUp") {
        a = parseInt($("#year1").html());
        b = months.indexOf($("#month1").html());
        c = parseInt($("#year2").html());
        d = months.indexOf($("#month2").html());
        setValue(1);
      } else if (evt.key == "Enter") {
        if (
          document.activeElement.id == "month1" ||
          document.activeElement.id == "year1" ||
          document.activeElement.id == "year2" ||
          document.activeElement.id == "month2" ||
          document.activeElement.id == "submit"
        ) {
          $("#submit").click();
        }
      }
      evt.stopImmediatePropagation();
    });

    // $(document).keydown(function (evt) {
    // console.log(evt.key);

    // });
  }

  // @HostListener('document:keyup', ['$event'])
  // KeyUpEvent(event: KeyboardEvent) {
  //   if (this.onDialerFocus && event.keyCode == 9){

  //   }

  // }

  ngOnInit() {
    // this.getReports();
  }

  ngAfterViewInit() {
    var $this = this;
    setTimeout(function () {
      $this.getReports();
    }, 3000);
  }

  ngAfterViewChecked(): void {
    $("#jog_dial_example_knob").css(
      "background",
      "url('../../assets/datepick/knob1.png')"
    );
    $("#jog_dial_example_knob").css("background-size", "contain");
    $("#jog_dial_example_knob").css("background-repeat", "none");
  }

  show() {
    var month1 = $("#month1").html();
    var month2 = $("#month2").html();
    var year1 = $("#year1").html();
    var year2 = $("#year2").html();

    var monthNew1 = _.filter(this.months, { name: month1 });
    var monthNew2 = _.filter(this.months, { name: month2 });
    this.getFilteredListData(
      monthNew1[0].code,
      monthNew2[0].code,
      year1,
      year2
    );
  }

  getFilteredListData(mon1, mon2, yea1, yea2) {
    var monthsArr = [];
    _.forEach(this.duplicatearray, function (value) {
      if (value.Month >= mon1 && value.Month <= mon2) {
        // console.log(value);
        monthsArr.push(value);
      }
    });
    this.getFinalData(monthsArr, yea1, yea2);
  }

  getFinalData(monarr, yea1, yea2) {
    var $this = this;
    this.reportslist = [];
    _.forEach(monarr, function (value) {
      if (value.Year >= yea1 && value.Year <= yea2) {
        $this.reportslist.push(value);
      }
    });
  }
  getReports() {
    this.userService.reports().subscribe(
      (data) => {
        // console.log(data);
        this.reportslist = data;
        this.duplicatearray = this.reportslist.slice();
        this.getYears(this.duplicatearray);
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  getYears(arr) {
    this.years = [];
    this.lastMonths = [];
    var index;
    for (index of arr) {
      if (this.years.indexOf(index["Year"]) == -1) {
        this.years.push(index["Year"]);
      }
    }
    var index1;
    for (index1 of arr) {
      if (this.lastMonths.indexOf(index1["Month"]) == -1) {
        this.lastMonths.push(index1["Month"]);
      }
    }
    // this.year = this.years[this.years.length - 1];
    // this.month = this.lastMonths[this.lastMonths.length - 1];
    var year = $("#year1").html();
    var month = $("#month1").html();
    // console.log(month + "" + typeof month);
    // console.log(this.months);
    var monthNew = _.filter(this.months, { name: month });
    // console.log(monthNew);
    this.getFilteredList(year, monthNew[0].code, arr);
  }

  getFilteredList(year, month, arr) {
    this.reportslist = _.filter(arr, { Year: year, Month: month });
    // console.log(this.reportslist);
  }

  fileDownload(name) {
    this.userService.downloadReport(name).subscribe(
      (data: any) => {
        // console.log(data);
        var blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // var objectUrl = URL.createObjectURL(blob);
        // window.open(objectUrl);
        saveAs(blob, name);
      },
      (err) => {
        // console.log(err);
      }
    );
  }
  changeMonth(event) {
    // console.log(event.target.value);
    this.month = event.target.value;
    this.getFilteredList(this.year, this.month, this.duplicatearray);
  }

  changeYear(event) {
    // console.log(event.target.value);
    this.year = event.target.value;
    this.getFilteredList(this.year, this.month, this.duplicatearray);
  }

  view(filename) {
    var file = filename.replace("xlsx", "json");
    this.httpClient
      .get("../../assets/json/" + unescape(file))
      .subscribe((data) => {
        var jsondata = data["Sheet1"];
        var payload = {
          queryParams: {
            reportdata: JSON.stringify(jsondata),
          },
        };
        this.router.navigate(["/report-view"], payload);
      });
  }
}
