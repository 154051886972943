import { UserStateService } from "./../../services/userstate.service";
import { Component, OnInit, Input } from "@angular/core";
import { Invoice } from "../invoice";
import { InvoiceService } from "../../services/invoice.service";
import * as $ from "jquery";
@Component({
  selector: "app-invoice-view",
  templateUrl: "./invoice-view.component.pug",
  styleUrls: ["./invoice-view.component.scss"],
})
export class InvoiceViewComponent implements OnInit {
  invoice: Invoice;

  constructor(
    private data: InvoiceService,
    public userStateService: UserStateService
  ) {}

  ngOnInit() {
    this.data.selectedInvoice.subscribe((invoice) => (this.invoice = invoice));
  }

  getPdf(invoice: any): void {
    var $this = this;
    // this.selectedInvoice = invoice;
    // this.data.setSelectedInvoice(invoice);
    // alert(JSON.stringify(invoice));
    // console.log(invoice);
    //alert(invoice.EtOpenItems.DocumentNo+"and"+invoice.EtOpenItems.FormFound);
    var docNo = invoice.EtOpenItems.DocumentNo;
    var docCat = invoice.EtOpenItems.DocCategory;
    var condType = invoice.EtOpenItems.ConditionType;
    //var ordInfoURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsDetailSet?$filter= IvOrder eq '"+ivorderinfo+"'&$format=json";
    // var getDocURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetDocPDFSet(IvDoc='"+docNo+"',IvDocCategory='"+docCat+"',IvConditionType='"+condType+"')/$value";
    // var getDocURL = `/getDocpdf?$filter= IvSoldTo eq '${soldtoUser}' and IvUser eq '${user}' and IvEquipment eq '${userRef}' &$format=json`;
    var getDocURL = `/getDocpdf?(IvDoc='${docNo}',IvDocCategory='${docCat}',IvConditionType='${condType}')/$value`;
    // console.log(getDocURL);
    $.ajax({
      context: this,
      cache: false,
      url: getDocURL,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (message, textStatus, response) {
        //console.log(XMLHttpRequest.getResponseHeader("content-disposition")+XMLHttpRequest.getResponseHeader("content-type"));
        var header = response.getResponseHeader("Content-Disposition");
        //  var fileName = header.split("=")[1];
        var blob = new Blob([message], { type: "application/pdf" });
        var link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.href = window.URL.createObjectURL(blob);
        link.download = docNo;
        link.click();
      },
    });
  }
}
