import { InvoiceService } from "./../services/invoice.service";
import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
@Component({
  selector: "app-order",
  templateUrl: "./order.component.pug",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent implements OnInit {
  public searchdata: FormGroup;
  showSearch = false;
  constructor(
    private formBuilder: FormBuilder,
    public invoiceService: InvoiceService
  ) {
    this.searchdata = this.formBuilder.group({
      searchelement: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  ngOnInit() {}

  searchData() {
    // console.log(this.searchdata.value.searchelement);
    var searchele = this.searchdata.value.searchelement;
    this.invoiceService.searchData.next(searchele);
  }

  openSearch() {
    this.showSearch = true;
  }
}
