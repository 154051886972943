import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Invoice } from "..//invoice";
import { INVOICES } from "../invoices-mock";
import { InvoiceService } from "../../services/invoice.service";
//import Swiper from 'swiper';
import Swiper from "../../../../node_modules/swiper/dist/js/swiper.js";
import { EquipmentService } from "../../services/equipement-service.service";
import { ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-invoice-item-list",
  templateUrl: "./invoice-item-list.component.pug",
  styleUrls: ["./invoice-item-list.component.scss"],
})
export class InvoiceItemListComponent implements OnInit, AfterViewInit {
  // invoices = INVOICES;
  selectedInvoice;
  inVoiceList: any[] = [];
  invoiceData = [];
  constructor(
    private route: ActivatedRoute,
    private data: InvoiceService,
    private equipmentService: EquipmentService,
    private userStateService: UserStateService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    /*this.route.queryParams.subscribe(params => {
            console.log(params, "paramsssss");
            const type = params['type'];
            this.getInVoiceData();
        });
        this.data.getMessage().subscribe(mgs => {
            if (mgs == 'excel') {
                const data = this.inVoiceList.map(item => item.EtOpenItems);
                if (data.length) {
                    this.equipmentService.exportAsExcelFile(data, 'Invoice');

                }
            }
            if (mgs == 'print') {
                const data = this.inVoiceList.map(item => item.EtOpenItems);

            }

        });*/
    setTimeout(() => {
      this.getInVoiceData();
    }, 2000);
    this.data.getMessage().subscribe((data) => {
      this.getInVoiceData();
    });
  }

  ngAfterViewInit() {
    setTimeout(function () {
      let invoiceListSwiper = new Swiper("#invoiceListContainer", {
        direction: "vertical",
        slidesPerView: "auto",
        slidesPerGroup: 4,
        navigation: {
          nextEl: ".invoice-item-down",
          prevEl: ".invoice-item-up",
        },
      });
    }, 7000);
    //this.getdata();
  }

  onSelect(invoice: any): void {
    var $this = this;
    this.selectedInvoice = invoice;
    this.data.setSelectedInvoice(invoice);
    // alert(JSON.stringify(invoice));
    // console.log(invoice);
    //alert(invoice.EtOpenItems.DocumentNo+"and"+invoice.EtOpenItems.FormFound);
    if (invoice.EtOpenItems.FormFound == "X") {
      var docNo = invoice.EtOpenItems.DocumentNo;
      var docCat = invoice.EtOpenItems.DocCategory;
      var condType = invoice.EtOpenItems.ConditionType;
      //var ordInfoURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsDetailSet?$filter= IvOrder eq '"+ivorderinfo+"'&$format=json";
      // var getDocURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetDocPDFSet(IvDoc='"+docNo+"',IvDocCategory='"+docCat+"',IvConditionType='"+condType+"')/$value";
      // var getDocURL = `/getDocpdf?$filter= IvSoldTo eq '${soldtoUser}' and IvUser eq '${user}' and IvEquipment eq '${userRef}' &$format=json`;
      var getDocURL = `/getDocpdf?(IvDoc='${docNo}',IvDocCategory='${docCat}',IvConditionType='${condType}')/$value`;
      // console.log(getDocURL);
      $.ajax({
        context: this,
        cache: false,
        url: getDocURL,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (message, textStatus, response) {
          //console.log(XMLHttpRequest.getResponseHeader("content-disposition")+XMLHttpRequest.getResponseHeader("content-type"));
          var header = response.getResponseHeader("Content-Disposition");
          //  var fileName = header.split("=")[1];
          var blob = new Blob([message], { type: "application/pdf" });
          var link = document.createElement("a");
          link.setAttribute("target", "_blank");
          link.href = window.URL.createObjectURL(blob);
          link.download = docNo;
          link.click();
        },
      });
    }
  }
  getInVoiceData(): void {
    //alert("hi")
    this.spinner.show();
    this.equipmentService.getInVoiceData().subscribe(
      (data) => {
        // console.log('invoicedata =>', data);
        this.spinner.hide();
        //if (type == 'C3' || type == 'C6') {
        //this.inVoiceList = data.d.results.filter(item => item.IvType == type);
        //} else {
        this.inVoiceList = data.d.results;
        //}
        // console.log(this.inVoiceList);
        this.onSelect(this.inVoiceList[0]);
        this.invoiceData = [];
        // alert(JSON.stringify(this.invoiceData));
        for (var i = 0; i < data.d.results.length; i++) {
          this.invoiceData.push(data.d.results[i].EtOpenItems);
          //alert(JSON.stringify(this.invoiceData));
        }
        // console.log("new1"+JSON.stringify(this.invoiceData));
        this.getdata();
      },
      (err) => {
        //   console.log(err);
        this.spinner.hide();
      }
    );
  }

  getdata() {
    //alert(this.invoiceData.length);
    var newrows = "";
    for (var i = 0; i < this.invoiceData.length; i++) {
      newrows +=
        "<tr><td>" +
        this.invoiceData[i].SoldTo +
        "</td>" +
        "<td>" +
        this.invoiceData[i].PostingKeyDesc +
        "</td>" +
        "<td>" +
        this.invoiceData[i].ReferanceNo +
        "</td>" +
        "<td>" +
        this.invoiceData[i].AssignmentNo +
        "</td>" +
        "<td>" +
        this.invoiceData[i].DocDate +
        "</td>" +
        "<td>" +
        this.invoiceData[i].DueDate +
        "</td>" +
        "<td>" +
        this.invoiceData[i].Terms +
        "</td>" +
        "<td>" +
        this.invoiceData[i].Amount +
        "</td></tr>";
    }

    //alert(newrows);
    $("#invoice-data").append(newrows);
  }
}
