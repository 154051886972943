import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";
import * as XLSX from "xlsx";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserStateService } from "../services/userstate.service";

import {
  Options,
  LabelType,
  CustomStepDefinition,
} from "@angular-slider/ngx-slider";
import { getViewData } from "@angular/core/src/render3/state";
import { resolve } from "url";
@Component({
  selector: "app-reports-v2",
  templateUrl: "./reports-v2.component.html",
  styleUrls: ["./reports-v2.component.scss"],
})
export class ReportsV2Component implements OnInit {
  monthSelection = [];
  reportTitle = "Report Title";
  customerNum: string;
  months: string = this.createDateRange();
  // value: number = this.letterToIndex("JAN");
  minValue: number = this.letterToIndex(this.getStartMonth());
  maxValue: number = this.letterToIndex(this.getEndMonth());
  options: Options = {
    stepsArray: this.months
      .split(",")
      .map((letter: string): CustomStepDefinition => {
        // debugger;
        return { value: this.letterToIndex(letter), legend: "sds" };
      }),
    translate: (value: number, label: LabelType): string => {
      return this.indexToLetter(value);
    },
  };
  createDateRange(): string {
    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var currDate = new Date();
    var dateYear = [];
    var previousYear = currDate.getFullYear() - 1;
    var currMonth = currDate.getMonth();
    for (var i = currMonth + 1; i <= 11; i++) {
      dateYear.push(months[i] + " " + previousYear);
    }
    // months.forEach((val, index) => {
    //   dateYear.push(val + " " + previousYear);
    // });
    months.forEach((val, index) => {
      if (index <= currMonth) {
        dateYear.push(val + " " + currDate.getFullYear());
      }
    });
    // debugger;
    return dateYear.join(",");
  }
  getStartMonth(): string {
    var months = this.months.split(",");
    var currTime = this.userStateService.getTimeline();
    if (currTime) {
      return currTime[0].replace("_", " ");
    } else {
      return months[9];
    }
  }

  getEndMonth(): string {
    var months = this.months.split(",");
    var currTime = this.userStateService.getTimeline();
    if (currTime) {
      return currTime[currTime.length - 1].replace("_", " ");
    } else {
      return months[11];
    }
  }

  indexToLetter(index: number): string {
    return this.months.split(",")[index];
  }

  letterToIndex(letter: string): number {
    return this.months.split(",").indexOf(letter);
  }
  //gradient
  gradient = [
    "yellow",
    "orange",
    "pink",
    "purple",
    "green",
    "yellow",
    "orange",
    "pink",
    "purple",
    "green",
    "yellow",
    "orange",
    "pink",
    "purple",
    "yellow",
    "green",
  ];
  // Slide
  tempSlide = [];
  slides = [];
  slideConfig = {
    autoplay: false,
    infinite: false,
    slidesToShow: 16,
    slidesToScroll: 1,
    cssEase: "cubic-bezier(0.250,  0.060, 0.050, 0.040)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slickInit(e) {
    var that = this;
    $(".slide").mouseover(function (event) {
      // debugger;
      that.reportTitle =
        event.currentTarget.getElementsByClassName(
          "reports-title"
        )[0].innerText;
      // event.currentTarget.getElementsByClassName(
      //   "report-top-header"
      // )[0].style.opacity = 1;
    });
    // $(".slide").mouseout(function (event) {
    //   // debugger;
    //   event.currentTarget.getElementsByClassName(
    //     "report-top-header"
    //   )[0].style.opacity = 0;
    // });
  }

  breakpoint(e) {}
  afterChange(e) {}

  beforeChange(e) {}
  sliderChange(event) {
    var months = [];
    var start = event.value;
    var end = event.highValue;
    while (start <= end) {
      // months.push(this.months.split(",")[start]);
      months.push(this.months.split(",")[start].replace(" ", "_"));

      start++;
    }
    this.monthSelection = months;
    this.userStateService.setTimeline(months);
  }
  onSelect(val) {
    this.monthSelection = [];
    var months = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [9, 10, 11],
    ];
    var Fullmonths = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var yr = new Date().getFullYear();
    if (val == 5) {
      $(".custom-slider").addClass("slider-show");
      var monthsArr = [];
      var start = this.minValue;
      var end = this.maxValue;
      while (start <= end) {
        monthsArr.push(this.months.split(",")[start].replace(" ", "_"));
        start++;
      }
      this.monthSelection = monthsArr;
    } else if (val == 4) {
      $(".custom-slider").removeClass("slider-show");
      var curr = new Date().getMonth();

      this.monthSelection.push(Fullmonths[curr] + "_" + yr);
    } else {
      $(".custom-slider").removeClass("slider-show");
      var that = this;
      months[val].forEach((val) => {
        // if (that.months.indexOf(Fullmonths[val] + " " + yr)) {
        that.monthSelection.push(Fullmonths[val] + "_" + yr);
        // }
      });
    }
    // this.monthSelection = val;
  }
  onFilter(val) {
    var filterSlides = [];
    var emptyArray = [];
    this.slides = this.tempSlide;
    if (val == "All") {
      // alert(val);
      this.slides = this.tempSlide;
    } else {
      this.slides.forEach((value) => {
        if (value.tags == val) {
          filterSlides.push(value);
        } else {
          emptyArray.push({ state: false });
        }
      });
      // this.slides = filterSlides;
      this.slides = filterSlides.concat(emptyArray);
    }
  }
  onReports(position) {
    // alert("dsd");
    // debugger;
    if (this.slides[position].navigation) {
      var element = this.slides[position].filename;
      var range = this.monthSelection;
      var re = /_/g;
      this.getData(element).then((content: any[]) => {
        if (content.length > 0) {
          var aParam = [];
          // this.monthSelection.forEach(function (val) {
          //   aParam.push(val + " " + element.innerText);
          // });
          var payload = {
            queryParams: {
              range: this.monthSelection.toString().replace(re, " "),
              report: element,
            },
          };
          this.router.navigate(["/reports-detail"], payload);
        } else {
          this.toastr.error("No Data for this date range", "", {
            timeOut: 2000,
          });
        }
      });
    }

    // debugger;
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private userStateService: UserStateService
  ) {
    // private router: Router;
  }

  onDownloadBilling(reportName) {
    var customerNum = localStorage.getItem("soldto");
    // var customerNum = "849487";
    var range = this.monthSelection;
    var that = this;
    var aReports = [];
    range.forEach(function (val) {
      aReports.push(val + "_" + reportName + "_" + customerNum + ".xlsx");
    });
    var sFilename = this.getInitials(range[0]);
    if (range.length > 1) {
      sFilename += "_to_" + this.getInitials(range[range.length - 1]);
    }
    const fileName = sFilename + "_" + reportName + "_" + customerNum;
    $.ajax({
      type: "POST",
      url: "/downloadBillingReport",
      data: JSON.stringify({ data: aReports }),
      contentType: "application/json",
      xhrFields: {
        responseType: "blob",
      },
      success: function (data, textStatus, XMLHttpRequest) {
        // debugger;
        if (data.type === "application/zip") {
          var url = URL.createObjectURL(data);
          var $a = $("<a />", {
            href: url,
            download: fileName,
            text: "click",
          })
            .hide()
            .appendTo("body")[0]
            .click();
        } else {
          that.toastr.error("Something went wrong. Please try again", "", {
            timeOut: 2000,
          });
        }

        // resolve(data);
      },
      dataType: "binary",
      error: function (error) {
        that.toastr.error("No Data for this timeline", "", {
          timeOut: 2000,
        });
        // resolve("Error fetching " + url);
      },
    });
    // $.ajax({
    //   url: "http://localhost:3000/downloadFile",
    //   type: "POST",
    //   data: { data: aReports },
    //   dataType: "json",
    //   xhrFields: {
    //     responseType: "blob",
    //   },
    //   success: function (data, textStatus, XMLHttpRequest) {
    //     // debugger;
    //     var url = URL.createObjectURL(data);
    //     var $a = $("<a />", {
    //       href: url,
    //       download: fileName,
    //       text: "click",
    //     })
    //       .hide()
    //       .appendTo("body")[0]
    //       .click();

    //     // resolve(data);
    //   },
    //   error: function (error) {
    //     // resolve("Error fetching " + url);
    //   },
    //   //crossDomain: true
    // });
  }

  onDownload(reportName) {
    if (reportName === "Invoice_Detail" || reportName === "Billing_Invoice") {
      this.onDownloadBilling(reportName);
    } else {
      this.customerNum = localStorage.getItem("soldto");
      // this.customerNum = "849487";
      var range = this.monthSelection;
      this.getData(reportName).then((content: any[]) => {
        if (content.length > 0) {
          var sFilename = this.getInitials(range[0]);
          if (range.length > 1) {
            sFilename += "-" + this.getInitials(range[range.length - 1]);
          }
          const fileName =
            sFilename + "_" + reportName + "_" + this.customerNum + ".xlsx";
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, fileName);
          XLSX.writeFile(wb, fileName);
        } else {
          this.toastr.error("No Data for this timeline", "", {
            timeOut: 2000,
          });
        }
      });
    }
  }
  getData(reportName) {
    return new Promise((resolve, reject) => {
      this.customerNum = localStorage.getItem("soldto");
      // this.customerNum = "849487";
      var that = this;
      var range = this.monthSelection;
      var aReports = [];
      range.forEach(function (val) {
        aReports.push(val + "_" + reportName + "_" + that.customerNum);
      });
      // var reportData = aReports;
      var soldTo = that.customerNum;
      var content = [];
      // debugger;
      var aPromise = [];
      // var aReports = this.reportData.split(",");
      // this.timeline = this.getInitials(aReports[0]);
      // if (aReports.length > 1) {
      //   this.timeline += "-" + this.getInitials(aReports[aReports.length - 1]);
      // }
      aReports.forEach((val) => {
        // var url = "assets/data/" + val + ".xlsx";
        // aPromise.push(this.getExcelData(url, soldTo));
        aPromise.push(this.getExcelData(val, soldTo));
      });

      // this.timeline = "Jan - Feb";
      Promise.all(aPromise)
        .then((response) => {
          response.forEach((val) => {
            if (Array.isArray(val)) {
              content = content.concat(val);
            }
          });
          resolve(content);
        })
        .catch((error) => {});
    });
  }

  getExcelData(url, soldTo) {
    return new Promise((resolve, reject) => {
      var $this = this;
      var sUser = localStorage.getItem("username");
      if (sUser) {
        var thisuser = sUser.toUpperCase();
        $.ajax({
          url:
            `/getExcelData?$filter= IvUser eq '${thisuser}'&$format=json&fileName=` +
            url +
            `&soldTo=` +
            soldTo,
          type: "GET",
          async: true,
          cache: true,
          beforeSend: function (xhr) {
            // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
            xhr.setRequestHeader(
              "Authorization",
              $this.userStateService.getAuthorizationHeader()
            );
            xhr.setRequestHeader(
              "Content-Type",
              "application/json; charset=utf-8"
            );
          },
          success: function (data, textStatus, XMLHttpRequest) {
            // console.log(data);
            resolve(data);
          },
          error: function (error) {
            resolve("Error fetching " + url);
          },
          //crossDomain: true
        });
      } else {
        resolve([]);
      }
    });
  }

  // getExcelData(url, soldTo) {
  //   return new Promise((resolve, reject) => {
  //     var content = [];
  //     var oReq = new XMLHttpRequest();
  //     oReq.open("GET", url, true);
  //     oReq.responseType = "arraybuffer";

  //     oReq.onload = function (e) {
  //       if (this.status === 404) {
  //         // not found, add some error handling
  //         resolve("Error fetching " + url);
  //       } else {
  //         var arraybuffer = oReq.response;

  //         /* convert data to binary string */
  //         var data = new Uint8Array(arraybuffer);
  //         var arr = new Array();
  //         for (var i = 0; i != data.length; ++i)
  //           arr[i] = String.fromCharCode(data[i]);
  //         var bstr = arr.join("");

  //         /* Call XLSX */
  //         try {
  //           var workbook = XLSX.read(bstr, { type: "binary" });

  //           /* DO SOMETHING WITH workbook HERE */
  //           var first_sheet_name = workbook.SheetNames[0];
  //           /* Get worksheet */
  //           var worksheet = workbook.Sheets["Sheet1"];
  //           var dataExcel = XLSX.utils.sheet_to_json(worksheet, {
  //             raw: true,
  //             defval: "",
  //           });
  //           // console.log(dataExcel);
  //           resolve(dataExcel);
  //           content = dataExcel;
  //         } catch (e) {
  //           resolve("Error fetching " + url);
  //         }
  //       }
  //     };
  //     oReq.onerror = function (e) {
  //       reject("Error fetching " + e);
  //     };
  //     oReq.send();
  //   });
  // }

  // async getExcel() {
  //   return await this.http.get(`/getExcelData`, {
  //     headers: {
  //       Authorization: this.userStateService.getAuthorizationHeader(),
  //     },
  //   });
  // }

  getInitials(month) {
    var text = month.substring(0, 3);
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  ngOnInit() {
    this.slides = [
      {
        navigation: true,
        tags: "B/W",
        state: "active",
        title: "B/W Volume/Cost",
        filename: "BW_VolumeCost",
      },
      {
        navigation: true,
        tags: "B/W",
        state: "active",
        title: "B/W Reduction",
        filename: "BW_Reduction",
      },
      {
        navigation: true,
        tags: "Color",
        state: "active",
        title: "Color Volume/Cost",
        filename: "Color_VolumeCost",
      },
      {
        navigation: true,
        tags: "Color",
        state: "active",
        title: "Color Reduction",
        filename: "Color_Reduction",
      },
      {
        navigation: true,
        tags: "All",
        state: "active",
        title: "B/W to Color Volume/Cost",
        filename: "BW_to_Color_VolumeCost",
      },
      {
        navigation: true,
        tags: "B/W",
        state: "active",
        title: "Underutilized B/W",
        filename: "Underutilized_BW",
      },
      {
        navigation: true,
        tags: "Color",
        state: "active",
        title: "Underutilized Color",
        filename: "Underutilized_Color",
      },
      {
        navigation: true,
        tags: "B/W",
        state: "active",
        title: "Overutilized B/W",
        filename: "Overutilized_BW",
      },
      {
        navigation: true,
        tags: "Color",
        state: "active",
        title: "Overutilized Color",
        filename: "Overutilized_Color",
      },
      {
        navigation: true,
        tags: "All",
        state: "active",
        title: "Service Calls by Device",
        filename: "Service_Calls_by_Device",
      },
      {
        navigation: true,
        tags: "All",
        state: "active",
        title: "Consumable Calls by Device",
        filename: "Consumable_Calls_by_Device",
      },
      {
        navigation: true,
        tags: "All",
        state: "active",
        title: "Unresponsive Device Report",
        filename: "Unresponsive_Device_Report",
      },
      {
        navigation: false,
        tags: "All",
        state: "active",
        title: "Billing Invoice",
        filename: "Billing_Invoice",
      },
      {
        navigation: false,
        tags: "All",
        state: "active",
        title: "Billing Invoice Detail",
        filename: "Invoice_Detail",
      },
    ];
    this.customerNum = localStorage.getItem("soldto");
    // this.customerNum = "849487";
    var $this = this;
    this.tempSlide = this.slides;
    var aMonth = this.months.split(",");
    this.monthSelection.push(aMonth[aMonth.length - 1].replace(" ", "_"));
    var currSelection = this.userStateService.getSelection();
    $(".month-selector span").click(function () {
      $(".month-selector span").removeClass("month-active");
      $this.userStateService.setSelection("." + this.className);
      $(this).addClass("month-active");
    });
    if (currSelection) {
      $(currSelection).click();
      $(".month-selector span").removeClass("month-active");
      $(currSelection).addClass("month-active");
    }

    // $(".year-selector span").click(function () {
    //   $(".year-selector span").removeClass("year-active");
    //   $(this).addClass("year-active");
    // });

    $(".filter-text").click(function () {
      $(".filter-text").removeClass("filter-active");
      $(this).addClass("filter-active");
    });

    $(".reports-box-sub-container").click(function () {
      // debugger;
    });
  }
}
