import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { InvoiceService } from "../services/invoice.service";
import * as jspdf from "jspdf";
import * as html2canvas from "html2canvas";
import { EquipmentService } from "../services/equipement-service.service";
import { InvoiceItemListComponent } from "./invoice-item-list/invoice-item-list.component";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.pug",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
  selectedFilter: string;
  invoiceFilter: HTMLSelectElement;
  inVoiceList: any[] = [];
  invoiceData = [];
  selectedInvoice;
  @ViewChild(InvoiceItemListComponent) inView: InvoiceItemListComponent;

  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private equipmentService: EquipmentService
  ) {}

  ngOnInit() {
    this.invoiceFilter = document.querySelector("#invoiceFilter");
    if (this.invoiceFilter) {
      this.selectedFilter = this.invoiceFilter.value;
    }
  }

  onSelect(invoice: any): void {
    this.selectedInvoice = invoice;
    this.invoiceService.setSelectedInvoice(invoice);
  }
  getInVoiceData(): void {
    this.equipmentService.getInVoiceData().subscribe((data) => {
      // console.log('invoicedata =>', data);
      //if (type == 'C3' || type == 'C6') {
      //this.inVoiceList = data.d.results.filter(item => item.IvType == type);
      //} else {
      this.inVoiceList = data.d.results;
      //}
      // console.log(this.inVoiceList);
      this.onSelect(this.inVoiceList[0]);
      this.invoiceData = [];
      for (var i = 0; i < data.d.results.length; i++) {
        this.invoiceData.push(data.d.results[i].EtOpenItems);
        //alert(JSON.stringify(this.invoiceData));
      }
    });
  }
  newInvoiceData() {
    this.inView.getInVoiceData();
  }

  updateSelectedFilter(filter: string) {
    this.selectedFilter = filter;
    const params = {
      type: this.selectedFilter,
    };
    this.router.navigate(["invoice"], { queryParams: params });
  }
  exportExcel() {
    this.invoiceService.sendMessage("excel");
  }
  print() {
    this.invoiceService.sendMessage("print");
  }

  getPdf() {
    const data = document.getElementById("invoice-data");
    // @ts-ignore
    html2canvas(data).then((canvas) => {
      //let pdf;
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL("image/png");
      //if(canvas.width > canvas.height){
      const pdf = new jspdf("p", "mm", [canvas.width, canvas.height]);
      //}
      //else {
      //const pdf = new jspdf('p', 'mm', [canvas.height, canvas.width]);
      // }
      const position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("invoice.pdf");
    });
  }
}
