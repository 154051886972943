import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { MyEquipmentsMetersComponent } from "../my-equipments/my-equipments.component";
import { NotificationDialogComponent } from "../notification-dialog/notification-dialog.component";
import { InvoiceService } from "../services/invoice.service";
import { EquipmentService } from "./../services/equipement-service.service";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
@Component({
  providers: [MyEquipmentsMetersComponent, NotificationDialogComponent],
  selector: "app-meter-reads",
  templateUrl: "./meter-reads.component.pug",
  styleUrls: ["./meter-reads.component.scss"],
})
export class MeterReadsComponent implements OnInit {
  uploadedData: any[];
  currentMeterIndex;
  equipments = [];
  equipList = [];
  EtMassMeterSet: [];
  meterarray = [];
  showSearch = false;
  public searchdata: FormGroup;
  constructor(
    private data: InvoiceService,
    private equipmentService: EquipmentService,
    private myEquip: MyEquipmentsMetersComponent,
    private notDia: NotificationDialogComponent,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    this.searchdata = this.formBuilder.group({
      searchelement: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  ngOnInit() {
    this.getMeterReaddata();
  }

  getMeterReaddata() {
    this.equipmentService.getMeterReadData().subscribe(
      (data) => {
        this.equipments = data.d.results.map((item) => item.EtMassMeter);
        this.equipments.forEach((item) => {
          delete item.__metadata;
        });

        this.equipList = data.d.results;
        this.equipList.forEach((item) => {
          delete item.__metadata;
        });
      },
      (err) => {}
    );
  }
  downloadExcel() {
    //alert("hi");
    this.data.sendMessage("excel");
  }
  getMeterReads(filePicker: HTMLInputElement): void {
    this.uploadedData = [];

    if (filePicker.files) {
      Array.from(filePicker.files).forEach((file, i, files) => {
        const reader: FileReader = new FileReader();

        reader.onload = (e: any) => {
          // Read workbook;
          const bStr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bStr, { type: "binary" });

          // Grab the first sheet
          // const wsName: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];

          // Save data
          // Use header only from the first workbook, if multiple selected
          const data: String[] = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            blankrows: false,
          });
          this.uploadedData = this.uploadedData.concat(
            this.uploadedData.length ? data.filter((e, i) => i > 0) : data
          );
          // console.log('uploadedData', this.uploadedData);
          this.findcurrentMeterIndex(this.uploadedData);
          if (i === files.length - 1) {
            setTimeout(() => {
              alert(
                "Imported " + (this.uploadedData.length - 1) + " record(s)"
              );
            }, 250);
          }
        };

        reader.readAsBinaryString(file);
      });
    }
  }
  findcurrentMeterIndex(uploadedData) {
    if (uploadedData) {
      uploadedData[0].forEach((ele, index) => {
        if (ele == "ReadCount") {
          this.currentMeterIndex = index;
        }
      });
    }
  }
  updateCurrentMeter(val, inxex, j) {
    const APIPostdata = this.uploadedData;
    // console.log(val, inxex);
    APIPostdata[inxex].forEach((ele, i) => {
      if (i == j) {
        this.equipments[inxex - 1].ReadCount = val;
      }
    });
    //console.log(' this.equipments', this.equipments);
    // console.log(' this.equipments', this.equipments);
  }
  PostMeterData() {
    // console.log(' this.equipments', this.equipments);
    var arrNew: any;
    //var EtMassMeterSet:String;
    var newData1: any = {
      IvSoldTo: localStorage.getItem("soldto"),
      EtMassMeterSet: "",
    };

    var newData2 = {
      Soldto: localStorage.getItem("soldto"),
      Soldtoname: "SHARP BUS SYSTEMS AZ",
      Location: "343/13 S SCOTT",
      City: "TUCSON",
      State: "AZ",
      Shipto: localStorage.getItem("soldto"),
      Billto: "",
      Equipment: "0000000000000000000000000000000064803012",
      CustEquipment: "0000000000000000000000000000000000002511",
      Model: "COCS3050CI",
      Serial: "312EQW321",
      Terms: "P&L, Drums, Supply, Staples",
      ContactName: "",
      ContactNumber: "",
      Location1: "",
      City1: "",
      State1: "",
      ReadDescription: "Total B/W",
      PrvMeter: "0000000000",
      PrvReadingDt: "00000000",
      PrvReadingDisp: "",
      CurrentMeter: "0000002002",
      CurrentReadingDt: "20190612",
      CurrentReadingDisp: "2002 (06/12/2019)",
      SettleTo: "20190630",
      MpointGuid: "593764A6DE181160E1008000AC1D1C38",
      PoNumberSold: "",
      ReadCount: "2500",
      ReadDate: "20190625",
      ReadUnit: "",
      ReadDesc: "",
      ReadOrigin: "",
      ReadTransType: "",
      Type: "",
      Message: "",
    };

    //alert(JSON.stringify(this.equipList));
    //console.log("Hello"+JSON.stringify(this.equipList));
    //this.equipList = data.d.results.map(item => item.EtMassMeter);
    this.equipList.forEach((item) => {
      //delete item.__metadata;
      /*delete item.__metadata;
                    delete item.Equipment;
                    delete item.MpointGuid;
                    delete item.Location1;
                    delete item.City1;
                    delete item.State1;
                    delete item.CurrentReadingDisp;
                    delete item.ReadOrigin;
                    delete item.Type;
                    delete item.Message;*/
      this.meterarray.push({
        Soldto: localStorage.getItem("soldto"),
        Soldtoname: item.EtMassMeter.Soldtoname,
        Location: item.EtMassMeter.Location,
        City: item.EtMassMeter.City,
        State: item.EtMassMeter.State,
        Shipto: item.EtMassMeter.Shipto,
        Billto: "",
        Equipment: item.EtMassMeter.Equipment,
        CustEquipment: item.EtMassMeter.CustEquipment,
        Model: item.EtMassMeter.Model,
        Serial: item.EtMassMeter.Serial,
        Terms: item.EtMassMeter.Terms,
        ContactName: item.EtMassMeter.ContactName,
        ContactNumber: item.EtMassMeter.ContactName,
        Location1: item.EtMassMeter.Location1,
        City1: item.EtMassMeter.City1,
        State1: item.EtMassMeter.State1,
        ReadDescription: item.EtMassMeter.ReadDescription,
        PrvMeter: item.EtMassMeter.PrvMeter,
        PrvReadingDt: item.EtMassMeter.PrvReadingDt,
        PrvReadingDisp: item.EtMassMeter.PrvReadingDisp,
        CurrentMeter: item.EtMassMeter.CurrentMeter,
        CurrentReadingDt: item.EtMassMeter.CurrentReadingDt,
        CurrentReadingDisp: item.EtMassMeter.CurrentReadingDisp,
        SettleTo: "",
        MpointGuid: item.EtMassMeter.MpointGuid,
        PoNumberSold: "",
        ReadCount: "",
        ReadDate: item.EtMassMeter.CurrentReadingDt,
        ReadUnit: "",
        ReadDesc: item.EtMassMeter.ReadDesc,
        ReadOrigin: item.EtMassMeter.ReadOrigin,
        ReadTransType: item.EtMassMeter.ReadTransType,
        Type: "",
        Message: item.EtMassMeter.Message,
      });
    });
    newData1.EtMassMeterSet = this.equipments;
    //console.log(JSON.stringify(newData1.arrNew));
    //   console.log(JSON.stringify(newData1));
    newData1 = JSON.stringify(newData1);
    //this.equipmentService.PostMeterData(this.equipments);
    this.equipmentService.PostMeterData(newData1);
  }

  searchData() {
    //   console.log(this.searchdata.value.searchelement);
    var searchele = this.searchdata.value.searchelement;
    this.data.searchData.next(searchele);
  }

  openSearch() {
    this.showSearch = true;
  }
}
