import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { UserStateService } from "src/app/services/userstate.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  Inject,
} from "@angular/core";
import { Equipment } from "./equipment";
import { EQUIPMENTS } from "./equipments-mock";
import { SPARES } from "./spares-mock";
//import Swiper from 'swiper';
import Swiper from "../../../../node_modules/swiper/dist/js/swiper.js";
import { EquipmentService } from "../../services/equipement-service.service";
import { ElementRef, Pipe } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as $ from "jquery";
import { OrderConsumablesService } from "../../services/order-consumables.service";
import { InvoiceService } from "../../services/invoice.service";
import { NgxSmartModalService } from "ngx-smart-modal";
// const lightSlider = require('../../../assets/datepick/lightslider');
import { DOCUMENT } from "@angular/platform-browser";

@Component({
  selector: "app-my-equipments",
  templateUrl: "./my-equipments.component.html",
  styleUrls: ["./my-equipments.component.scss"],
})
export class MyEquipmentsComponent implements AfterViewInit {
  equipments = EQUIPMENTS;
  spares = SPARES;
  myEquipmentsSwiper: Swiper = undefined;
  myEquipmentSparesSwipers: Swiper[] = [];
  mySparesEquipment: Swiper = undefined;
  equipmentList = [];
  consumableData = [];
  consStrData = JSON.stringify(this.consumableData);
  userRef: any;
  orderUserRefVal: String;
  checked: any;
  textdata: any;
  equipmentsArray = [];
  constructor(
    private equiptService: EquipmentService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private orderCosumablesData: OrderConsumablesService,
    private selectService: InvoiceService,
    public userStateService: UserStateService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    public toastr: ToastrService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document
  ) {}

  ngOnInit() {
    // console.log(JSON.stringify(this.equipmentList));
    setTimeout(() => {
      this.getEquipmentInfo();
    }, 2000);
    //this.getConsInfo();
  }

  ngAfterViewInit() {
    var $this = this;
    setTimeout(function () {
      this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
        direction: "vertical",
        slidesPerView: "auto",
        slidesPerGroup: 4,
        navigation: {
          nextEl: ".my-equipments--navigation--next",
          prevEl: ".my-equipments--navigation--prev",
        },
      });
    }, 7000);

    //this.myEquipmentsSwiper.update();

    // let swiperContainer: HTMLElement = document.querySelector('.spares-container .swiper-container');
    //     let prevElement: HTMLElement = document.querySelector('.spares-action .nav-prev');
    //     let nextElement: HTMLElement = document.querySelector('.spares-action .nav-next');

    // let newSpares;

    // Array.from(document.querySelectorAll(".my-equipment--spares")).forEach(
    //   spares => {
    //     let swiperContainer: HTMLElement = spares.querySelector(
    //       ".spares-container .swiper-container"
    //     );
    //     let prevElement: HTMLElement = spares.querySelector(
    //       ".spares-action .nav-prev"
    //       // ".spares-action--nav--prev"
    //     );
    //     let nextElement: HTMLElement = spares.querySelector(
    //       ".spares-action .nav-next"
    //       // ".spares-action--nav--next"
    //     );
    //     setTimeout(function() {
    //       newSpares = new Swiper(swiperContainer, {
    //         slidesPerView: "auto",
    //         direction: 'horizontal',
    //         navigation: {
    //           nextEl: nextElement,
    //           prevEl: prevElement
    //         }
    //       });
    //     }, 4000);
    //     this.myEquipmentSparesSwipers.push(newSpares);
    //   }
    // );

    this.selectService.searchData.subscribe((data) => {
      if (data) {
        // console.log(data+"this is search value");
        // console.log(this.equipmentList);
        if (data && data.trim() != "") {
          this.equipmentList = this.equipmentsArray.filter((item) => {
            return (
              item.Sernr.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
              item.EquipmentId.toLowerCase().indexOf(data.toLowerCase()) > -1
            );
          });
        } else {
          this.equipmentList = this.equipmentsArray;
        }
      } else {
        this.equipmentList = this.equipmentsArray;
      }
    });
    //newSpares.update();
    //var ab=this.elementRef.nativeElement.querySelector('.my-equipment--spares').resize();

    this.selectService.getMessage().subscribe((data) => {
      // console.log(data);
      this.getEquipmentInfo();
      setTimeout(function () {
        this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
          direction: "vertical",
          slidesPerView: "auto",
          slidesPerGroup: 4,
          navigation: {
            nextEl: ".my-equipments--navigation--next",
            prevEl: ".my-equipments--navigation--prev",
          },
        });
      }, 7000);

      // let newSpares;

      // Array.from(document.querySelectorAll(".my-equipment--spares")).forEach(
      //   spares => {

      //     let swiperContainer: HTMLElement = spares.querySelector(
      //       ".spares-container .swiper-container"
      //     );
      //     let prevElement: HTMLElement = spares.querySelector(
      //       ".spares-action .nav-prev"
      //       // ".spares-action--nav--prev"
      //     );
      //     let nextElement: HTMLElement = spares.querySelector(
      //       ".spares-action .nav-next"
      //       // ".spares-action--nav--next"
      //     );
      //     setTimeout(function() {
      //       newSpares = new Swiper(swiperContainer, {
      //         slidesPerView: "auto",
      //         direction: 'horizontal',
      //         navigation: {
      //           nextEl: nextElement,
      //           prevEl: prevElement
      //         }
      //       });
      //     }, 4000);
      //     this.myEquipmentSparesSwipers.push(newSpares);
      //   }
      // );
    });

    $(document).ready(function () {
      $(".scroll-y").scroll(function () {
        if ($(".scroll-y").scrollTop() > 1600) {
          $(".scroll-y").removeClass("scrollwhite scrollhovergrey");
          $(".scroll-y").addClass("scrollred scrollhoverblack");
        } else {
          $(".scroll-y").removeClass("scrollred scrollhoverblack");
          $(".scroll-y").addClass("scrollwhite scrollhovergrey");
        }
      });
    });
  }

  toggleSpares(equipment: Equipment, sparesContainer: HTMLElement) {
    let thisSlide = sparesContainer.closest(".my-equipment-slide");
    let allSlides = thisSlide
      .closest(".my-equipments--container")
      .querySelectorAll(".my-equipment-slide");
    let thisSlideIndex = Array.from(allSlides).indexOf(thisSlide);
    let btnViewSparesLabel = thisSlide.querySelector(
      ".btn-view-spares .icon-btn--label"
    );
    $(".my-equipment-slide")
      .closest(".my-equipments--container")
      .find(".my-equipment--spares")
      .removeClass("shown");
    // console.log(thisSlideIndex);

    sparesContainer.classList.toggle("shown");
    window.setTimeout(() => {
      if (this.myEquipmentsSwiper) {
        this.myEquipmentsSwiper.updateSlides();
      }
      if (this.mySparesEquipment) {
        this.mySparesEquipment.updateSlides();
      }
      if (sparesContainer.classList.contains("shown")) {
        btnViewSparesLabel.innerHTML = "Hide Items";
        // $(this).parent().parent().removeClass("shown")
        //this.myEquipmentsSwiper.slideTo(thisSlideIndex, 600);
      } else {
        btnViewSparesLabel.innerHTML = "View Items";
      }
    }, 400);
    if (btnViewSparesLabel.innerHTML == "Hide Items") {
      sparesContainer.classList.toggle("shown");
    }
  }

  getEquipmentInfo() {
    this.spinner.show();
    var defEquip = localStorage.getItem("equip");
    if (defEquip) {
      var jEquip = JSON.parse(defEquip);
      for (var i = 0; i < jEquip.d.results.length; i++) {
        this.equipmentList.push(jEquip.d.results[i].EtList);
      }
      this.equipmentsArray = this.equipmentList.slice();
      // setTimeout(() =>{
      this.spinner.hide();
      // },3000);
    } else {
      this.equipmentList = [];

      this.equiptService.getEquipmentInfo().subscribe(
        (equiData) => {
          //console.log(equiData.d)
          this.spinner.hide();
          for (var i = 0; i < equiData.d.results.length; i++) {
            this.equipmentList.push(equiData.d.results[i].EtList);
          }
          this.equipmentsArray = this.equipmentList.slice();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
    }
  }

  closeThis() {
    $(".spares-action--close").parent().parent().removeClass("shown");
  }

  getConsInfo(userRef) {
    this.userRef = userRef;
    this.consumableData = [];
    //alert(userRef);
    this.orderCosumablesData
      .getOrderCosumablesData(this.userRef)
      .subscribe((consData) => {
        for (var i = 0; i < consData.d.results.length; i++) {
          this.consumableData.push(consData.d.results[i]);
          // console.log(JSON.stringify(this.consumableData));
        }
        /*this.http.get("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '56FC534B6CBF0CD0E1008000AC1D1C38'&$format=json")
      .subscribe((consData) => {
        for (var i = 0; i < consData.d.results.length; i++) {
          this.consumableData.push(consData.d.results[i]);
      //console.log(JSON.stringify(this.consumableData));
      let trlen=$(".cons-data-cell").length;
      //alert(consData.d.results.length);
              console.log("tr length"+trlen);
              let Total=0;
              for (var i=0; i<trlen; i++)
              {
                Total+=parseFloat((<HTMLInputElement>parseFloat($(".or-qty"))[i]).innerHTML)*parseFloat($(".or-price")[i].innerHTML);
                //Total+=indTotal[i];
                console.log(parseFloat((<HTMLInputElement>parseFloat($(".or-qty"))[i]).innerHTML) + " " + parseFloat($(".or-price")[i].innerHTML));
                //console.log(indTotal[i]);
              }
              $("#orderConsumables2 .newtotal").html(Total);

    }
  })*/
      });
  }

  createOrderView(orderUserRef) {
    this.closeOrderView();
    this.spinner.show();
    this.orderUserRefVal = orderUserRef;
    //alert("hello");
    var innerText = $(this).text().trim();
    $(".main-page-form").addClass("active");
    $(".order-consumables-order-form").removeClass("hide");
    $(".order-consumables-order-form").siblings().addClass("hide");
    $("#orderConsumables2 .cons-data-cell").remove();
    $(".order-consumables-sl .message-no-items").hide();

    /*this.orderCosumablesData.getOrderCosumablesData().subscribe((consData) => {
    for (var i = 0; i < consData.d.results.length; i++) {
      this.consumableData.push(consData.d.results[i]);
    }*/
    /*this.http.get("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '56FC534B6CBF0CD0E1008000AC1D1C38'&$format=json")
    .subscribe((consData) => {
      for (var i = 0; i < consData.d.results.length; i++) {
        this.consumableData.push(consData.d.results[i]);
    //console.log(JSON.stringify(this.consumableData));
    let trlen=$(".cons-data-cell").length;
    //alert(consData.d.results.length);
            console.log("tr length"+trlen);
            let Total=0;
            for (var i=0; i<trlen; i++)
            {
              Total+=parseFloat((<HTMLInputElement>parseFloat($(".or-qty"))[i]).innerHTML)*parseFloat($(".or-price")[i].innerHTML);
              //Total+=indTotal[i];
              console.log(parseFloat((<HTMLInputElement>parseFloat($(".or-qty"))[i]).innerHTML) + " " + parseFloat($(".or-price")[i].innerHTML));
              //console.log(indTotal[i]);
            }
            $("#orderConsumables2 .newtotal").html(Total);

  }
})*/

    //alert("Hello"+$(this).parent().html());

    //var ivorder=$(this).parent().parent().find(".equipinfo").text().trim();
    var ivorder = this.orderUserRefVal;
    //alert(ivorder);
    //alert(ivorderinfo);
    // var ab="http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '56FC534B6CBF0CD0E1008000AC1D1C38'&$format=json"

    //  var ordInfoURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '"+ivorder+"'&$format=json";
    //  console.log(ordInfoURL);
    // var userRefNo = "641433";
    var userRefNo = localStorage.getItem("soldto");
    var $this = this;
    // var thisuser = "SBSTST000";
    var thisuser = localStorage.getItem("username").toUpperCase();
    var opt = "X";
    var ordInfoURL = `/getConsInfo?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}' and IvEquipment eq '${ivorder}'&$format=json`;
    //let checkedind=[];
    var checkboxes =
      this.elementRef.nativeElement.parentElement.parentElement.querySelectorAll(
        ".spares-check"
      );

    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].name);
      }
    }
    //alert(JSON.stringify(checkboxesChecked));
    $.ajax({
      context: this,
      url: ordInfoURL,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          this.userStateService.getAuthorizationHeader()
        );
      },
      success: function (orderData) {
        $this.spinner.hide();
        // console.log(JSON.stringify(orderData));
        orderData = orderData;
        let newdata: any = orderData.d.results[0];
        //let newdata:any=reqData.d.results[0];
        //let newdata;
        //alert(newdata.EsEqInfo.Equipment);

        $(".order-consumables-sl .equip-id").text(newdata.EsEqInfo.EquipmentId);
        $(".order-consumables-sl .model").text(newdata.EsEqInfo.Model);
        $(".order-consumables-sl .sl-no").text(newdata.EsEqInfo.Sernr);
        $(".order-consumables-sl .contr-terms").text(newdata.EsEqInfo.Terms);
        $(".order-consumables-sl .equiprefno").text(newdata.EsEqInfo.Equipment);

        $("#orderConsumables2 .order-data-o").remove();
        var newRows = "";
        if (newdata.EsReturn.Type != "E") {
          //alert(orderData.d.results.length);
          for (var j = 0; j < checkboxesChecked.length; j++) {
            var checkVal = checkboxesChecked[j];
            //alert(JSON.stringify(checkboxesChecked));
            //alert(checkVal);
            newRows +=
              '<tr style="background:#fff; border-bottom: solid 1px #000;" class="order-data-o"><td class="part-no" style="border: solid 1px #ccc; padding: 5px; width: 56%" id="partno' +
              j +
              '">' +
              orderData.d.results[checkVal].EtPPR.ProductId +
              "</td>" +
              '<td class="desc" style="border: solid 1px #ccc; padding: 5px; width: 20%">' +
              orderData.d.results[checkVal].EtPPR.ProductDescr +
              "</td>" +
              "<td class=\"qty2\" style=\"border: solid 1px #ccc; padding: 5px; width: 6%\"><input type=\"text\" size=\"4\" class=\"inqty2\" onkeyup=\"if(parseInt(this.parentElement.parentElement.lastElementChild.innerHTML.trim()) == 0){if(this.value > 1){this.value = 1; document.getElementById('quantity2-error').style.display = 'block'}else{document.getElementById('quantity2-error').style.display = 'none'}};var odc = document.getElementById('orderConsumables2').children[0].children;var total = 0;for(var i=1; i < odc.length-1; i++) {var priceval = parseInt(odc[i].children[2].getElementsByClassName('inqty2')[0].value)*parseFloat(odc[i].children[4].innerText);if(!priceval){priceval = 0}total+=priceval}odc[odc.length-1].getElementsByClassName('newtotal')[0].innerText = total;\" value=" +
              orderData.d.results[checkVal].EtPPR.Quantity +
              ' id="inq' +
              j +
              '"></input></td>' +
              '<td class="unit2" style="border: solid 1px #ccc; padding: 5px; width: 8%;">' +
              orderData.d.results[checkVal].EtPPR.Unit +
              "</td>" +
              '<td class="price2" style="border: solid 1px #ccc; padding: 5px; width: 10%; text-align: right;">' +
              orderData.d.results[checkVal].EtPPR.Price +
              "</td></tr>";
          }
          /*for (var i=0; i< orderData.d.results.length; i++) {//alert("Hi");
            newRows+='<tr style="background:#fff; border-bottom: solid 1px #000;" class="order-data-o"><td class="part-no" style="border: solid 1px #ccc; padding: 5px; width: 56%">'+ orderData.d.results[i].EtPPR.ProductId +'</td>'+
            '<td class="desc" style="border: solid 1px #ccc; padding: 5px; width: 20%">'+orderData.d.results[i].EtPPR.ProductDescr+'</td>'+
            '<td class="qty2" style="border: solid 1px #ccc; padding: 5px; width: 6%"><input type="text" size="4" class="inqty2" value='+orderData.d.results[i].EtPPR.Quantity+'></input></td>'+
            '<td class="unit2" style="border: solid 1px #ccc; padding: 5px; width: 8%;">'+orderData.d.results[i].EtPPR.Unit+'</td>'+
            '<td class="price2" style="border: solid 1px #ccc; padding: 5px; width: 10%; text-align: right;">'+orderData.d.results[i].EtPPR.Price+'</td></tr>';

            //console.log(orderData.d.results[i].EtItems.Unit);
            } */
        } else {
          $(".order-consumables-sl .message-no-items").show();
          $(".order-consumables-sl .message-no-items").text(
            newdata.EsReturn.Message
          );
        }
        // console.log(newRows);
        $("#orderConsumables2 .head").after(newRows);
        let indTotal: any;
        let trlen = $(".order-data-o").length;
        // console.log("tr length" + trlen);
        let Total = 0;
        for (var i = 0; i < trlen; i++) {
          Total +=
            parseFloat(
              (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
                .value
            ) * parseFloat($(".price2")[i].innerHTML);
          //Total+=indTotal[i];
          // console.log(
          //   parseFloat(
          //     (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
          //       .value
          //   ) +
          //     " " +
          //     parseFloat($(".price2")[i].innerHTML)
          // );
          //console.log(indTotal[i]);
        }
        $("#orderConsumables2 .newtotal").html(Total);
        // console.log(Total);
      },
      error: function (odata) {
        $this.spinner.hide();
      },
    });
    $("#orderConsumables2 .inqty2").keyup(function () {
      let trlen = $(".order-data-o").length;
      let Total1 = 0;
      let newrowTotal;
      //let rowtotal;
      //for (var i=0; i<trlen; i++)
      //{
      //alert("yyyy");
      newrowTotal =
        parseFloat($(this).val()) *
        Number($(this).parent().find("price2").innerHTML);
      //Total+=indTotal[i];
      // console.log(
      //   parseFloat($(this).val()) +
      //     " " +
      //     Number($(this).parent().find("price2").innerHTML)
      // );
      //console.log(indTotal[i]);
      //}
      for (var i = 0; i < trlen; i++) {
        Total1 +=
          parseFloat(
            (<HTMLInputElement>document.getElementsByClassName("inqty2")[i])
              .value
          ) * Number($(".price2")[i].innerHTML);
      }

      $("#orderConsumables2 .newtotal").text(Total1);
      // console.log(Total1);
    });
  }

  closeOrderView() {
    // e.preventDefault();
    document.querySelector(".main-page-form").classList.remove("active");
    $(".order-consumables-sl .equip-id").text("");
    $(".order-consumables-sl .model").text("");
    $(".order-consumables-sl .sl-no").text("");
    $(".order-consumables-sl .contr-terms").text("");
    $(".order-consumables-order-form .shipping-instr-create-order").val("");
    $("#orderConsumables2 .newtotal").text("");
    $("#orderConsumables2 .order-data-o").remove();
    document.getElementById("quantity2-error").style.display = "none";
  }

  postConsOrdernew() {
    this.spinner.show();
    var $this = this;
    //alert("hi");
    var narray = [];
    var rows = $("#orderConsumables2 tr");
    //alert($("#orderConsumables2 tr").length);
    for (var i = 0; i < $("#orderConsumables2 tr").length; i++) {
      //var ab=$("#orderConsumables2 #partno"+i).text();
      //var cd=$("#orderConsumables2 #inq"+i).val();

      narray.push({
        SoldTo: localStorage.getItem("soldto"),
        ProductId: $("#partno" + i).text(),
        Quantity: $("#inq" + i).val(),
      });
      //narray.pop();
      //narray.pop();
    }
    narray.pop();
    narray.pop();

    //alert(JSON.stringify(narray));

    var orderdata: any = {
      IvSoldTo: localStorage.getItem("soldto"),
      IvShippingInstruct: "",
      IvEquipment: "",
      IvText: "",
      EtProductsSet: "",
    };
    var shipInstr = $(
      ".order-consumables-order-form .shipping-instr-create-order"
    ).val();
    //alert($(".order-consumables-order-form .shipping-instr-create-order").val() + "Hello" +$(".order-consumables-order-form .equiprefno").text())
    var equipRefno = $(".order-consumables-order-form .equiprefno").text();
    orderdata.IvShippingInstruct = shipInstr;
    orderdata.IvEquipment = equipRefno;
    orderdata.EtProductsSet = narray;

    var orderdata1 = orderdata;
    // console.log(orderdata1);
    //alert(JSON.stringify(orderdata));

    var token = "";
    var cookie = "";

    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        //  xhr.setRequestHeader('X-CSRF-Token', 'Fetch');
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        //  token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      document.cookie = cookie;
      $.ajax({
        url: `/createorders`,

        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: orderdata1, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("setcookie", cookie);
        },
        success: function (odata) {
          // console.log("Crate order success:", JSON.stringify(odata));
          $this.spinner.hide();
          //alert(odata.d.EvType);
          if (odata.d.EvType == "S") {
            // alert("Create Order success. Order number: " + odata.d.IvOrderNo);
            $this.textdata =
              "Create Order success. Order number: " + odata.d.IvOrderNo;
            // $this.ngxSmartModalService.open('myModal');
            $this.toastr.success($this.textdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
            $this.closeOrderView();
          }
          if (odata.d.EvType == "E") {
            // alert(odata.d.EvMessage);
            $this.textdata = odata.d.EvMessage;
            // $this.ngxSmartModalService.open('myModal');
            $this.toastr.success($this.textdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
        },
        error: function (odata) {
          $this.spinner.hide();
          // console.log("error:", JSON.stringify(odata));
          // alert("error:" + JSON.stringify(odata));
          $this.textdata = "error:" + JSON.stringify(odata);
          // $this.ngxSmartModalService.open('myModal');
          $this.toastr.error($this.textdata, "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);

    /*$.ajax({
        type: "post",
        url: "http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/CreateConsOrderSet",
        data: orderdata,
        success: function(data){console.log(JSON.stringify(data))},
        contentType: 'application/json; charset=utf-8'
        //dataType: 'json'
      });*/

    /*this.http.post("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/CreateConsOrderSet", orderdata, {headers:
  {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} })
  .subscribe((res:any) => {
   let abgg = res.json();
    console.log(abgg);
  },
  (error) => {
    console.log(error);
  });*/
  }
}
