import { Equipment } from './equipment';

export const EQUIPMENTS: Equipment[] = [
    {
        equipmentID: "86246626",
        serial: "85007826",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246627",
        serial: "85007827",
        modelID: "MXM5050",
        modelName: "30 CPM Color MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm5050--30-cpm-color-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246628",
        serial: "85007828",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246629",
        serial: "85007829",
        modelID: "MXM5050",
        modelName: "30 CPM Color MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm5050--30-cpm-color-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246630",
        serial: "85007830",
        modelID: "MXM5050",
        modelName: "30 CPM Color MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm5050--30-cpm-color-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246631",
        serial: "85007831",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246632",
        serial: "85007832",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246633",
        serial: "85007833",
        modelID: "MXM5050",
        modelName: "30 CPM Color MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm5050--30-cpm-color-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246634",
        serial: "85007834",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
    {
        equipmentID: "86246635",
        serial: "85007835",
        modelID: "MXM3070",
        modelName: "30 CPM Monochrome MFP B-Phoenix",
        contractTerms: "Call for Terms",
        imgFileName: "product--mxm3070--30-cpm-monochrome-mpf-b-phoenix.svg",
        supportedSpares: ["MX607HB", "MX607HC", "MX607HM", "MX607HY", "MX607XY", "MX607XZ"]
    },
];