import { Component, OnInit } from '@angular/core';
//declare var jquery:any;
//declare var $ :any;
import * as $ from 'jquery';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function () {

      // login form
      $("body").on("click", ".login-home", function(e) {
        e.preventDefault();
        $('.login-form').addClass('active');
        $('.login-home').css('opacity', '0');

        $('.input-login').removeClass('hide');
        $('.input-login').siblings().addClass('hide');
      });
      $("body").on("click", ".login-close", function(e) {
        e.preventDefault();
        $('.login-form').removeClass('active');
        $('.login-home').css('opacity', '1');
      });
      
      //Dealer slider pop
      $("body").on("click", ".dealer-home", function(e) {
        e.preventDefault();

        var modalHref = $(this).attr("data-href");
        $('body').addClass('modal-open');

        $(modalHref).css('display', 'block').delay(10).queue(function () {
          $(modalHref).find('.modal-content').css('transform', 'translateY(0)');
          $(this).dequeue();
        });
      });
      $("body").on("click", ".dealer-close", function(e) {
        e.preventDefault();

        $('body').removeClass('modal-open');

        $(this).parents('.modal').find('.modal-content').css('transform', 'translateY(-700px)').delay(500).queue(function(){
          $(this).parents('.modal').css('display', 'none');
          $(this).dequeue();
        });
      });


      $("body").on("click", ".forgot-signup .forgot-password", function() {
        //alert('forgot');
        $('.input-forgot-password').removeClass('hide');
        $('.input-forgot-password').siblings().addClass('hide');
      });
      
      $("body").on("click", ".forgot-signup .sign-up", function(e) {
        e.preventDefault();
        $('.input-signup').removeClass('hide');
        $('.input-signup').siblings().addClass('hide');
      });
      
      //On focus of input fields functionality (floating labels)
      $(".input-form-group .input").focus(function() {
        if($(this).val() == "") {
          $(this).addClass("floating-label");
        }
      });
      
      //On blur of input fields functionality (floating labels)
      $(".input-form-group .input").blur(function() {
        if($(this).val() == "") {
          $(this).removeClass("floating-label");
        }
      });
      
      if($(".input-form-group .input").val() !== ''){
        $(".input-form-group .input").addClass("floating-label");
      }
      else{
        $(".input-form-group .input").removeClass("floating-label");
      }

      $("body").on("click", ".home-menu-list", function(e) {
        // $('.drivers-manuals-li').off('click');
        $('.login-form').addClass('active');
        $('.login-home').css('opacity', '0');

        if($(this).hasClass('service-request-li')){
          $('.input-service-request').removeClass('hide');
          $('.input-service-request').siblings().addClass('hide');
        }
        else {
          $('.input-login').removeClass('hide');
          $('.input-login').siblings().addClass('hide');
        }
      });

    });
  }

}
