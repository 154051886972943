import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
//import {Headers, RequestOptions, HttpClient, Response} from '@angular/http';
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";

import { switchMap, filter } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
import * as $ from "jquery";
import { AppComponent } from "../app.component";
//import {HeaderComponent} from "../header/header.component";
import { BehaviorSubject } from "rxjs";
import { UserStateService } from "./userstate.service";
import { Constants } from "../common/constants";

@Injectable({
  providedIn: "root",
})
export class EquipmentService {
  equipmentInfo = {};
  userid: any;
  userRef = [];

  onCustomerSelect: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userlogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //static userRef=[];

  constructor(
    private http: HttpClient,
    private userStateService: UserStateService,
    private userInfoService: AppComponent,
    public toastr: ToastrService
  ) {
    if (this.userInfoService.userdetail) {
      this.userid = this.userInfoService.userdetail.trim();
    }
    // console.log(this.userid);
    //alert(this.getUserRefData1());
    if (this.userid) {
      var thisuser = this.userid.toUpperCase();
    }
    /*$.ajax({
              url : "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetUserREfSet?$filter= IvUser eq '"+thisuser+"'&$format=json",
              type : "GET",
              async : true,
              success: function(response){
                alert(JSON.stringify(response));
                for (var i = 0; i < response.d.results.length; i++) {
                  this.userRef.push(response.d.results[i].EtRefferances.SoldTo);
                  alert(JSON.stringify(this.userRef));
              }
            }
          });*/
    /*this.getUserRefData().subscribe((userData) => {
        //console.log(equiData.d)
        alert(JSON.stringify(userData));
        for (var i = 0; i < userData.d.results.length; i++) {
          this.userRef.push(userData.d.results[i].EtRefferances.SoldTo);
          alert(JSON.stringify(this.userRef));
        }
      })*/
  }

  // getEquipmentInfo(): Observable<any> {
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   headers.append("Access-Control-Allow-Origin", "*");
  //   return this.http.get("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetEquipmentSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000'&$format=json", {headers: headers}).pipe(
  //     map(this.extractData));

  // }

  getUserRefData(): Observable<any> {
    // var thisuser = "SBSTST000";
    var thisuser = localStorage.getItem("username").toUpperCase();
    // var userRefNo = 641433;
    //code change by sahana
    // debugger;
    // var thisuser=(this.userid).toUpperCase();
    // var ab =
    //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetUserREfSet?$filter= IvUser eq '" +
    //   thisuser +
    //   "'&$format=json";
    // console.log(ab);
    // return this.http.get(
    //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetUserREfSet?$filter= IvUser eq '" +
    //     thisuser +
    //     "'&$format=json"
    // );
    // console.log("user logged in get user ref");
    return this.http.get(
      `/getUserRefSet?$filter= IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getEquipmentInfo(): Observable<any> {
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo;
    userRefNo = $("#userRefs").val();
    if (userRefNo != undefined) {
      //alert("one")
      userRefNo = userRefNo;
    } else {
      //alert("two")
      if (localStorage.getItem("soldto") !== null) {
        userRefNo = localStorage.getItem("soldto");
      } else {
        userRefNo = this.userRef[0];
      }
    }

    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/equipment?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getopenOrders(): Observable<any> {
    var userRefNo;
    userRefNo = $("#userRefs").val();
    if (userRefNo != undefined) {
      //alert("oneaa")
      userRefNo = userRefNo;
    } else {
      //alert("twoaa")
      if (localStorage.getItem("soldto") !== null) {
        userRefNo = localStorage.getItem("soldto");
      } else {
        userRefNo = this.userRef[0];
      }
    }
    var thisuser = localStorage.getItem("username").toUpperCase();
    //http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenRequestSet?$filter= IvSoldTo eq '641433'
    // return this.http.get(
    //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenOrdersSet?$filter= IvSoldTo eq '" +
    //     userRefNo +
    //     "'&$format=json"
    // );
    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/getOpenOrders?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getopenRequests(): Observable<any> {
    //code change by sahana
    // var thisuser = "SBSTST000";
    // var userRefNo = 641433;
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo;
    userRefNo = $("#userRefs").val();
    //var userRefNo=$("#userRefs").val();
    var ab = $("#userRefs :selected").text();
    //var ab=$("#userRefs").text();
    //alert(userRefNo+"fssgsg"+ab);
    if (userRefNo != undefined) {
      //alert("oneaa")
      userRefNo = userRefNo;
    } else {
      //alert("twoaa")
      if (localStorage.getItem("soldto") !== null) {
        userRefNo = localStorage.getItem("soldto");
      } else {
        userRefNo = this.userRef[0];
      }
    }
    //http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenRequestSet?$filter= IvSoldTo eq '641433'
    // return this.http.get(
    //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenRequestSet?$filter= IvSoldTo eq '" +
    //     userRefNo +
    //     "'&$format=json"
    // );

    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/getOpenRequets?$filter= IvSoldTo eq '${userRefNo}'and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }
  getInVoiceData(): Observable<any> {
    //alert($("#invoiceFilter").val());
    //let ivTypeVal;
    let ivtype = $("#invoiceFilter").val();
    //return this.http.get<any>("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenItemsSet?$filter= IvSoldTo eq '641433' and IvType eq 'O'&$format=json").pipe(
    // map(res => res));
    //code change by sahana
    // var thisuser = "SBSTST000";
    // var userRefNo = 641433;
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo;
    userRefNo = $("#userRefs").val();
    //var userRefNo=$("#userRefs").val();
    var ab = $("#userRefs :selected").text();
    //var ab=$("#userRefs").text();
    //alert(userRefNo+"fssgsg"+ab);
    if (userRefNo != undefined) {
      //alert("oneaa")
      userRefNo = userRefNo;
    } else {
      //alert("twoaa")
      userRefNo = this.userRef[0];
    }

    localStorage.setItem("soldto", userRefNo);
    if (ivtype == "dateRange") {
      ivtype = "CA";
      // $(document).ready(function(){$(".invoice-cal-btn").click(function(){
      if ($(".e-date-range-wrapper input.e-input").val()) {
        var calInput = $(".e-date-range-wrapper input.e-input")
          .val()
          .split("-");
        // console.log(calInput[0] + " " + calInput[1]);
        var fromDate = calInput[0];
        var fromDatefrmt = calInput[0].split("/");
        fromDatefrmt[0] = fromDatefrmt[0].trim();
        fromDatefrmt[1] = fromDatefrmt[1].trim();
        if (fromDatefrmt[0].trim().length == 1) {
          fromDatefrmt[0] = "0" + fromDatefrmt[0];
        }
        if (fromDatefrmt[1].trim().length == 1) {
          fromDatefrmt[1] = "0" + fromDatefrmt[1];
        }
        var newFromDate =
          fromDatefrmt[2].trim() +
          fromDatefrmt[0].trim() +
          fromDatefrmt[1].trim();
        var toDate = calInput[1];
        var toDatefrmt = calInput[1].split("/");
        toDatefrmt[0] = toDatefrmt[0].trim();
        toDatefrmt[1] = toDatefrmt[1].trim();
        if (toDatefrmt[0].trim().length == 1) {
          toDatefrmt[0] = "0" + toDatefrmt[0];
        }
        if (toDatefrmt[1].trim().length == 1) {
          toDatefrmt[1] = "0" + toDatefrmt[1];
        }
        var newToDate =
          toDatefrmt[2].trim() + toDatefrmt[0].trim() + toDatefrmt[1].trim();
        // console.log(newFromDate);
        // console.log(newToDate);
        // console.log(
        //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenItemsSet?$filter= IvSoldTo eq '" +
        //     userRefNo +
        //     "' and IvType eq '" +
        //     ivtype +
        //     "' and IvFromDate eq '" +
        //     newFromDate +
        //     "' and IvToDate eq '" +
        //     newToDate +
        //     "'&$format=json"
        // );
        //$(document).ready(function(){$(".invoice-cal-btn").click(function(){
        // return this.http.get(
        //   "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenItemsSet?$filter= IvSoldTo eq '" +
        //     userRefNo +
        //     "' and IvType eq '" +
        //     ivtype +
        //     "' and IvFromDate eq '" +
        //     newFromDate +
        //     "' and IvToDate eq '" +
        //     newToDate +
        //     "'&$format=json"
        // );

        return this.http.get(
          `/getOpenItemSetWithDate?$filter= IvSoldTo eq '${userRefNo}' and IvType eq '${ivtype}' and IvFromDate eq '${newFromDate}' and IvToDate eq '${newToDate}'&$format=json`,
          {
            headers: {
              Authorization: this.userStateService.getAuthorizationHeader(),
            },
          }
        );
      }
    }

    // return this.http
    //   .get<any>(
    //     "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetOpenItemsSet?$filter= IvSoldTo eq '" +
    //       userRefNo +
    //       "' and IvType eq '" +
    //       ivtype +
    //       "'&$format=json"
    //   )
    return this.http
      .get(
        `/getOpenItemSet?$filter= IvSoldTo eq '${userRefNo}' and IvType eq '${ivtype}'&$format=json`,
        {
          headers: {
            Authorization: this.userStateService.getAuthorizationHeader(),
          },
        }
      )
      .pipe(map((res) => res));
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log("worksheet", worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  getMeterReadData(): Observable<any> {
    /*return this.http.get<any>('../../assets/json/meter-read.json').pipe(
      map(res => res));*/
    //return this.http.get<any>("http://crdb00.sec.sharpamericas.com:8000//sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetMassMeterSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000'&$format=json")
    //code change by sahana
    // var thisuser = "SBSTST000";
    // var userRefNo = 641433;
    var thisuser = localStorage.getItem("username").toUpperCase();

    var userRefNo;
    userRefNo = $("#userRefs").val();
    //var userRefNo=$("#userRefs").val();
    var ab = $("#userRefs :selected").text();
    //var ab=$("#userRefs").text();
    //alert(userRefNo+"fssgsg"+ab);
    if (userRefNo != undefined) {
      //alert("oneaa")
      userRefNo = userRefNo;
    } else {
      //alert("twoaa")
      userRefNo = this.userRef[0];
    }
    localStorage.setItem("soldto", userRefNo);
    //  thisuser=(this.userid).toUpperCase();

    // return this.http
    //   .get<any>(
    //     "/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetMassMeterSet?$filter= IvSoldTo eq '" +
    //       userRefNo +
    //       "' and IvUser eq '" +
    //       thisuser +
    //       "'&$format=json"
    //   )
    //   .pipe(map(res => res));
    return this.http
      .get(
        `/getMassMeterSet?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json`,
        {
          headers: {
            Authorization: this.userStateService.getAuthorizationHeader(),
          },
        }
      )
      .pipe(map((res) => res));
  }
  PostMeterData(data) {
    var $this = this;
    // console.log(data);
    //this.http.post("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/PostMassMetersSet", data, {headers:
    // {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} })
    /*****this.http.post("http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/PostMassMetersSet", data, {headers:
      {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} })
      .subscribe((res:any) => {
       let abgg = res.json();
        // console.log(abgg);
      },
      (error) => {
        console.log(error);
      });*****/
    // go for Post API

    var token = "";
    var cookie = "";
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        // xhr.setRequestHeader("X-CSRF-Token", "Fetch");
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        // console.log(data);
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        // console.log("Token:" + token);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      $.ajax({
        url: `/postmassmeterdata`,
        async: false,
        dataType: "json",
        data: JSON.stringify({ actual: data, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          // console.log("Mass meter success:", JSON.stringify(odata));
          //alert(JSON.stringify(odata));
          var data1: any = odata.d.EtMassMeterSet.results;
          var msg = "";
          for (var i = 0; i < data1.length; i++)
            if (data1[i].Type == "E") {
              msg += data1[i].Message + "\n";
            }
          // alert(msg);
          $this.toastr.success(msg, "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
        error: function (data) {
          // console.log("error:", JSON.stringify(data));
          // alert("error:"+JSON.stringify(data));
          $this.toastr.error(JSON.stringify(data), "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }

  /**Hanumanth code for deafult data */
  getEquipmentInfoDefault(refuser): Observable<any> {
    //Code change by sahana
    // var thisuser = "SBSTST000";
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo = refuser;

    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/equipment?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getOpenOrdersDefault(refuser): Observable<any> {
    //Code change by sahana
    // var thisuser = "SBSTST000";
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo = refuser;

    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/getOpenOrders?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getOpenRequestsDeafult(refuser): Observable<any> {
    //Code change by sahana
    // var thisuser = "SBSTST000";
    var thisuser = localStorage.getItem("username").toUpperCase();
    var userRefNo = refuser;

    localStorage.setItem("soldto", userRefNo);
    return this.http.get(
      `/getOpenRequets?$filter= IvSoldTo eq '${userRefNo}'and IvUser eq '${thisuser}'&$format=json`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  getChangeContactinformation(): Observable<any> {
    var thisuser = localStorage.getItem("username").toUpperCase();
    // var thisuser = 'PURCIAT';
    return this.http.get(`/changeContactInfoset?(IvUser=${thisuser})`, {
      headers: {
        Authorization: this.userStateService.getAuthorizationHeader(),
      },
    });
  }

  getEquipmentContactInforamtion(equipmentID): Observable<any> {
    var thisuser = localStorage.getItem("username").toUpperCase();
    // var thisuser = 'PURCIAT';
    return this.http.get(
      `/equipmentContactInfoset?(IvEquipment='${equipmentID}',IvUser='${thisuser}')`,
      {
        headers: {
          Authorization: this.userStateService.getAuthorizationHeader(),
        },
      }
    );
  }

  /**Hanumanth code ended here */
  postMicrosoftdata(body): Observable<any> {
    const url =
      "https://login.microsoftonline.com/common/GetCredentialType?mkt=en-IN";
    const htteaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(url, body, { headers: htteaders, observe: "response" })
      .pipe(
        tap((_) => this.log("response received")),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : "Server error";
    return throwError(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // console.log(message);
  }
}
