import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-search',
  templateUrl: './dealer-search.component.html',
  styleUrls: ['./dealer-search.component.scss']
})
export class DealerSearchComponent implements OnInit {
  
  dealerslider: HTMLElement;
  body: HTMLElement;
  slideroverlay: HTMLElement;
  
  constructor() { }

  ngOnInit() {
  }

  public dealerSearch() {

    if (!this.body) this.body = document.querySelector('body');
    if (!this.dealerslider) this.dealerslider = document.querySelector('.dealer-search');
    this.slideroverlay = document.querySelector('.overlay');
    if (this.dealerslider) {
      this.dealerslider.classList.toggle('open');
      this.body.classList.toggle('dealer-search-header-open', this.dealerslider.classList.contains('open'));
      this.slideroverlay.classList.toggle('hide');

      document.querySelector('.main-content').classList.toggle('z-i-1');
      document.querySelector('.main-content').classList.toggle('z-i-0'); 
 

    }
  }
}
