import { UserStateService } from 'src/app/services/userstate.service';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable,  throwError, observable } from 'rxjs';
import {AppComponent} from "../app.component"

@Injectable({
  providedIn: 'root'
})
export class OrderConsumablesService {
  consumableParts={};
  userid:any;
  constructor(private http: HttpClient, private userInfoService:AppComponent, private userStateService: UserStateService) {
    // this.userid=this.userInfoService.userdetail.trim();
      //alert("Order service"+this.userid);

   }
   getOrderCosumablesData(userRef:any): Observable <any> {
     //let fullURL="http://crdb00.sec.sharpamericas.com:8000/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433' and IvUser eq 'SBSTST000' and IvEquipment eq '56FC534B6CBF0CD0E1008000AC1D1C38'&$format=json"
   //code change by sahana
     //  var thisuser=this.userid;
     var thisuser=localStorage.getItem('username').toUpperCase();
     var userRefNo=localStorage.getItem('soldto');
     var opt='X';
    //  let fullURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetConsInfoSet?$filter= IvSoldTo eq '641433'
    // and IvUser eq '"+thisuser+"' and IvEquipment eq '"+userRef+"'&$format=json";
    //  console.log(fullURL);
     return this.http.get(
      `/getConsInfo?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}' and IvEquipment eq '${userRef}' and IvNoPrice eq '${opt}' &$format=json`,
        {
          headers : {
            'Authorization': this.userStateService.getAuthorizationHeader()
          }
        }
    )

  }

}
