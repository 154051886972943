import { Component, OnInit } from "@angular/core";
import { EquipmentDetailsFormComponent } from "../equipment-details-form/equipment-details-form.component";
import { EquipmentService } from "../../services/equipement-service.service";

@Component({
  providers: [EquipmentDetailsFormComponent],
  selector: "app-home-cards",
  templateUrl: "./home-cards.component.pug",
  styleUrls: ["./home-cards.component.scss"],
})
export class HomeCardsComponent implements OnInit {
  constructor(
    private equipform: EquipmentDetailsFormComponent,
    private euipService: EquipmentService
  ) {}

  ngOnInit() {}

  customEquipDetailsForm() {
    // console.log("Hi");
    this.equipform.equipDetailsForm();
  }
}
