import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit } from "@angular/core";
import { SearchEquipComponent } from "./search-equip/search-equip.component";
import { DealerSearchComponent } from "./dealer-search/dealer-search.component";
import { LoginFormComponent } from "../home2/login-form/login-form.component";
//import * as $ from "jquery";
import { EquipmentService } from "../services/equipement-service.service";
import * as $ from "jquery";
import {
  HttpClient,
  HttpClientXsrfModule,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";
import { Injectable } from "@angular/core";
import { InvoiceService } from "../services/invoice.service";
import { Routes, RouterModule, Router } from "@angular/router";

// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  providers: [SearchEquipComponent, DealerSearchComponent, LoginFormComponent],
  selector: "app-header",
  templateUrl: "./header.component.pug",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  // faCoffee = faCoffee;
  userdialogbox: HTMLElement;
  userRef = [];
  showTilesData: boolean;
  isAdmin: boolean = false;
  showSearch: boolean = false;
  username: string;

  constructor(
    private lform: LoginFormComponent,
    public searchSlider: SearchEquipComponent,
    public dealerSlider: DealerSearchComponent,
    private euipService: EquipmentService,
    private http: HttpClient,
    private userStateService: UserStateService,
    private selectService: InvoiceService,
    public router: Router
  ) {
    this.userStateService.getUserRef.subscribe((data) => {
      // console.log(data);
      if (data) {
        // console.log("hello true", data);
        this.getRefdata();
      }
    });
    this.userStateService.showTiles.subscribe((data) => {
      this.showTilesData = data;
    });
    // this.userStateService.isAdmin.subscribe((data) => {
    //   this.isAdmin = data;
    // });
    this.userStateService.newUsername.subscribe((data) => {
      this.username = data;
    });
  }

  ngOnInit() {
    //On focus of input fields functionality (floating labels)
    $(".input-form-group .input").focus(function () {
      if ($(this).val() == "") {
        $(this).addClass("floating-label");
      }
    });

    // $(document).keyup(function (e) {
    //   if (e.key === "Escape") {
    //     if (document.getElementById("loginForm").style.display === "block") {
    //       document.getElementById("loginForm").style.display = "none";
    //       document.getElementById("userIcon").focus();
    //     }
    //     // escape key maps to keycode `27`
    //     // <DO YOUR WORK HERE>
    //   }
    // });

    //On blur of input fields functionality (floating labels)
    $(".input-form-group .input").blur(function () {
      if ($(this).val() == "") {
        $(this).removeClass("floating-label");
      }
    });

    if ($(".input-form-group .input").val() !== "") {
      $(".input-form-group .input").addClass("floating-label");
    } else {
      $(".input-form-group .input").removeClass("floating-label");
    }

    // this.getUserRef();
  }

  userLogin() {
    this.userdialogbox = document.querySelector(".user-id-dialog");
    // console.log(this.userdialogbox.style.display);
    // this.userdialogbox.classList.toggle('open');
    if (
      this.userdialogbox.style.display == "none" ||
      this.userdialogbox.style.display == "" ||
      this.userdialogbox.style.display == undefined
    ) {
      this.userdialogbox.style.display = "block";
    } else {
      this.userdialogbox.style.display = "none";
    }
    // document.querySelector('.main-content').classList.toggle('z-i-1');
    // document.querySelector('.main-content').classList.toggle('z-i-0');
  }

  customLoginForm() {
    // this.euipService.userlogin.next(true);
    this.lform.loginForm();
    document.getElementById("loginForm").style.display = "block";
    document.getElementById("userName").focus();
    // document.querySelector(".main-content").classList.toggle("z-i-1");
    // document.querySelector(".main-content").classList.toggle("z-i-0");
  }

  getRefdata() {
    localStorage.removeItem("equip");
    localStorage.removeItem("openord");
    localStorage.removeItem("openreq");
    this.euipService.getUserRefData().subscribe(
      (userData) => {
        //console.log(equiData.d)
        // debugger;
        // console.log("getrefdata", JSON.stringify(userData));
        if (
          userData.d.results[0].EsAuths &&
          userData.d.results[0].EsAuths.AdminUser === "X"
        ) {
          this.isAdmin = true;
        }
        for (var i = 0; i < userData.d.results.length; i++) {
          this.userRef.push(userData.d.results[i].EtRefferances.SoldTo);
        }
        // console.log(this.userRef);
        if (this.userRef) {
          this.euipService
            .getEquipmentInfoDefault(this.userRef[this.userRef.length - 1])
            .subscribe(
              (data) => {
                localStorage.setItem("equip", JSON.stringify(data));
              },
              (err) => {
                // console.log(err);
              }
            );
          this.euipService
            .getOpenOrdersDefault(this.userRef[this.userRef.length - 1])
            .subscribe(
              (data) => {
                localStorage.setItem("openord", JSON.stringify(data));
              },
              (err) => {
                // console.log(err);
              }
            );
          this.euipService
            .getOpenRequestsDeafult(this.userRef[this.userRef.length - 1])
            .subscribe(
              (data) => {
                localStorage.setItem("openreq", JSON.stringify(data));
              },
              (err) => {
                // console.log(err);
              }
            );
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  changeUserRef(event) {
    var refval = event.target.value;
    // console.log(refval + "refval");
    localStorage.setItem("soldto", refval);
    localStorage.removeItem("equip");
    this.selectService.sendMessage(refval);
  }

  removeData() {
    localStorage.removeItem("soldto");
    localStorage.removeItem("username");
    localStorage.removeItem("equip");
    localStorage.removeItem("openord");
    localStorage.removeItem("openreq");
    localStorage.removeItem("route");
  }

  goToAuditLog() {
    this.router.navigate(["audit-log"]);
    this.userdialogbox.style.display = "none";
  }
}
