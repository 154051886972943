import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { ActivatedRoute } from "@angular/router";
import _ from "lodash";
import * as $ from "jquery";
import * as Highcharts from "highcharts";
declare var MtrDatepicker: any;

@Component({
  selector: "app-reports-view",
  templateUrl: "./reports-view.component.html",
  styleUrls: ["./reports-view.component.scss"],
})
export class ReportsViewComponent implements OnInit {
  locations: any = [];
  blackWhiteChart: any;
  volumeBlackChart: any;
  volumeChart: any;
  amountChart: any;
  blackChartOptions: any;
  colorChartOptions: any;
  volumeChartOptions: any;
  amountChartOptions: any;
  reportData: any;
  locationSelected: any;
  barValue: any;
  barName: any;
  newData: any = [];
  targetElement: null;
  dataMessage = "";
  noData = false;
  seriesClicked = false;
  defaultValues: {
    hours: [];
    minutes: [];
    dates: [];
    datesNames: [];
    months: [];
    years: [];
  };
  hours: {
    min: 1;
    max: 12;
    step: 1;
    maxlength: 2;
  };
  minutes: {
    min: 0;
    max: 50;
    step: 10;
    maxlength: 2;
  };
  months: {
    min: 0;
    max: 11;
    step: 1;
    maxlength: 2;
  };
  years: {
    min: 2000;
    max: 2030;
    step: 1;
    maxlength: 4;
  };
  animations: true; // Responsible for the transition of the sliders - animated or static
  smartHours: false; // Make auto swicth between AM/PM when moving from 11AM to 12PM or backwards
  future: false; // Validate the date to be only in the future
  disableAmPm: false; // Disable the 12 hours time format and go to a full 24 hours experience
  validateAfter: true; // perform the future validation after the date change
  utcTimezone: 0; // change the local timezone to a specific one, e.g. 'Europe/London', 'DST', etc

  transitionDelay: 100;
  transitionValidationDelay: 500;
  references: {
    // Used to store references to the main elements
    hours: null;
  };

  monthsNames: {
    0: "Jan";
    1: "Feb";
    2: "Mar";
    3: "Apr";
    4: "May";
    5: "Jun";
    6: "Jul";
    7: "Aug";
    8: "Sep";
    9: "Oct";
    10: "Nov";
    11: "Dec";
  };

  daysNames: {
    0: "Sun";
    1: "Mon";
    2: "Tue";
    3: "Wed";
    4: "Thu";
    5: "Fri";
    6: "Sat";
  };

  timezones: null;
  datepickerDefault: any;
  constructor(
    public activateRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.reportData = JSON.parse(
      this.activateRoute.snapshot.queryParamMap.get("reportdata")
    );
    this.locations = _.uniqBy(this.reportData, "(EQ) Location 1");
    // this.loadBlackChart();
    // this.loadVolumeChart();
    // this.loadAmountChart();
    this.getLocationData(this.locations[0]["(EQ) Location 1"]);
    this.datepickerDefault = new MtrDatepicker({
      target: "demo",
    });

    this.datepickerDefault.onChange("all", () => {
      var month = this.datepickerDefault.values.date.getMonth();
      var year = this.datepickerDefault.values.date.getFullYear();
      if (month == 3) {
        this.noData = false;
        this.httpClient
          .get("../../assets/json/april.json")
          .subscribe((data) => {
            // console.log(data);
            this.reportData = data["Sheet1"];
            this.locations = _.uniqBy(this.reportData, "(EQ) Location 1");
            this.getLocationData(this.locations[0]["(EQ) Location 1"]);
          });
      } else if (month == 4) {
        this.noData = false;
        this.httpClient.get("../../assets/json/may.json").subscribe((data) => {
          // console.log(data);
          this.reportData = data["Sheet1"];
          this.locations = _.uniqBy(this.reportData, "(EQ) Location 1");
          this.getLocationData(this.locations[0]["(EQ) Location 1"]);
        });
      } else {
        this.dataMessage = "No data found";
        this.locations = [];
        this.noData = true;
      }
    });
  }

  getLocationData(data) {
    this.locationSelected = data;
    var filteredData = _.filter(this.reportData, { "(EQ) Location 1": data });
    this.newData = filteredData;
    this.filterNetValueComparison(filteredData);
    this.seriesClicked = false;
    var xaxiscat = [
      "B/W Curr Vol.",
      "B/W Prev Vol.",
      "B/W Billed Vol.",
      "BW Gross Volume",
    ];
    var dataset = [0, 0, 0, 0];
    this.loadBlackChart(xaxiscat, dataset);
    // this.filteredBlackandWhiteVolume(filteredData);
    // this.filterdColorVolume(filteredData);
    // this.filterAmount(filteredData);
  }

  showColumnD(location) {
    var filteredData = _.filter(this.reportData, {
      "(EQ) Location 1": location,
    });
    this.filteredBlackandWhiteVolume(filteredData);
  }

  filterAmount(filterdata) {
    var netamount = 0;
    var taxamount = 0;
    filterdata.forEach((item) => {
      var net = item["Net Value"].replace("$ ", "");
      var tax = item["Tax amount"].replace("$ ", "");
      netamount += parseFloat(net.replace(/,/g, ""));
      taxamount += parseFloat(tax.replace(/,/g, ""));
    });
    var xaxiscat = ["Net Value", "Tax amount", "Test1 amount", "Test2 amount"];
    var dataset = [netamount, taxamount, 2000, 5000];
    this.loadAmountChart(xaxiscat, dataset);
  }

  filterdColorVolume(filterdata) {
    var currvol = 0;
    var prevvol = 0;
    var billedvol = 0;
    var grossvol = 0;
    filterdata.forEach((item) => {
      currvol += parseInt(item["Color Curr Vol."].replace(/,/g, ""));
      prevvol += parseInt(item["COL Prev. Vol."].replace(/,/g, ""));
      billedvol += parseInt(item["Color Billed Vol."].replace(/,/g, ""));
      grossvol += parseInt(item["COL Gross Volume"].replace(/,/g, ""));
    });
    var xaxiscat = [
      "Color Curr Vol.",
      "COL Prev. Vol.",
      "Color Billed Vol.",
      "COL Gross Volume",
    ];
    var dataset = [currvol, prevvol, billedvol, grossvol];
    this.loadvolumeColorChart(xaxiscat, dataset);
  }

  filteredBlackandWhiteVolume(filterdata) {
    var currvol = 0;
    var prevvol = 0;
    var billedvol = 0;
    var grossvol = 0;
    filterdata.forEach((item) => {
      currvol += parseInt(item["B/W Curr Vol."].replace(/,/g, ""));
      prevvol += parseInt(item["B/W Prev Vol."].replace(/,/g, ""));
      billedvol += parseInt(item["B/W Billed Vol."].replace(/,/g, ""));
      grossvol += parseInt(item["BW Gross Volume"].replace(/,/g, ""));
    });
    var xaxiscat = [
      "B/W Curr Vol.",
      "B/W Prev Vol.",
      "B/W Billed Vol.",
      "BW Gross Volume",
    ];
    var dataset;
    if (this.seriesClicked == true && this.volumeBlackChart) {
      dataset = [
        Math.ceil(Math.random() * 2000),
        Math.ceil(Math.random() * 14000),
        Math.ceil(Math.random() * 10000),
        Math.ceil(Math.random() * 5000),
      ];
      this.loadBlackChart(xaxiscat, dataset);
    } else {
      dataset = [currvol, prevvol, billedvol, grossvol];
      this.loadBlackChart(xaxiscat, dataset);
    }
  }

  filterNetValueComparison(filterdata) {
    var colornet = 0;
    var bnet = 0;
    filterdata.forEach((item) => {
      // console.log(item);
      var val = item["B/W Net Val."].replace("$ ", "");
      var colval = item["Color Net Val."].replace("$ ", "");
      bnet += parseFloat(val.replace(/,/g, ""));
      colornet += parseFloat(colval.replace(/,/g, ""));
    });
    var xaxiscat = [
      "B/W Net Val.",
      "Color Net Val.",
      "Test Net val",
      "Test2 Net val",
    ];
    var dataset = [
      bnet,
      colornet,
      2500,
      2000,
      bnet + 2000,
      colornet + 1000,
      bnet + 12000,
      bnet + 20200,
      bnet + 200,
    ];
    if (this.blackWhiteChart) {
      // console.log(this.blackWhiteChart);
      this.blackWhiteChart.ref.series[0].setData(dataset);
    } else {
      this.loadvolumeBlackChart(xaxiscat, dataset);
    }
  }

  loadvolumeBlackChart(xcat, dataset) {
    var $this = this;
    Highcharts.SVGRenderer.prototype.symbols.rect = function (x, y, w, h) {
      return ["M", x, y + h / 2, "L", x + w, y + h / 2, "z"];
    };

    this.colorChartOptions = {
      chart: {
        type: "scatter",
        zoomType: "x",
        animation: {
          duration: 1000,
        },
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: xcat,
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return "The value for <b>" + this.x + "</b> is <b>" + this.y + "</b>";
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function (e) {
                $this.seriesClicked = true;
                $this.showColumnD($this.locationSelected);
              },
            },
          },
        },
      },
      series: [
        {
          name: "Net val",
          data: dataset,
          marker: {
            symbol: "rect",
            lineColor: "#7CB5EC",
            radius: 20,
            lineWidth: 5,
          },
        },
      ],
    };
    // {
    //   chart: {
    //       type: 'columnrange',
    //       inverted: false
    //   },
    //   title: {
    //       text: ''
    //   },
    //   subtitle: {
    //       text: ''
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   exporting: { enabled: false },
    //   xAxis: {
    //       categories: xcat,
    //       crosshair: true
    //   },
    //   yAxis: {
    //       title: {
    //           text: 'Values'
    //       },
    //     gridLineWidth: 0,
    //     minorGridLineWidth: 0
    //   },
    //   tooltip: {
    //       headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //       pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //           '<td style="padding:0"><b>{point.y}</b></td></tr>',
    //       footerFormat: '</table>',
    //       shared: true,
    //       useHTML: true
    //   },
    //   series: [{
    //       name: 'Net val',
    //       data: dataset,
    //       animation: {
    //         duration: 2000,
    //         // Uses Math.easeOutBounce
    //         easing: easeOutBounce
    //     },
    //   }]
    // };

    this.blackWhiteChart = new Chart(this.colorChartOptions);
  }

  fadeOutAlert() {
    $("#alert").fadeOut("slow");
  }

  loadBlackChart(xaxis, dataset) {
    var $this = this;
    var easeOutBounce = function (pos) {
      // console.log(pos);
      if (pos < 1 / 2.75) {
        return 7.5625 * pos * pos;
      }
      if (pos < 2 / 2.75) {
        return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
      }
      if (pos < 2.5 / 2.75) {
        return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
      }
      return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
    };

    this.blackChartOptions = {
      chart: {
        type: "column",
        // zoomType: 'x',
        // options3d: {
        //     enabled: true,
        //     alpha: 40,
        //     beta: 0,
        //     depth: 80,
        //     viewDistance: 25
        // }
      },
      xAxis: {
        categories: xaxis,
        visible: true,
        crosshair: true,
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      title: {
        text: "",
      },
      tooltip: {
        enabled: false,
      },
      subtitle: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      exporting: { enabled: true },
      plotOptions: {
        column: {
          depth: 25,
          events: {
            click: (e) => {
              // console.log(e);
              $this.barValue = e.point.options.y;
              $this.barName = e.point.category;
              $("#alert").fadeIn("slow").delay(2000).fadeOut("slow");
            },
          },
        },
      },
      // series:  [{
      //   name: 'Black vol',
      //   data: dataset,
      //   color: 'black',
      //   animation: {
      //     duration: 1500,
      //     // Uses Math.easeOutBounce
      //     easing: easeOutBounce
      //   }
      //   },
      //   {
      //   name: 'White vol',
      //   data: dataset,
      //   animation: {
      //     duration: 1500,
      //     // Uses Math.easeOutBounce
      //     easing: easeOutBounce
      //   }
      // }]
      series: [
        {
          name: "Volume",
          colorByPoint: true,
          data: dataset,
          animation: {
            duration: 1500,
            // Uses Math.easeOutBounce
            easing: easeOutBounce,
          },
        },
      ],
    };

    this.volumeBlackChart = new Chart(this.blackChartOptions);
  }

  loadvolumeColorChart(xaxis, dataset) {
    var easeOutBounce = function (pos) {
      // console.log(pos);
      if (pos < 1 / 2.75) {
        return 7.5625 * pos * pos;
      }
      if (pos < 2 / 2.75) {
        return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
      }
      if (pos < 2.5 / 2.75) {
        return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
      }
      return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
    };
    this.volumeChartOptions = {
      chart: {
        type: "cylinder",
        zoomType: "x",
        options3d: {
          enabled: true,
          alpha: 15,
          beta: -15,
          depth: 50,
          viewDistance: 25,
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: xaxis,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Values",
        },
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          depth: 25,
          colorByPoint: true,
        },
      },
      series: [
        {
          data: dataset,
          name: "Color Volume",
          showInLegend: true,
          animation: {
            duration: 1500,
            // Uses Math.easeOutBounce
            easing: easeOutBounce,
          },
        },
      ],
    };

    this.volumeChart = new Chart(this.volumeChartOptions);
  }

  loadAmountChart(xaxis, dataset) {
    Highcharts.SVGRenderer.prototype.symbols.rect = function (x, y, w, h) {
      return ["M", x, y + h / 2, "L", x + w, y + h / 2, "z"];
    };

    this.amountChartOptions = {
      chart: {
        type: "scatter",
        zoomType: "x",
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: xaxis,
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      series:
        // [{
        //     name: 'Predifined symbol',
        //     data: [29.9, 71.5, 106.4, 129.2, 144.0],
        //     marker: {
        //         symbol: 'square',
        //         radius: 20
        //     }
        // },
        [
          {
            name: "Net val",
            data: dataset,
            // marker: {
            //     symbol: 'url(../../assets/images/close.png)'
            // }
            marker: {
              symbol: "rect",
              lineColor: "#7CB5EC",
              radius: 20,
              lineWidth: 5,
            },
            animation: {
              duration: 2000,
              // Uses Math.easeOutBounce
              easing: easeOutBounce,
            },
          },
        ],
    };
    // this.amountChartOptions = {
    //   chart: {
    //       type: 'columnrange',
    //       inverted: false,
    //       height: 270
    //   },
    //   title: {
    //       text: ''
    //   },
    //   subtitle: {
    //       text: ''
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   exporting: { enabled: false },
    //   xAxis: {
    //       categories: xaxis,
    //       crosshair: true
    //   },
    //   yAxis: {
    //       title: {
    //           text: 'Amount'
    //       },
    //       gridLineWidth: 0,
    //       minorGridLineWidth: 0
    //   },
    //   tooltip: {
    //       headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //       pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //           '<td style="padding:0"><b>${point.y}</b></td></tr>',
    //       footerFormat: '</table>',
    //       shared: true,
    //       useHTML: true
    //   },
    //   plotOptions: {
    //       column: {
    //           pointPadding: 0.2,
    //           borderWidth: 0
    //       }
    //   },
    //   series: [{
    //       name: 'Amount',
    //       data: dataset,
    //       animation: {
    //         duration: 2000,
    //         // Uses Math.easeOutBounce
    //         easing: easeOutBounce
    //     },
    //   }]
    // };

    var easeOutBounce = function (pos) {
      // console.log(pos);
      if (pos < 1 / 2.75) {
        return 7.5625 * pos * pos;
      }
      if (pos < 2 / 2.75) {
        return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
      }
      if (pos < 2.5 / 2.75) {
        return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
      }
      return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
    };
    this.amountChart = new Chart(this.amountChartOptions);
  }
}
