import { EquipmentService } from "./../services/equipement-service.service";
import { Router } from "@angular/router";
import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as $ from "jquery";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-change-contact",
  templateUrl: "./change-contact.component.html",
  styleUrls: ["./change-contact.component.scss"],
})
export class ChangeContactComponent implements OnInit {
  originalContact: any;
  newContact: any;
  equipmentNo: any;
  contactForm: FormGroup;
  showUpdateButton = true;
  showNewButton = true;
  constructor(
    private formBuilder: FormBuilder,
    public userService: UserStateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private equipService: EquipmentService
  ) {
    this.contactForm = this.formBuilder.group({
      // originalContact: ['', Validators.required],
      telephone: ["", Validators.required],
      firstName: ["", Validators.required],
      email: ["", Validators.required],
      lastName: ["", Validators.required],
      equipmentNo: ["", Validators.required],
    });
  }

  ngOnInit() {}

  getEquipmentInfo() {
    this.spinner.show();
    let equipeMentId = this.contactForm.value.equipmentNo;
    this.equipService.getEquipmentContactInforamtion(equipeMentId).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          if (data.d.EvNoContact != "X") {
            this.showUpdateButton = false;
            this.showNewButton = false;
            if (data.d.EvType == "E") {
              this.toastr.error(data.d.EvMessage);
            } else {
              this.contactForm.patchValue({
                firstName: data.d.EvFname,
                lastName: data.d.EvLname,
                telephone: data.d.EvTelephone,
                email: data.d.EvEmail,
              });
            }
          } else {
            this.showUpdateButton = true;
            this.showNewButton = false;
          }
        }
      },
      (err) => {
        this.spinner.hide();
        // console.log(err);
      }
    );
  }

  updateContactInfo() {
    // console.log("update");
    this.spinner.show();
    let body = {
      IvEquipment: this.contactForm.value.equipmentNo,
      IvFName: this.contactForm.value.firstName,
      IvLName: this.contactForm.value.lastName,
      IvUser: localStorage.getItem("username"),
      IvTelephone: this.contactForm.value.telephone,
      IvEmail: this.contactForm.value.email,
    };
    var token = "";
    var cookie = "";
    var $this = this;
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          $this.userService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      $.ajax({
        url: `/updatecontactinfo`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: body, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          $this.spinner.hide();
          if (odata.d.ev_type == "S") {
            // console.log(odata.d.ev_message);
            $this.toastr.success("Updated successfully", "", {
              closeButton: true,
              disableTimeOut: true,
            });
            $this.contactForm.reset();
          }
          if (odata.d.ev_type == "E") {
            // console.log(odata.d.ev_message);
            $this.toastr.error(odata.d.ev_message, "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
        },
        error: function (odata) {
          // console.log(odata);
          let errorMsg = JSON.parse(odata["responseText"]).error.message.value;
          // console.log(errorMsg);
          $this.spinner.hide();
          $this.toastr.error(errorMsg, "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }

  submitData() {
    // console.log("create");
    this.spinner.show();
    let body = {
      IvEquipment: this.contactForm.value.equipmentNo,
      IvFname: this.contactForm.value.firstName,
      IvLname: this.contactForm.value.lastName,
      IvUname: localStorage.getItem("username"),
      IvTelephone: this.contactForm.value.telephone,
      IvEmail: this.contactForm.value.email,
    };
    var token = "";
    var cookie = "";
    var $this = this;
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "Authorization",
          $this.userService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      $.ajax({
        url: `/postuserinfo`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: body, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          xhr.setRequestHeader("Content-Type", "application/json");
          // xhr.setRequestHeader("set-cookie", cookie);
        },
        success: function (odata) {
          $this.spinner.hide();
          if (odata.d.EvType == "") {
            // console.log(odata.d.EvMessage);
            $this.toastr.success("Created successfully", "", {
              closeButton: true,
              disableTimeOut: true,
            });
            $this.router.navigate(["/home"]);
          }
          if (odata.d.EvType == "E") {
            // console.log(odata.d.EvMessage);
            $this.toastr.error(odata.d.EvMessage, "", {
              closeButton: true,
              disableTimeOut: true,
            });
          }
        },
        error: function (odata) {
          // console.log(odata);
          let errorMsg = JSON.parse(odata["responseText"]).error.message.value;
          // console.log(errorMsg);
          $this.spinner.hide();
          $this.toastr.error(errorMsg, "", {
            closeButton: true,
            disableTimeOut: true,
          });
        },
      });
    }, 5000);
  }
}
