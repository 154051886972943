import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-notification-dialog",
  templateUrl: "./notification-dialog.component.pug",
  styleUrls: ["./notification-dialog.component.scss"],
})
export class NotificationDialogComponent implements OnInit {
  notificationModal: any;
  modalData: any = {
    title: "Sample title",
    body: "body",
    footer: "footer",
  };

  constructor() {}

  ngOnInit() {
    this.notificationModal = $("#notificationDialog");
    // console.log(this.notificationModal);
  }

  public showNotification(): void {
    if (this.notificationModal) {
      this.notificationModal.modal("show");
    }

    // console.log(this.notificationModal);
  }

  public hideNotification(): void {
    this.notificationModal.modal("hide");
  }
}
