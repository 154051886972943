import { InvoiceService } from './../../services/invoice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-equip',
  templateUrl: './search-equip.component.html',
  styleUrls: ['./search-equip.component.scss']
})
export class SearchEquipComponent implements OnInit {

  searchslider: HTMLElement;
  body: HTMLElement;
  slideroverlay: HTMLElement;
  searchdata: any;
  constructor(public invoiceService: InvoiceService) { }

  ngOnInit() {
  }

  public headerSearch() {
    if (!this.body) this.body = document.querySelector('body');
    if (!this.searchslider) this.searchslider = document.querySelector('.header-search');
    this.slideroverlay = document.querySelector('.overlay');
    if (this.searchslider) {
      this.searchslider.classList.toggle('open');
      this.body.classList.toggle('search-header-open', this.searchslider.classList.contains('open'));
      this.slideroverlay.classList.toggle('hide');
      document.querySelector('.main-content').classList.toggle('z-i-1');
      document.querySelector('.main-content').classList.toggle('z-i-0');
    }
  }

  getData() {
    // console.log(this.searchdata);
    this.invoiceService.searchData.next(this.searchdata);
    this.headerSearch();
    this.searchdata = '';
  }

}
