import { InvoiceService } from "./../services/invoice.service";
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from "@angular/forms";
@Component({
  selector: "app-equipments-all",
  templateUrl: "./equipments-all.component.html",
  styleUrls: ["./equipments-all.component.css"],
})
export class EquipmentsAllComponent implements OnInit {
  public searchdata: FormGroup;
  showSearch = false;
  constructor(
    private formBuilder: FormBuilder,
    public invoiceService: InvoiceService
  ) {
    this.searchdata = this.formBuilder.group({
      searchelement: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  ngOnInit() {
    $(document).ready(function () {
      //Hamburger menu
      $("body").on("click", ".hamburger-menu", function () {
        $(this).toggleClass("is-active");
        $("#leftSidebar").toggleClass("active");
        $("#serviceRequestAllMain").toggleClass("active");
      });

      //Service request slider
      $(".list-section-3").on("click", "button", function (e) {
        e.preventDefault();
        $(".main-page-form").addClass("active");
        $(".service-request-form").removeClass("hide");
      });
      $("body").on("click", ".main-form-close", function (e) {
        e.preventDefault();
        $(".main-page-form").removeClass("active");
      });

      //view more
      // $('.service-request-all-main-content').on('click', '.view-more-service-requests', function(e){
      //   e.preventDefault();
      //   $(this).addClass('hide');
      //   $('.view-less-service-requests').removeClass('hide');
      //   $('.equipments-all-main').css('max-height', 'fit-content');
      // });
      // $('.service-request-all-main-content').on('click', '.view-less-service-requests', function(e){
      //   e.preventDefault();
      //   $(this).addClass('hide');
      //   $('.view-more-service-requests').removeClass('hide');
      //   $('.equipments-all-main').css('max-height', '635px');
      // });
    });
  }

  searchData() {
    // console.log(this.searchdata.value.searchelement);
    var searchele = this.searchdata.value.searchelement;
    this.invoiceService.searchData.next(searchele);
  }

  openSearch() {
    this.showSearch = true;
  }
}
