import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { EquipmentService } from '../../services/equipement-service.service'


@Component({
  selector: 'app-equipment-info',
  templateUrl: './equipment-info.component.html',
  styleUrls: ['./equipment-info.component.css'],
  //providers: [EquipmentService]
})
export class EquipmentInfoComponent implements OnInit {
  equipmentInfo={};
  constructor(private _equipmentService : EquipmentService) { }

  ngOnInit() {
    //debugger;
    if(this._equipmentService.equipmentInfo && this._equipmentService.equipmentInfo!=null){
       this.equipmentInfo = this._equipmentService.equipmentInfo;
    }
  }

}
