import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { tap, catchError } from "rxjs/operators";
import { throwError, BehaviorSubject, Subject } from "rxjs";
import { DOCUMENT } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class UserStateService {
  private encryptedHeaderValue: string;
  private authenticated: boolean;
  public timeline: string;
  public selection: any;
  private showHideLogin: BehaviorSubject<boolean>;
  public getUserRef: BehaviorSubject<boolean>;
  public showTiles: BehaviorSubject<boolean>;
  // public isAdmin: BehaviorSubject<boolean>;
  public newUsername: BehaviorSubject<string>;
  private username: string;
  domain: any;
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: any
  ) {
    this.showHideLogin = new BehaviorSubject(false);
    this.getUserRef = new BehaviorSubject(false);
    this.showTiles = new BehaviorSubject(false);
    // this.isAdmin = new BehaviorSubject(false);
    this.newUsername = new BehaviorSubject<string>(null);
    // console.log(this.document.location);
    this.domain = this.document.location.host;
  }

  public login(userName: string, password: string) {
    // debugger;
    var un;
    if (userName.indexOf("@") == -1) {
      un = userName;
    } else {
      un = userName.substring(0, userName.lastIndexOf("@"));
    }
    let unp = `${un}:${password}`;
    return this.http
      .post("/login", {
        user_name: un,
        password: password,
      })
      .pipe(
        tap((res) => {
          this.authenticated = true;
          this.encryptedHeaderValue = `Basic ${btoa(unp)}`;
          this.showTiles.next(true);
          // console.log("userState", res);
          // this.isAdmin.next(true);
          localStorage.setItem("username", un);
          this.newUsername.next(un);
          this.getUserRef.next(true);
        }),
        catchError(this.handleError)
      );
  }

  public resetPassword(userName: string, password: string) {
    var un;
    if (userName.indexOf("@") == -1) {
      un = userName;
    } else {
      un = userName.substring(0, userName.lastIndexOf("@"));
    }
    return this.http
      .post("/resetPassword", {
        user_name: un,
        password: password,
      })
      .pipe(
        tap((res) => {
          console.log("Success", res);
        }),
        catchError(this.handleError)
      );
  }

  public reports() {
    return this.http.get("/reports").pipe(
      tap((res) => {
        // console.log(res);
      }),
      catchError(this.handleError)
    );
  }

  public downloadReport(name) {
    return this.http
      .get("/download/" + name, {
        responseType: "arraybuffer",
      })
      .pipe(
        tap((res) => {
          // console.log(res);
        }),
        catchError(this.handleError)
      );
  }

  public postUseInfo(body) {
    return this.http
      .post("/postuserinfo", body, {
        observe: "response",
      })
      .pipe(
        tap((res) => {
          // console.log(res);
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // console.log(error);
    this.authenticated = false;
    if (error.error && error.error.error) {
      error = error.error.error;
    }
    return throwError(error);
  }

  public isAuthenticated() {
    return this.authenticated;
  }

  public getAuthorizationHeader() {
    return this.encryptedHeaderValue;
  }

  public showHideLoginMode() {
    return this.showHideLogin;
  }
  public setTimeline(range) {
    this.timeline = range;
    return this.timeline;
  }
  public getTimeline() {
    return this.timeline;
  }
  public setSelection(ele) {
    this.selection = ele;
    return this.selection;
  }
  public getSelection() {
    return this.selection;
  }
}
