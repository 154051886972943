import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { UserStateService } from "src/app/services/userstate.service";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Equipment } from "./equipment";
import { EQUIPMENTS } from "./equipments-mock";
import { SPARES } from "./spares-mock";
//import Swiper from 'swiper';
import Swiper from "../../../../node_modules/swiper/dist/js/swiper.js";
import { EquipmentService } from "../../services/equipement-service.service";
import { ElementRef, Pipe } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as $ from "jquery";
import { AppComponent } from "../../app.component";
import { InvoiceService } from "../../services/invoice.service";

@Component({
  selector: "app-my-equipments-service",
  templateUrl: "./my-equipments.component.pug",
  styleUrls: ["./my-equipments.component.scss"],
})
export class MyEquipmentsServiceComponent implements OnInit {
  equipments = EQUIPMENTS;
  spares = SPARES;
  myEquipmentsSwiper: Swiper = undefined;
  myEquipmentSparesSwipers: Swiper[] = [];
  equipmentList = [];
  userid: any;
  soldToUser: any;
  briefdesc: any;
  addinfo: any;
  equipmentsArray = [];
  constructor(
    private equiptService: EquipmentService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private userInfoService: AppComponent,
    private selectService: InvoiceService,
    public userStateService: UserStateService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    // console.log(this.equipmentList);
    //alert("Hi");
    // this.userid=this.userInfoService.userdetail.trim();
    //alert("My equipments"+this.userid);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getEquipmentInfo();
    }, 3000);
    setTimeout(function () {
      this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
        direction: "vertical",
        slidesPerView: "auto",
        slidesPerGroup: 4,
        navigation: {
          nextEl: ".my-equipments--navigation--next",
          prevEl: ".my-equipments--navigation--prev",
        },
      });
    }, 7000);

    this.selectService.searchData.subscribe((data) => {
      if (data) {
        // console.log(data + "this is search value");
        // console.log(this.equipmentList);
        if (data && data.trim() != "") {
          this.equipmentList = this.equipmentsArray.filter((item) => {
            return (
              item.Sernr.toLowerCase().indexOf(data.toLowerCase()) > -1 ||
              item.EquipmentId.toLowerCase().indexOf(data.toLowerCase()) > -1
            );
          });
        } else {
          this.equipmentList = this.equipmentsArray;
        }
      } else {
        this.equipmentList = this.equipmentsArray;
      }
    });

    // Array.from(document.querySelectorAll('.my-equipment--spares')).forEach((spares) => {
    //     let swiperContainer: HTMLElement = spares.querySelector('.spares-container .swiper-container');
    //     let prevElement: HTMLElement = spares.querySelector('.spares-action .nav-prev');
    //     let nextElement: HTMLElement = spares.querySelector('.spares-action .nav-next');

    //     this.myEquipmentSparesSwipers.push(
    //         new Swiper(swiperContainer, {
    //             slidesPerView: 'auto',
    //             navigation: {
    //                 nextEl: nextElement,
    //                 prevEl: prevElement
    //             }
    //         })
    //     );
    // });

    this.selectService.getMessage().subscribe((data) => {
      // console.log(data);
      this.soldToUser = data;
      this.getEquipmentInfo();

      setTimeout(function () {
        this.myEquipmentsSwiper = new Swiper("#myEquipmentsContainer", {
          direction: "vertical",
          slidesPerView: "auto",
          slidesPerGroup: 4,
          navigation: {
            nextEl: ".my-equipments--navigation--next",
            prevEl: ".my-equipments--navigation--prev",
          },
        });
      }, 7000);

      // Array.from(document.querySelectorAll('.my-equipment--spares')).forEach((spares) => {
      //     let swiperContainer: HTMLElement = spares.querySelector('.spares-container .swiper-container');
      //     let prevElement: HTMLElement = spares.querySelector('.spares-action .nav-prev');
      //     let nextElement: HTMLElement = spares.querySelector('.spares-action .nav-next');

      //     this.myEquipmentSparesSwipers.push(
      //         new Swiper(swiperContainer, {
      //             slidesPerView: 'auto',
      //             navigation: {
      //                 nextEl: nextElement,
      //                 prevEl: prevElement
      //             }
      //         })
      //     );
      // });
    });

    $(document).ready(function () {
      $(".scroll-y").scroll(function () {
        if ($(".scroll-y").scrollTop() > 1600) {
          $(".scroll-y").removeClass("scrollwhite scrollhovergrey");
          $(".scroll-y").addClass("scrollred scrollhoverblack");
        } else {
          $(".scroll-y").removeClass("scrollred scrollhoverblack");
          $(".scroll-y").addClass("scrollwhite scrollhovergrey");
        }
      });
    });
  }

  /*toggleSpares(equipment: Equipment, sparesContainer: HTMLElement) {
        let thisSlide =             sparesContainer.closest('.my-equipment-slide');
        let allSlides =             thisSlide.closest('.my-equipments--container').querySelectorAll('.my-equipment-slide');
        let thisSlideIndex =        Array.from(allSlides).indexOf(thisSlide);
        let btnViewSparesLabel =    thisSlide.querySelector('.btn-view-spares .icon-btn--label');

        // console.log(thisSlideIndex);

        sparesContainer.classList.toggle('shown');
        window.setTimeout(() => {
            this.myEquipmentsSwiper.updateSlides();
            if (sparesContainer.classList.contains('shown')) {
                btnViewSparesLabel.innerHTML = "Hide Spares";
                this.myEquipmentsSwiper.slideTo(thisSlideIndex, 600);
            } else {
                btnViewSparesLabel.innerHTML = "View Spares";
            }
        }, 400);
    }*/

  getEquipmentInfo() {
    this.equipmentList = [];
    this.spinner.show();
    var defEquip = localStorage.getItem("equip");
    if (defEquip) {
      var jEquip = JSON.parse(defEquip);

      for (var i = 0; i < jEquip.d.results.length; i++) {
        this.equipmentList.push(jEquip.d.results[i].EtList);
      }
      this.equipmentsArray = this.equipmentList.slice();
      this.spinner.hide();
    } else {
      this.equiptService.getEquipmentInfo().subscribe(
        (equiData) => {
          //console.log(equiData.d)
          this.spinner.hide();
          for (var i = 0; i < equiData.d.results.length; i++) {
            this.equipmentList.push(equiData.d.results[i].EtList);
          }
          this.equipmentsArray = this.equipmentList.slice();
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
    }
  }

  /*getEquipmentInfoDetail(equipmentId : string) : void{
        //debugger;
        this._equipmentService.equipmentInfo={};
        this._equipmentService.equipmentInfo = this.equipmentList.filter(e=>e.Equipment == equipmentId)
        this._router.navigate(['/equipment-info']);
      }*/

  createReqSl(equipRef: string) {
    var $this = this;
    this.spinner.show();
    this.clearData();
    this.equiptService.equipmentInfo = {};
    this.equiptService.equipmentInfo = this.equipmentList.filter(
      (e) => e.Equipment == equipRef
    );
    $(".main-page-form").addClass("active");
    //if(innerText=='SERVICE REQUEST'){
    $(".service-request-form").removeClass("hide");
    $(".service-request-form").siblings().addClass("hide");
    // console.log('service status');
    //let userRef=$(this).parent().parent().find(".equipinfo").text().trim();
    let userRef = this.equiptService.equipmentInfo[0].Equipment;
    //alert(JSON.stringify(this.equiptService.equipmentInfo)+ "Hello" +userRef);
    //let servURL="http://crdb00.sec.sharpamericas.com:8000/";
    let servURL = "";
    // let partURL="/sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/GetRequestInfoSet?$filter= IvSoldTo eq ";
    // let partURL = `/equipment?$filter= IvSoldTo eq '${userRefNo}' and IvUser eq '${thisuser}'&$format=json
    // let soldtoUser="641433";
    // let user="SBSTST000";
    let soldtoUser = localStorage.getItem("soldto");
    let user = localStorage.getItem("username").toUpperCase();
    // let user=this.userid;
    let partURL = `/getRequestInfo?$filter= IvSoldTo eq '${soldtoUser}' and IvUser eq '${user}' and IvEquipment eq '${userRef}' &$format=json`;
    // let servReqURL=servURL+partURL+"'"+soldtoUser+"' and IvUser eq '"+user+"' and IvEquipment eq '"+userRef+"'&$format=json";
    let servReqURL = partURL;
    //  console.log(JSON.stringify(servReqURL));
    $.ajax({
      context: this,
      url: servReqURL,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRF-Token", "Fetch");
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
      },
      success: function (servReqData) {
        $this.spinner.hide();
        let serviceData: any = servReqData.d.results[0];
        $(".service-request-form .equip-id").text(
          serviceData.EsEqInfo.EquipmentId
        );
        $(".service-request-form .model").text(
          serviceData.EsEqInfo.Model + " " + serviceData.EsEqInfo.ProdrefShtext
        );
        $(".service-request-form .srnum").text(serviceData.EsEqInfo.Sernr);
        $(".service-request-form .equip-ref").text(
          serviceData.EsEqInfo.Equipment
        );

        $("#CreateServiceRequestTopic .gen-topic").remove();
        $("#CreateServiceRequestConcern .det-con").remove();
        localStorage.setItem(
          "requestData",
          JSON.stringify(servReqData.d.results)
        );
        let topicArr = [];
        var detailArr = [];

        for (var i = 0; i < servReqData.d.results.length; i++) {
          if (
            topicArr.indexOf(servReqData.d.results[i].EtCategories.Cat1) == -1
          ) {
            topicArr.push(servReqData.d.results[i].EtCategories.Cat1);
          }
        }
        for (i = 0; i < topicArr.length; i++) {
          $("#CreateServiceRequestTopic").append(
            "<option class='gen-topic'>" + topicArr[i] + "</option>"
          );
        }

        $("#CreateServiceRequestTopic").change(function () {
          var ab = $("#CreateServiceRequestTopic :selected").text().trim();
          // console.log(ab);
          var cd = [];
          var aa = [];
          detailArr = [];
          $("#CreateServiceRequestConcern .det-con").remove();
          // console.log("service change"+ab);
          for (i = 0; i < servReqData.d.results.length; i++) {
            if (servReqData.d.results[i].EtCategories.Cat1 == ab) {
              detailArr.push(servReqData.d.results[i].EtCategories.Cat2);
            }
          }
          // console.log(detailArr.length);
          for (i = 0; i < detailArr.length; i++) {
            $("#CreateServiceRequestConcern").append(
              "<option  class='det-con'>" + detailArr[i] + "</option>"
            );
          }
        });
      },
      error: function (err) {
        // console.log(err);
        $this.spinner.hide();
      },
    });
    //}
  }

  postCreateServiceRequestMyEquip() {
    //alert("hi");
    this.spinner.show();
    //var narray=[];
    var $this = this;
    // console.log(JSON.parse(localStorage.getItem('requestData')));
    var cat1 = $("#CreateServiceRequestTopic :selected").text().trim();
    // console.log(cat1);
    var cat2 = $("#CreateServiceRequestConcern :selected").text().trim();
    // console.log(cat2);
    var ivcat1;
    var ivcat2;
    var arr = JSON.parse(localStorage.getItem("requestData"));
    for (var i = 0; i < arr.length; i++) {
      // console.log(arr[i].EtCategories.Cat1)
      if (arr[i].EtCategories.Cat1 == cat1) {
        ivcat1 = arr[i].EtCategories.CatCode1;
        break;
      }
    }

    for (var i = 0; i < arr.length; i++) {
      // console.log(arr[i].EtCategories.Cat2)
      if (arr[i].EtCategories.Cat2 == cat2) {
        ivcat2 = arr[i].EtCategories.CatCode2;
        break;
      }
    }
    var createServiceReqData = {
      IvSoldTo: localStorage.getItem("soldto"),
      IvEquipment: $(".service-request-form .equip-ref").text().trim(),
      // "IvComments" : $("#CreateServiceRequestTopic :selected").text().trim(),
      // "IvDescription" : $("#CreateServiceRequestConcern :selected").text().trim(),
      IvComments: this.addinfo,
      IvDescription: this.briefdesc,
      IvCat1: ivcat1,
      IvCat2: ivcat2,
    };
    var newData = createServiceReqData;
    //alert(newData);

    /*this.http.post("sap/opu/odata/SAP/ZSD_SERVICE_APP_SRV/CreateRequestSet", newData, {headers:
            {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} })
            .subscribe((res:any) => {
             let abgg = res.json();
              // console.log(abgg);
            },
            (error) => {
              console.log(error);
            });*/

    var token = "";
    var cookie = "";
    $.ajaxSetup({
      cache: false,
    });
    $.ajax({
      url: `/token`,
      type: "GET",
      async: true,
      // headers: {'X-CSRF-Token':'Fetch'},
      beforeSend: function (xhr) {
        //  xhr.setRequestHeader('X-CSRF-Token', 'Fetch');
        xhr.setRequestHeader(
          "Authorization",
          $this.userStateService.getAuthorizationHeader()
        );
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      },
      success: function (data, textStatus, XMLHttpRequest) {
        //  token = XMLHttpRequest.getResponseHeader("X-CSRF-Token");
        token = data["x-csrf-token"];
        cookie = data["set-cookie"].join(";");
        // console.log("Token:" + token);
      },
      //crossDomain: true
    });

    $.ajaxSetup({
      cache: false,
    });
    setTimeout(function () {
      // console.log(token + "this is the token to send");
      $.ajax({
        url: `/createrequestset`,
        async: true,
        dataType: "json",
        data: JSON.stringify({ actual: newData, cookie: cookie }),
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            $this.userStateService.getAuthorizationHeader()
          );
          xhr.setRequestHeader("X-CSRF-Token", token);
          // xhr.setRequestHeader("set-cookie", cookie);
          xhr.setRequestHeader("Content-Type", "application/json");
        },
        success: function (data) {
          // console.log("Crate service success:", JSON.stringify(data));
          $this.spinner.hide();
          //alert(data.d.EvType);
          if (data.d.EvType == "S") {
            // alert("Create Service request success. Service order number: "+ data.d.IvOrderNo);
            var resdata =
              "Create Service request success. Service order number: " +
              data.d.IvOrderNo;
            $this.toastr.success(resdata, "", {
              closeButton: true,
              disableTimeOut: true,
            });
            $("#CreateServiceRequestConcern").val("");
            $("#CreateServiceRequestTopic").val("");
            $(".service-request-form .equip-id").text("");
            $(".service-request-form .model").text("");
            $(".service-request-form .srnum").text("");
            $this.briefdesc = "";
            $this.addinfo = "";
          }
        },
        error: function (data) {
          $this.spinner.hide();
          var reserror = "";
          // console.log("error:", JSON.stringify(data));
          if (
            data.responseJSON &&
            data.responseJSON.error &&
            data.responseJSON.error.message
          ) {
            reserror = data.responseJSON.error.message.value;
          } else {
            reserror = "error:" + JSON.stringify(data);
          }

          $this.toastr.error(reserror, "", {
            closeButton: true,
            disableTimeOut: true,
          });
          // alert("error:"+JSON.stringify(data));
        },
      });
    }, 5000);
  }

  /**Hanumanth */
  clearData() {
    $("#CreateServiceRequestConcern").val("");
    $("#CreateServiceRequestTopic").val("");
    $(".service-request-form .equip-id").text("");
    $(".service-request-form .model").text("");
    $(".service-request-form .srnum").text("");
    this.briefdesc = "";
    this.addinfo = "";
    // console.log(this.briefdesc + "breirweldk");
  }
}
